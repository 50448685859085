import React from "react";
import SelectControl from "../../../../components/form/select-control";
import LinkState from "../../../../helpers/link-state";

export default function NextShipmentEdit({ state, country, parentThis }) {
    if (!state.printers.length) {
        return null;
    }
    const hasMobilePaper =
        state.printers.find(
            (p) => p.type === "mobile" && p.name !== "TSC DA220"
        ) && country !== "Australia";

    const hasMobileStickers =
        state.printers.find(
            (p) => p.type === "mobile" && p.name === "TSC DA220"
        ) && country !== "Australia";

    const hasBlackLineMobile = state.printers.find(
        (p) => p.type === "mobile" && p.blackLineMobile
    );

    const hasIndustrialPaper = state.printers.find(
        (p) => p.type === "industrial"
    );

    return (
        <>
            {(hasMobilePaper && (
                <>
                    <h3>Mobile Paper</h3>
                    <SelectControl
                        name="nextMobileShipment"
                        label="Next Mobile Shipment"
                        value={state.nextMobileShipment}
                        onChange={LinkState.bind(parentThis)}
                    >
                        {/* <option value="">-- Select One --</option>
                        <option value="mobile">
                            Mobile Paper 125 per roll
                        </option> */}
                        {/* <option value="mobileLaminated">
                            Laminated Mobile Paper 100 per roll
                        </option> */}
                        <option value="sixMilMobile">6mil Mobile Paper</option>
                    </SelectControl>

                    {/* {((!state.nextMobileShipment ||
                        state.nextMobileShipment === "mobile") &&
                        country !== "Australia" && (
                            <SelectControl
                                name="mobileRollRefillCount"
                                label="Mobile Replenishment Roll Count*"
                                value={state.mobileRollRefillCount}
                                onChange={LinkState.bind(parentThis)}
                            >
                                <option value="">-- Select One --</option>
                                <option value={12}>12</option>
                                <option value={24}>24</option>
                                <option value={36}>36</option>
                                <option value={48}>48</option>
                                <option value={60}>60</option>
                            </SelectControl>
                        )) ||
                        null} */}

                    {(country !== "Australia" &&
                        state.nextMobileShipment === "mobileLaminated" && (
                            <SelectControl
                                name="mobileLaminatedRollRefillCount"
                                label="Mobile Laminated  Roll Replenishment Count*"
                                value={state.mobileLaminatedRollRefillCount}
                                onChange={LinkState.bind(parentThis)}
                            >
                                <option value="">-- Select One --</option>
                                <option value={15}>15</option>
                                <option value={30}>30</option>
                                <option value={45}>45</option>
                                <option value={60}>60</option>
                            </SelectControl>
                        )) ||
                        null}
                    {(country !== "Australia" &&
                        state.nextMobileShipment === "sixMilMobile" && (
                            <SelectControl
                                name="sixMilMobileRollRefillCount"
                                label="6mil Mobile Roll Replenishment Count*"
                                value={state.sixMilMobileRollRefillCount}
                                onChange={LinkState.bind(parentThis)}
                            >
                                <option value="">-- Select One --</option>
                                <option value={20}>20</option>
                                <option value={40}>40</option>
                                <option value={60}>60</option>
                                <option value={80}>80</option>
                            </SelectControl>
                        )) ||
                        null}
                </>
            )) ||
                null}

            {(hasMobileStickers && (
                <>
                    <h3>DA220 Stickers</h3>
                    <SelectControl
                        name="mobileStickerRollRefillCount"
                        label="Mobile Sticker Replenishment Roll Count*"
                        value={state.mobileStickerRollRefillCount}
                        onChange={LinkState.bind(parentThis)}
                    >
                        <option value="">-- Select One --</option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                        <option value={25}>25</option>
                    </SelectControl>
                </>
            )) ||
                null}

            {(hasBlackLineMobile && (
                <>
                    <h3>DA220 Tag Paper</h3>
                    <SelectControl
                        name="blackLineMobileRefillCount"
                        label="Black Line Mobile Replenishment Roll Count*"
                        value={state.blackLineMobileRefillCount}
                        onChange={LinkState.bind(parentThis)}
                    >
                        <option value="">-- Select One --</option>
                        <option value={4}>4</option>
                        <option value={8}>8</option>
                        <option value={12}>12</option>
                        <option value={16}>16</option>
                        <option value={20}>20</option>
                    </SelectControl>
                </>
            )) ||
                null}

            {(hasIndustrialPaper && (
                <>
                    <h3>Industrial Paper</h3>
                    <SelectControl
                        name="nextIndustrialShipment"
                        label="Next Industrial Shipment"
                        value={state.nextIndustrialShipment}
                        onChange={LinkState.bind(parentThis)}
                    >
                        <option value="">-- Select One --</option>
                        <option value="industrial">7" Tags</option>
                        <option value="industrialSmall">Small Tags</option>
                    </SelectControl>
                    {((!state.nextIndustrialShipment ||
                        state.nextIndustrialShipment === "industrial") && (
                        <SelectControl
                            name="industrialRollRefillCount"
                            label='7" Industrial Tag Replenishment Roll Count*'
                            value={state.industrialRollRefillCount}
                            onChange={LinkState.bind(parentThis)}
                            disabled={state.loading}
                        >
                            <option value="">-- Select One --</option>
                            <option value={4}>4</option>
                            <option value={8}>8</option>
                            <option value={12}>12</option>
                            <option value={16}>16</option>
                            <option value={20}>20</option>
                            <option value={24}>24</option>
                        </SelectControl>
                    )) ||
                        null}
                    {(state.nextIndustrialShipment === "industrialSmall" && (
                        <SelectControl
                            name="industrialSmallRollRefillCount"
                            label="Small Industrial Tag Replenishment Roll Count*"
                            value={state.industrialSmallRollRefillCount}
                            onChange={LinkState.bind(parentThis)}
                            disabled={state.loading}
                        >
                            <option value="">-- Select One --</option>
                            <option value={4}>4</option>
                            <option value={8}>8</option>
                            <option value={12}>12</option>
                            <option value={16}>16</option>
                            <option value={20}>20</option>
                            <option value={24}>24</option>
                        </SelectControl>
                    )) ||
                        null}
                </>
            )) ||
                null}
        </>
    );
}
