import React from "react";
import LinkState from "../../../../helpers/link-state";
import Button from "../../../../components/form/button.jsx";
import TextControl from "../../../../components/form/text-control.jsx";
import SelectControl from "../../../../components/form/select-control.jsx";
import Actions from "./actions";

class FarmForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            _id: undefined,
            plan: null,
            setupFee: "",
            trialPeriod: 0,
            loading: false,
            showSaveSuccess: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            plan: nextProps.plan,
            setupFee: nextProps.setupFee,
            _id: nextProps._id,
            trialPeriod: nextProps.trialPeriod,
        });
    }

    componentWillMount() {
        this.setState({
            plan: this.props.plan,
            setupFee: this.props.setupFee,
            trialPeriod: this.props.trialPeriod,
            _id: this.props._id,
        });
    }

    handleSubmit() {
        const farmId = this.props._id;
        const plan = this.state.plan || this.props.plan;
        const setupFee = this.state.setupFee || this.props.setupFee;
        const trialPeriod = this.state.trialPeriod || this.props.trialPeriod;

        Actions.updateFarmSetupFee(farmId, plan, setupFee, trialPeriod);
        this.props.toggleEdit();
    }

    render() {
        return (
            <div>
                <br />
                <div className="panel panel-default">
                    <div className="panel-heading">Current Subscription</div>
                    <div className="panel-body">
                        <TextControl
                            name="setupFee"
                            label="Setup Fee"
                            type="number"
                            value={this.state.setupFee}
                            onChange={LinkState.bind(this)}
                            disabled={!!this.props.subscriptionId}
                        />
                        <TextControl
                            name="trialPeriod"
                            label="Trial Period (in days)"
                            type="number"
                            value={this.state.trialPeriod}
                            onChange={LinkState.bind(this)}
                            disabled={!!this.props.subscriptionId}
                        />
                        <SelectControl
                            name="plan"
                            label="Payment Plan"
                            value={this.state.plan}
                            onChange={LinkState.bind(this)}
                            disabled={this.props.loading}
                        >
                            <option value="">- Select One - </option>
                            {this.props.plans.map((plan) => (
                                <option value={plan.id}>{plan.nickname}</option>
                            ))}
                        </SelectControl>

                        <Button
                            type="submit"
                            inputClasses={{ "btn-primary": true }}
                            onClick={this.handleSubmit.bind(this)}
                            disabled={this.state.loading}
                        >
                            {this.state.loading
                                ? "Save changes..."
                                : "Save changes"}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default FarmForm;
