import React from "react";
import Actions from "./actions";
import Store from "./store";
import InvoiceForm from "./invoice-form.jsx";
import { Elements } from "react-stripe-elements";

class TaggingInvoice extends React.Component {
  constructor(props) {
    super(props);

    Actions.getDetails(this.props.match.params.id);
    Actions.getUser();
    Actions.getInvoice(this.props.match.params.invoiceId);

    this.state = Store.getState();
  }

  componentDidMount() {
    this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));
  }

  componentWillUnmount() {
    this.unsubscribeStore();
  }

  onStoreChange() {
    this.setState(Store.getState());
  }
  render() {
    return (
      <Elements>
        <InvoiceForm {...this.state} />
      </Elements>
    );
  }
}

export default TaggingInvoice;
