import Actions from './actions';
import Alert from '../../../../components/alert.jsx';
import Button from '../../../../components/form/button.jsx';
import ControlGroup from '../../../../components/form/control-group.jsx';
import LinkState from '../../../../helpers/link-state';
import PropTypes from 'prop-types';
import React from 'react';
import SelectControl from '../../../../components/form/select-control.jsx';
import Spinner from '../../../../components/form/spinner.jsx';
import TextControl from '../../../../components/form/text-control.jsx';

const propTypes = {
	_id: PropTypes.string,
	email: PropTypes.string,
	error: PropTypes.string,
	hasError: PropTypes.object,
	help: PropTypes.object,
	isActive: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	loading: PropTypes.bool,
	showSaveSuccess: PropTypes.bool,
	username: PropTypes.string,
	name: PropTypes.object,
};

class DetailsForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isActive: props.isActive,
			username: props.username,
			email: props.email,
			name: props.name,
		};
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			isActive: nextProps.isActive,
			username: nextProps.username,
			email: nextProps.email,
			name: nextProps.name,
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		event.stopPropagation();

		const id = this.props._id;
		const data = {
			isActive: this.state.isActive,
			username: this.state.username,
			email: this.state.email,
			name: this.state.name,
		};

		Actions.saveDetails(id, data);
	}

	render() {
		const alerts = [];

		if (this.props.showSaveSuccess) {
			alerts.push(
				<Alert
					key="success"
					type="success"
					onClose={Actions.hideDetailsSaveSuccess}
					message="Success. Changes have been saved."
				/>
			);
		}

		if (this.props.error) {
			alerts.push(
				<Alert key="danger" type="danger" message={this.props.error} />
			);
		}

		const formElements = (
			<fieldset>
				<legend>Details</legend>
				{alerts}
				<SelectControl
					name="isActive"
					label="Active"
					value={this.state.isActive}
					onChange={LinkState.bind(this)}
					hasError={this.props.hasError.isActive}
					help={this.props.help.isActive}
					disabled={this.props.loading}
				>
					<option value={true}>true</option>
					<option value={false}>false</option>
				</SelectControl>
				<TextControl
					name="username"
					label="Username"
					value={this.state.username}
					onChange={LinkState.bind(this)}
					hasError={this.props.hasError.username}
					help={this.props.help.username}
					disabled={this.props.loading}
				/>
				<TextControl
					name="email"
					label="Email"
					value={this.state.email}
					onChange={LinkState.bind(this)}
					hasError={this.props.hasError.email}
					help={this.props.help.email}
					disabled={this.props.loading}
				/>
				<TextControl
					name="name.first"
					label="First name"
					value={this.state.name.first}
					onChange={LinkState.bind(this)}
					hasError={this.props.hasError['name.first']}
					help={this.props.help['name.first']}
					disabled={this.props.loading}
				/>
				<TextControl
					name="name.middle"
					label="Middle name"
					value={this.state.name.middle}
					onChange={LinkState.bind(this)}
					hasError={this.props.hasError['name.middle']}
					help={this.props.help['name.middle']}
					disabled={this.props.loading}
				/>
				<TextControl
					name="name.last"
					label="Last name"
					value={this.state.name.last}
					onChange={LinkState.bind(this)}
					hasError={this.props.hasError['name.last']}
					help={this.props.help['name.last']}
					disabled={this.props.loading}
				/>
				<ControlGroup hideLabel={true} hideHelp={true}>
					<Button
						type="submit"
						inputClasses={{ 'btn-primary': true }}
						disabled={this.props.loading}
					>
						Save changes
						<Spinner space="left" show={this.props.loading} />
					</Button>
				</ControlGroup>
			</fieldset>
		);

		return <form onSubmit={this.handleSubmit.bind(this)}>{formElements}</form>;
	}
}

DetailsForm.propTypes = propTypes;

export default DetailsForm;
