export default (metric, type = null) => {
    const harvestOptions = [
        "Yield",
        "% Mortality",
        "% Yield",
        "% Return",
        "mortality",
        "yield",
        "returns",
    ];

    return harvestOptions.includes(metric) && type !== "count";
};
