import Footer from './footer.jsx';
import Login from './login/home/index.jsx';
import LoginForgot from './login/forgot/index.jsx';
import LoginLogout from './login/logout/index.jsx';
import LoginReset from './login/reset/index.jsx';
import Navbar from './navbar.jsx';
import NotFound from './not-found.jsx';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RouteRedirect from '../../components/route-redirect.jsx';
import SsoComponent from './login/Sso.jsx';

const AppUniversal = function (props) {
	return (
		<div>
			<Route component={Navbar} />
			<Switch>
				<Route path="/" exact component={Login} />
				<Route path="/login" exact component={Login} />
				<Route path="/login/forgot" exact component={LoginForgot} />
				<Route path="/login/reset/:email/:key" component={LoginReset} />
				<Route path="/login/logout" exact component={LoginLogout} />
				<Route path="/sso/:token/:farmId" exact component={SsoComponent} />

				<RouteRedirect from="/moved" to="/" code={301} />

				<Route component={NotFound} />
			</Switch>
			<Footer />
		</div>
	);
};

export default AppUniversal;
