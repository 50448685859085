import React from "react";

function dateToYMD(date) {
    return (
        (date.getMonth() + 1).toString().padStart(2, 0) +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear()
    );
}

const paperType = {
    mobile: "Mobile Tags",
    industrial: "Industrial Tags",
    mobileSticker: "Mobile Sticker Rolls",
    mobileStickerRoll: "DA220 Sticker Rolls",
    setup: "Starter Packs",
};

const shipmentQualifier = {
    mobile: "Tags",
    industrial: "Tags",
    mobileSticker: "Stickers",
    setup: "",
    blackLineMobile: "Black Line Mobile Tags",
};

export default function TaggingShipmentHistory({ taggingInvoices }) {
    if (!taggingInvoices) {
        return null;
    }
    return Object.keys(taggingInvoices).map((key) => {
        if (taggingInvoices[key].length) {
            return (
                <>
                    <h4>{paperType[key]}</h4>
                    <table className="billingTable">
                        <thead>
                            <th>Date</th>
                            <th>Invoice</th>
                            <th>Shipment</th>
                            <th>$ Billed</th>
                        </thead>
                        {taggingInvoices[key].map((invoice) => (
                            <tr>
                                <td>
                                    {invoice._id}{" "}
                                    {dateToYMD(new Date(invoice.date))}
                                </td>
                                <td>{invoice.name}</td>
                                <td>
                                    {invoice.tagsShipped}{" "}
                                    {shipmentQualifier[key]}
                                </td>
                                <td>
                                    {invoice.amount}{" "}
                                    {invoice.refunded && (
                                        <span className="text-danger">
                                            Refunded
                                        </span>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </table>
                    <ul></ul>
                </>
            );
        }
        return null;
    });
}
