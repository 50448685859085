import Alert from '../../../components/alert.jsx';
import ControlGroup from '../../../components/form/control-group.jsx';
import LinkState from '../../../helpers/link-state';
import PropTypes from 'prop-types';
import React from 'react';
import Spinner from '../../../components/form/spinner.jsx';

const PERMISSON_OPTIONS = {
	'admin-users': [
		'Administer Users',
		'Permission to invite users and manage user permissions',
	],
	'admin-items': [
		'Administer Items',
		'Permission to configure item templates and manage item data',
	],
	'user-ios': [
		'Seafood iOS User',
		'Permission to access Seafood OS on iOS devices using the BlueTrace app',
	],
	'user-desktop': [
		'Seafood OS User',
		'Permission to access Seafood OS website using a desktop browser',
	],
	companies: [
		'Companies',
		'Permission to access and manage vendors and customers',
	],
	departments: [
		'Departments',
		'Permission to access and manage the list of departments',
	],
	recall: [
		'Initiate Recall',
		'Permission to initiate a product recall; sends email to buyers and sellers of specific lot alerting them of a product issue',
	],
	export: [
		'Data Export',
		'Permission to initiate a product recall; sends email to buyers and sellers of specific lot alerting them of a product issue',
	],
	pricing: ['Pricing', 'Permission to access and manage pricing'],
};

const PermissionsBlock = ({ permissions, farmId, accountId, endpoint }) => {
	const url = `${endpoint}/${farmId}/settings/users/${accountId}`;

	const [show, setShow] = React.useState(false);
	return (
		<>
			<h5 onClick={() => setShow(!show)}>
				Permissions ({(permissions && permissions.length) || 0}){' '}
				<span className="pull-right">{show ? '⌃' : '⌄'}</span>
			</h5>
			{show && (
				<>
					<ul>
						{permissions.map((p) => (
							<li key={p}>{PERMISSON_OPTIONS?.[p]?.[0] || p}</li>
						))}
					</ul>
					{endpoint && (
						<a href={url} target="_blank" className="btn btn-primary btn-xs">
							Update Permissions
						</a>
					)}
				</>
			)}
		</>
	);
};

const propTypes = {
	current: PropTypes.object,
	error: PropTypes.string,
	hasError: PropTypes.object,
	help: PropTypes.object,
	loading: PropTypes.bool,
	log: PropTypes.array,
	newFarm: PropTypes.string,
	options: PropTypes.array,
	userFarms: PropTypes.array,
	removeAction: PropTypes.func,
	saveAction: PropTypes.func,
	showSaveSuccess: PropTypes.bool,
	successCloseAction: PropTypes.func,
};

class FarmForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			newFarm: props.newFarm,
		};
	}

	handleSubmit(event) {
		event.preventDefault();
		event.stopPropagation();

		const data = {
			farm: this.state.newFarm,
			accountName: this.props.accountName,
			userFarms: this.props.userFarms,
		};

		this.props.saveAction(data);
	}

	render() {
		const alerts = [];

		if (this.props.showSaveSuccess) {
			alerts.push(
				<Alert
					key="success"
					type="success"
					onClose={this.props.successCloseAction}
					message="Success. Changes have been saved."
				/>
			);
		}

		if (this.props.error) {
			alerts.push(
				<Alert key="danger" type="danger" message={this.props.error} />
			);
		}

		const farmOptions = this.props.options
			.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
			.map((farm) => {
				return (
					<option key={farm._id} value={farm._id}>
						{farm.name} ({farm.type})
					</option>
				);
			});

		const farmHistory = this.props.userFarms.map((farm) => {
			const handleRemove = (e) => {
				e.preventDefault();
				this.props.removeAction(farm.id);
			};

			return (
				<div key={farm.timeCreated}>
					<h3>
						{farm.name}{' '}
						<button
							onClick={handleRemove}
							className="btn btn-secondary btn-xs pull-right"
						>
							Remove
						</button>
					</h3>
					<PermissionsBlock
						endpoint={this.props.permissionsEndpoint}
						permissions={farm.permissions}
						farmId={farm.id}
						accountId={this.props.accountId}
					/>
				</div>
			);
		});

		const maxedOut = this.props.userFarms && this.props.userFarms.length >= 25;
		const formElements = (
			<fieldset>
				<legend>Farm</legend>
				{alerts}
				<ControlGroup
					hideLabel={true}
					hasError={this.props.hasError.farm}
					help={this.props.help.farm}
				>
					<div className="input-group">
						<select
							name="newFarm"
							className="form-control"
							value={this.state.newFarm}
							onChange={LinkState.bind(this)}
							disabled={maxedOut}
						>
							<option value="">--- select ---</option>
							{farmOptions}
						</select>
						<span className="input-group-btn">
							<button
								type="submit"
								className="btn btn-default"
								disabled={this.props.loading}
							>
								Add
								<Spinner space="left" show={this.props.loading} />
							</button>
						</span>
					</div>
				</ControlGroup>
				{maxedOut && (
					<p className="help-block">
						You have reached 25 farms the maximum number allowed.
					</p>
				)}
				{farmHistory}
			</fieldset>
		);

		return <form onSubmit={this.handleSubmit.bind(this)}>{formElements}</form>;
	}
}

FarmForm.propTypes = propTypes;

export default FarmForm;
