import React from "react";
import "./BillingTableStyles.scss";

export default function ({
    totalPrinted,
    taggingRefillThreshold,
    customPaperRolls = [],
    paperCosts,
    type = "industrial",
}) {
    const rows = ["mobile", "industrial", "mobileSticker", "blackLineMobile"];

    const paperType = {
        mobile: "Mobile Tags",
        industrial: "Industrial Tags",
        mobileSticker: "Mobile Sticker Rolls",
        blackLineMobile: "Black Line Mobile Tags",
    };
    if (!totalPrinted || !taggingRefillThreshold) {
        return null;
    }
    return (
        <table className="billingTable">
            <tr>
                <th>Paper Type</th>
                <th>All Time Prints</th>
                <th>Remaining tags in stock</th>
            </tr>
            {rows
                .filter(
                    (key) =>
                        key !== "industrial" ||
                        (key === "industrial" &&
                            !(customPaperRolls && customPaperRolls.length))
                )
                .map((key) => {
                    const paperLeft =
                        taggingRefillThreshold[key].shippedCount -
                        totalPrinted[key];

                    return (
                        ((totalPrinted[key] ||
                            taggingRefillThreshold[key].shippedCount) && (
                            <tr>
                                <td>{paperType[key]}</td>
                                <td>{totalPrinted[key]}</td>
                                <td>{paperLeft ?? "-"}</td>
                            </tr>
                        )) ||
                        null
                    );
                })}
            {(customPaperRolls && customPaperRolls.length && (
                <tr>
                    <td>Custom {type}</td>
                    <td>{totalPrinted[type]}</td>
                    <td>
                        {customPaperRolls.reduce((all, rolls) => {
                            return (
                                all +
                                parseInt(rolls.count) *
                                    paperCosts[type].tagCountPerRoll
                            );
                        }, 0) - totalPrinted.industrial}
                    </td>
                </tr>
            )) ||
                null}
        </table>
    );
}
