import React from "react";
import { find } from "lodash/fp";
import moment from "moment";

class ManagerSetup extends React.Component {
    render() {
        const paymentObj = find({ id: this.props.plan })(this.props.plans);

        const paymentPlan = paymentObj ? paymentObj.nickname : "None selected";

        const subscriptionStatus = this.props.subscriptionStatus
            ? `: ${this.props.subscriptionStatus} ${
                  this.props.lastFarmManagerInvoice &&
                  `last invoice ${moment(
                      this.props.lastFarmManagerInvoice
                  ).format("l")}`
              }`
            : ": Not Paid";
        return (
            <div>
                <br />
                <div className="panel panel-default">
                    <div className="panel-heading">
                        Current Subscription{subscriptionStatus}
                    </div>
                    <ul className="list-group list-group-farm-summary">
                        <li className="list-group-item row">
                            <dt className="col-sm-4">Payment Plan</dt>
                            <dd>{paymentPlan}</dd>
                        </li>
                        <li className="list-group-item row">
                            <dt className="col-sm-4">Setup Fee</dt>
                            <dd>
                                {this.props.setupFee ||
                                this.props.setupFee === 0
                                    ? `$${this.props.setupFee}.00`
                                    : "Not set"}
                            </dd>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default ManagerSetup;
