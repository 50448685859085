import CreateNew from "./reducers/create-new";
import { createStore, combineReducers } from "redux";
import Results from "./reducers/results";

export default createStore(
    combineReducers({
        createNew: CreateNew,
        results: Results,
    })
);
