import SetupWizard from "./wizard.jsx";
import PropTypes from "prop-types";
import React from "react";

const propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

class DetailsPage extends React.Component {
  render() {
    return (
      <section className="container">
        <h1 className="page-header">Add Farm Details</h1>
        <div className="row">
          <div className="col-sm-8">
            <SetupWizard />
          </div>
        </div>
      </section>
    );
  }
}

DetailsPage.propTypes = propTypes;

export default DetailsPage;
