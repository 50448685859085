import React from "react";
import ManagerSetupInfo from "./managerInfo";
import Button from "../../../../components/form/button.jsx";
import ManagerSetupForm from "./managerSetupForm";
import Actions from "./actions";

class Manager extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            edit: false,
        };

        this.handleToggleEdit = this.handleToggleEdit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentWillMount() {
        const { plan, setupFee } = this.props;

        if (!plan && !setupFee) {
            this.setState({
                edit: true,
            });
        }
    }

    handleToggleEdit() {
        this.setState({
            edit: !this.state.edit,
        });
    }
    handleCancel() {
        if (!window.confirm("Are you sure?")) {
            return;
        }

        Actions.cancelFarmManager(this.props._id, this.props.subscriptionId);
    }

    render() {
        return (
            <div>
                {this.state.edit ? (
                    <ManagerSetupForm
                        toggleEdit={this.handleToggleEdit}
                        {...this.props}
                    />
                ) : (
                    <div>
                        <ManagerSetupInfo {...this.props} />
                        <Button
                            type="submit"
                            inputClasses={{ "btn-primary": true }}
                            onClick={this.handleToggleEdit}
                            disabled={this.state.loading}
                        >
                            Edit
                        </Button>
                        &nbsp;
                        <Button
                            type="submit"
                            inputClasses={{ "btn-primary": true }}
                            onClick={this.handleCancel}
                            disabled={this.state.loading}
                        >
                            Cancel Subscription
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}

export default Manager;
