import React, { Component } from "react";

import CardSection from "../details/stripe/CardSection.js";

class StripeInvoiceForm extends Component {
  render() {
    const { farm } = this.props;
    return farm.customerId &&
      farm.cardInfo &&
      this.props.updateCard === false ? (
      <div>
        <p>
          <span className="capitalize">{farm.cardInfo.brand}</span> ending in{" "}
          {farm.cardInfo.last4}. Expires {farm.cardInfo.exp_month}/
          {farm.cardInfo.exp_year}
        </p>
        <p>
          <button
            onClick={this.props.handleUpdateCard}
            className="btn-default btn-small"
          >
            Update
          </button>
        </p>
      </div>
    ) : (
      <CardSection />
    );
  }
}

export default StripeInvoiceForm;
