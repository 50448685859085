import React from "react";
import { Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReportContainer from "./ReportContainer";

export default () => (
  <div id="root">
    <Helmet>
      <body class="reportPage" />
    </Helmet>
    <Switch>
      <Route component={ReportContainer} />
    </Switch>
  </div>
);
