import Delete from "./reducers/delete";
import Details from "./reducers/details";
import Lease from "./reducers/lease";
import Line from "./reducers/line";
import Tray from "./reducers/tray";
import { createStore, combineReducers } from "redux";

export default createStore(
    combineReducers({
        delete: Delete,
        details: Details,
        lease: Lease,
        line: Line,
        tray: Tray,
    })
);
