/* global window */
import ApiActions from "../../../../actions/api";
import Constants from "./constants";
import Store from "./store";
import { find } from "lodash/fp";

class Actions {
    static getDetails(id) {
        ApiActions.get(
            `/api/users/${id}`,
            undefined,
            Store,
            Constants.GET_DETAILS,
            Constants.GET_DETAILS_RESPONSE
        );
    }

    static getPermissionsUrl() {
        console.log("get permissions endpoint");
        ApiActions.get(
            "/api/farms/permissionsEndpoint",
            undefined,
            Store,
            Constants.GET_FARM_OPTIONS,
            Constants.GET_PERMISSIONS_URL_RESPONSE
        );
    }

    static getFarmOptions() {
        ApiActions.get(
            "/api/farms",
            undefined,
            Store,
            Constants.GET_FARM_OPTIONS,
            Constants.GET_FARM_OPTIONS_RESPONSE
        );
    }
    static newFarm(id, data) {
        if (!data.farm) {
            return;
        }
        if (
            find({
                id: data.farm,
            })(data.userFarms)
        ) {
            return Store.dispatch({
                type: Constants.NEW_FARM_RESPONSE,
                err: new Error("same farm"),
                response: {
                    message: "Farm already added.",
                },
            });
        }

        delete data.userFarms;

        ApiActions.post(
            `/api/users/${id}/farm`,
            data,
            Store,
            Constants.NEW_FARM,
            Constants.NEW_FARM_RESPONSE
        );
    }

    static removeFarm(id, farmId) {
        console.log("remove farm", id, farmId);
        ApiActions.delete(
            `/api/users/${id}/farm/${farmId}`,
            undefined,
            Store,
            Constants.DELETE_ACCOUNT_FARM,
            Constants.DELETE_ACCOUNT_FARM_RESPONSE
        );
    }

    static getGroupOptions() {
        ApiActions.get(
            "/api/admin-groups",
            undefined,
            Store,
            Constants.GET_GROUP_OPTIONS,
            Constants.GET_GROUP_OPTIONS_RESPONSE
        );
    }

    static saveGroups(id, data) {
        console.log("save groups", id, data);
        ApiActions.put(
            `/api/users/${id}/groups`,
            data,
            Store,
            Constants.SAVE_GROUPS,
            Constants.SAVE_GROUPS_RESPONSE
        );
    }

    static saveDetails(id, data) {
        ApiActions.put(
            `/api/users/${id}`,
            data,
            Store,
            Constants.SAVE_DETAILS,
            Constants.SAVE_DETAILS_RESPONSE
        );
    }

    static hideDetailsSaveSuccess() {
        Store.dispatch({
            type: Constants.HIDE_DETAILS_SAVE_SUCCESS,
        });
    }

    static savePassword(id, data) {
        if (data.password !== data.passwordConfirm) {
            return Store.dispatch({
                type: Constants.SAVE_PASSWORD_RESPONSE,
                err: new Error("password mismatch"),
                response: {
                    message: "Passwords do not match.",
                },
            });
        }

        delete data.passwordConfirm;

        ApiActions.put(
            `/api/users/${id}/password`,
            data,
            Store,
            Constants.SAVE_PASSWORD,
            Constants.SAVE_PASSWORD_RESPONSE
        );
    }

    static hidePasswordSaveSuccess() {
        Store.dispatch({
            type: Constants.HIDE_PASSWORD_SAVE_SUCCESS,
        });
    }

    static delete(id, history) {
        ApiActions.delete(
            `/api/users/${id}`,
            undefined,
            Store,
            Constants.DELETE,
            Constants.DELETE_RESPONSE,
            (err, response) => {
                if (!err) {
                    history.push("/admin/users");

                    window.scrollTo(0, 0);
                }
            }
        );
    }
}

export default Actions;
