import AdminGroupDetails from './admin-groups/details/index.jsx';
import AdminGroupSearch from './admin-groups/search/index.jsx';
import Footer from './footer.jsx';
import Home from './home/index.jsx';
import Navbar from './navbar.jsx';
import NotFound from './not-found.jsx';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import UserDetails from './users/details/index.jsx';
import UserSearch from './users/search/index.jsx';

const App = () => (
	<div>
		<Route component={Navbar} />
		<Switch>
			<Route path="/admin" exact component={Home} />
			<Route path="/admin/admin-groups" exact component={AdminGroupSearch} />
			<Route path="/admin/admin-groups/:id" component={AdminGroupDetails} />
			<Route path="/admin/users" exact component={UserSearch} />
			<Route path="/admin/users/:id" component={UserDetails} />

			<Route component={NotFound} />
		</Switch>
		<Footer />
	</div>
);

export default App;
