import isPercentMetric from "./isPercentMetric";
import moment from "moment";

export function quantFormatter(
    uglyNumber,
    capital,
    metric = null,
    type = null
) {
    if (metric === "Average Size") {
        return `${Math.round(uglyNumber * 100) / 100}mm`;
    }
    if (isPercentMetric(metric, type)) {
        return `${Math.round(uglyNumber * 100) / 100}%`;
    }
    if (1000 > uglyNumber && uglyNumber >= 0) {
        return uglyNumber.toString();
    }
    if (1000000 > uglyNumber && uglyNumber >= 1000) {
        return `${(Math.ceil(uglyNumber / 100) / 10).toString()}K`;
    }

    if (1000000000 > uglyNumber && uglyNumber >= 1000000) {
        return `${(Math.ceil(uglyNumber / 100000) / 10).toString()}M`;
    }

    if (1000000000000 > uglyNumber && uglyNumber >= 1000000000) {
        return `${(Math.ceil(uglyNumber / 100000000) / 10).toString()}B`;
    }

    return uglyNumber.toString();
}

export function formatDate(date, ddOptions) {
    const justDate = date.split("T")[0];
    if (!justDate) {
        return date;
    }
    const dateArray = justDate.split("-");

    const month = moment()
        .month(dateArray[1] - 1)
        .format("MMM");

    const day = parseInt(dateArray[2], 10);

    return `${month} ${day}`;
}

export function formatDelta(delta) {
    if (delta === 0) {
        return "+0";
    } else if (delta < 0) {
        return "-" + quantFormatter(delta * -1);
    } else {
        return "+" + quantFormatter(delta);
    }
}
