import React from 'react';
import { injectStripe } from 'react-stripe-elements';
import CardSection from './CardSection';
import actions from '../actions';

class CheckoutForm extends React.Component {
	constructor(props) {
		super(props);

		this.handleSubmit = this.handleSubmit.bind(this);
		this.updateCard = this.updateCard.bind(this);

		this.state = {
			update: false,
			error: null,
		};
	}

	updateCard() {
		this.setState({
			update: true,
		});
	}

	handleSubmit(ev) {
		// We don't want to let default form submission happen here, which would refresh the page.
		ev.preventDefault();
		const { farm } = this.props;

		if (farm.cardInfo && !this.state.update) {
			console.log('when does this get called');
			actions.updatePaymentDetails({
				id: this.props.farm._id,
				token: null,
			});
		} else {
			this.props.stripe
				.createToken()
				.then(({ token, error }) => {
					if (error) {
						this.setState({
							error: error.message,
						});
						return;
					}
					actions.updatePaymentDetails({
						id: this.props.farm._id,
						token: token.id,
						email: this.props.farm.billingEmail,
					});
				})
				.catch((err) => {
					console.log('stripe err', err);
					this.setState({
						error: err.message,
					});
				});
		}
	}

	render() {
		const { farm } = this.props;

		return (
			<form onSubmit={this.handleSubmit}>
				<div className="panel panel-default">
					<div className="panel-heading" id="cardInfo">
						<h4>Card Information</h4>
					</div>
					<div className="panel-body">
						{farm.customerId && farm.cardInfo && this.state.update === false ? (
							<div>
								<p>
									<span className="capitalize">{farm.cardInfo.brand}</span>{' '}
									ending in {farm.cardInfo.last4}. Expires{' '}
									{farm.cardInfo.exp_month}/{farm.cardInfo.exp_year}
								</p>
								<p>
									<button
										onClick={this.updateCard}
										className="btn-default btn-small"
									>
										Update
									</button>
								</p>
							</div>
						) : (
							<div>
								<CardSection />
								{this.state.error && (
									<p className="text-danger">{this.state.error}</p>
								)}
								<button className="btn-primary btn-lg">
									Update Card on File
								</button>
							</div>
						)}
					</div>
				</div>
			</form>
		);
	}
}

export default injectStripe(CheckoutForm);
