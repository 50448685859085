import React, { useState, useEffect } from "react";
import {
    LineChart,
    Line,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
} from "recharts";
import isPercentMetric from "../utils/isPercentMetric";
import { quantFormatter } from "../utils/formatters";
import request from "superagent";
const colors = ["#02B6FF", "#FF708F", "#32C465"];

const getDashboardLabel = (filters) => {
    if (Object.keys(filters).length) {
        const selected = Object.keys(filters).length
            ? Object.keys(filters).reduce((all, key) => {
                  if (filters[key]) {
                      return all.concat(filters[key]);
                  }

                  return all;
              }, [])
            : [];
        return selected.join(", ");
    } else {
        return "All Animals";
    }
};

export default ({
    farmId,
    activeLeaseId,
    dateRange,
    frequency,
    activeViewBy,
    metric,
    graphType,
}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const getChartData = async () => {
            try {
                const response = await request
                    .put(`/api/v2/getFarmInventory/${farmId}`)
                    .send({
                        activeLease: activeLeaseId,
                        dateRange,
                        frequency,
                        activeViewBy,
                        metric,
                        graphType,
                    });

                setData(response.body);
            } catch (err) {
                alert(err);
            }
        };
        if (farmId) {
            getChartData();
        }
    }, [
        activeLeaseId,
        dateRange,
        frequency,
        activeViewBy,
        farmId,
        metric,
        graphType,
    ]);

    const type = "share";
    if (data.count) {
        return (
            <div className="dashboard-box">
                <h2>{quantFormatter(data.count)}</h2>
                <h3>{data.label}</h3>
            </div>
        );
    }

    if (data.size) {
        return (
            <div className="dashboard-box">
                <h2>{`${+data.size.toFixed(2)}mm`}</h2>
                <h3>{data.label}</h3>
            </div>
        );
    }

    if (data.total) {
        return (
            <div className="dashboard-box">
                <h2>{quantFormatter(data.total)}</h2>
                <h3>Containers</h3>
            </div>
        );
    }

    if (data.data) {
        let domain = [0, "dataMax"];

        if (isPercentMetric(metric, type)) {
            const maxValue = data.reduce((max, d) => {
                Object.keys(d)
                    .filter((key) => key === "name")
                    .forEach((key) => {
                        if (d[key] && d[key] > max) {
                            max = d[key];
                        }
                    });

                return max;
            }, 100);
            domain = [0, maxValue];
        }
        return (
            <div className="dashboard-box">
                <h3>{`${getDashboardLabel(activeViewBy)} ${metric}`}</h3>
                <h4>{dateRange}</h4>
                <div
                    style={{
                        height: 200,
                        width: "90%",
                        position: "relative",
                        left: -20,
                    }}
                >
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={data.data}>
                            <CartesianGrid vertical={true} horizontal={false} />

                            <YAxis
                                axisLine={false}
                                tickLine={false}
                                tick={styles.axisStyleY}
                                orientation="left"
                                tickFormatter={(tick) =>
                                    quantFormatter(tick, null, metric)
                                }
                                domain={domain}
                            />

                            {Object.keys(data.data[0])
                                .filter((key) => key !== "name")
                                .map((key, i) => (
                                    <Line
                                        connectNulls
                                        type="linear"
                                        dataKey={key}
                                        stackId="1"
                                        stroke={colors[i]}
                                        strokeWidth={2}
                                        dot={false}
                                    />
                                ))}
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        );
    }
    return null;
};

const styles = {
    axisStyleX: {
        fontFamily: "Avenir Next",
        fontSize: 11,
        fontWeight: 700,
        fill: "#000000",
    },
    axisStyleY: {
        fontFamily: "Avenir Next",
        fontSize: 11,
        fontWeight: 700,
        fill: "#000000",
    },
};
