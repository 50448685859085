import FluxConstant from "flux-constant";

export default FluxConstant.set([
    "DELETE",
    "DELETE_RESPONSE",
    "GET_DETAILS",
    "GET_DETAILS_RESPONSE",
    "HIDE_DETAILS_SAVE_SUCCESS",
    "HIDE_PASSWORD_SAVE_SUCCESS",
    "SAVE_DETAILS",
    "SAVE_DETAILS_RESPONSE",
    "SAVE_PASSWORD",
    "SAVE_PASSWORD_RESPONSE",
    "GET_FARM_OPTIONS",
    "GET_FARM_OPTIONS_RESPONSE",
    "NEW_FARM",
    "NEW_FARM_RESPONSE",
    "DELETE_ACCOUNT_FARM",
    "DELETE_ACCOUNT_FARM_RESPONSE",
    "GET_GROUP_OPTIONS",
    "GET_GROUP_OPTIONS_RESPONSE",
    "GET_PERMISSIONS_URL_RESPONSE",
    "SAVE_GROUPS",
    "SAVE_GROUPS_RESPONSE",
    "HIDE_GROUPS_SAVE_SUCCESS",
]);
