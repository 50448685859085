import React from "react";
import TaggingSummary from "../../farms/farms/details/tagging-summary";
import Button from "../../../components/form/button.jsx";
import TaggingAddressEdit from "./tagging-address-edit";

class Tagging extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            edit: false,
        };

        this.handleToggleEdit = this.handleToggleEdit.bind(this);
    }

    handleToggleEdit() {
        this.setState({
            edit: !this.state.edit,
        });
    }
    render() {
        return (
            <div>
                {this.state.edit ? (
                    <TaggingAddressEdit
                        toggleEdit={this.handleToggleEdit}
                        {...this.props}
                    />
                ) : (
                    <div>
                        <TaggingSummary {...this.props} />
                        <Button
                            type="submit"
                            inputClasses={{ "btn-primary": true }}
                            onClick={this.handleToggleEdit}
                            disabled={this.state.loading}
                        >
                            Edit
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}

export default Tagging;
