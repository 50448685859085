import React, { Component } from "react";

import getQueryStringParams from "./utils/getQueryStringParams";
import getReportData from "./utils/getReportData";

class SnapShot extends Component {
    constructor(props) {
        super(props);
        this.getEquipmentData = this.getEquipmentData.bind(this);

        const queryStringParams = getQueryStringParams(window.location.search);

        this.state = {
            farmId: props.farmId || queryStringParams.farmId,
            total: 0,
            unused: 0,
            chartData: {},
        };

        this.getEquipmentData(props.activeLease);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.activeLease !== nextProps.activeLease) {
            this.getEquipmentData(nextProps.activeLease);
        }
    }

    getEquipmentData(activeLease) {
        const reportParams = activeLease ? { activeLease } : {};

        getReportData(
            "/api/v2/getEquipmentReport",
            this.state.farmId,
            reportParams
        ).then((r) => {
            this.setState(r);
        });
    }
    render() {
        const { total, chartData, unused } = this.state;

        console.log("unused", unused);
        return (
            <div className="report">
                <div style={styles.statsWrapper}>
                    <div style={styles.statsContainer}>
                        <span style={styles.currentCount}>
                            {total.toLocaleString()}
                        </span>
                        <span style={styles.currentCountText}>
                            Containers in Use
                        </span>
                    </div>
                    <div style={styles.statsContainer}>
                        <span style={styles.currentCount}>
                            {unused.toLocaleString()}
                        </span>
                        <span style={styles.currentCountText}>
                            Unused spots
                        </span>
                    </div>
                </div>

                <table style={styles.tableStyle}>
                    <tr style={styles.tableHead}>
                        <th style={styles.mainColHead}>Container Type</th>
                        <th style={styles.colHead}>Count in Use</th>
                    </tr>
                    {Object.keys(chartData).map((key, i) => {
                        return (
                            <tr key={i} style={styles.tableRow}>
                                <td style={styles.mainCol}>{key}</td>
                                <td style={styles.middleCol}>
                                    {chartData[key].toLocaleString()}
                                </td>
                            </tr>
                        );
                    })}
                </table>
            </div>
        );
    }
}

export default SnapShot;

const styles = {
    container: {},
    statsWrapper: {
        display: "flex",
        flexDirection: "row",
    },
    statsContainer: {
        padding: 20,
        paddingBottom: 10,
    },
    currentCount: {
        fontFamily: "Avenir Next",
        fontSize: 24,
        fontWeight: 600,
        fill: "#000000",
    },
    currentCountText: {
        fontFamily: "Avenir Next",
        fontSize: 11,
        fontWeight: 700,
        fill: "#000000",
        marginLeft: 2,
    },
    tableStyle: {
        borderSpacing: 0,
        width: "100%",
    },
    tableHead: {
        height: "50px",
        background: "#fafafa",
    },
    tableRow: {
        height: "50px",
        whiteSpace: "nowrap",
    },
    mainColHead: {
        borderTop: "1px solid #E8E8E8",

        textAlign: "left",
        height: "52px",
        background: "#fafafa",
        fontFamily: "Avenir Next",
        fontSize: 13,
        fontWeight: "500",
        padding: "14px 5px 10px 20px",
        whiteSpace: "nowrap",
        transform: "translateY(-1px)",
        transition: "all .5s ease-in-out",
    },
    colHead: {
        fontFamily: "Avenir Next",
        fontSize: 13,
        fontWeight: "500",
        padding: "14px 20px 10px 20px",
        textAlign: "right",
        whiteSpace: "nowrap",
    },
    mainCol: {
        transition: "all .5s ease-in-out",
        fontFamily: "Avenir Next",
        fontSize: 15,
        fontWeight: "400",
        textAlign: "left",
        paddingLeft: "30px",
        borderTop: "1px solid #E8E8E8",
        height: "52px",
        padding: "14px 5px 10px 20px",
        background: "#ffffff",
    },
    middleCol: {
        whiteSpace: "nowrap",
        fontFamily: "Avenir Next",
        fontSize: 15,
        fontWeight: "400",
        textAlign: "right",
        padding: "14px 20px 10px 20px",
        borderTop: "1px solid #E8E8E8",
    },
};
