import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import Modal from "../../../../components/modal.jsx";
import SelectControl from "../../../../components/form/select-control.jsx";
import Button from "../../../../components/form/button.jsx";
import Spinner from "../../../../components/form/spinner.jsx";
import JsonFetch from "../../../../helpers/json-fetch";
import { getCost } from "./NextShipment.js";

export default (props) => {
    const {
        show,
        onClose,
        printers,
        paperCosts,
        distributorSubscriptionStatus,
        country,
        nextMobileShipment,
        nextIndustrialShipment,
    } = props;

    const [mobilePaperType, setMobilePaperType] = useState(
        nextMobileShipment || "mobile"
    );
    const [industrialPaperType, setIndustrialPaperType] = useState(
        nextIndustrialShipment || "industrial"
    );
    const [mobileRolls, setMobileRolls] = useState("");
    const [mobileLaminated, setMobileLaminated] = useState("");
    const [sixMilMobile, setSixMilMobile] = useState("");
    const [industrialRolls, setIndustrialRolls] = useState("");
    const [industrialSmallRolls, setIndustrialSmallRolls] = useState("");
    const [mobileStickerRolls, setMobileStickerRolls] = useState("");
    const [blackLineMobile, setBlackLineMobile] = useState("");
    const [sendForFree, setSendForFree] = useState(false);
    const [sendFulfillmentEmail, setSendFulfillmentEmail] = useState(true);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setMobileRolls("");
        setMobileLaminated("");
        setSixMilMobile("");
    }, [mobilePaperType]);

    useEffect(() => {
        setIndustrialRolls("");
        setIndustrialSmallRolls("");
    }, [industrialPaperType]);

    const showMobileDropdown =
        !!printers &&
        printers.find(
            (p) =>
                p.type === "mobile" && [
                    "TSC ALPHA-3R",
                    "Zebra ZQ521",
                    "TSC ALPHA-30L",
                    "TSC ALPHA-30RB",
                ]
        ) &&
        country !== "Australia";

    const showIndustrialDropdown =
        !!printers &&
        printers.find((p) => p.type === "industrial") &&
        country !== "Australia";
    const showBlackLineMobileDropdown =
        !!printers &&
        printers.find((p) => p.name === "TSC DA220") &&
        country === "Australia";
    const showMobileStickerRoll =
        !!printers &&
        printers.find((p) => p.name === "TSC DA220") &&
        country !== "Australia";

    const handleSubmit = () => {
        setError("");
        if (
            !mobileRolls &&
            !mobileLaminated &&
            !sixMilMobile &&
            !industrialRolls &&
            !industrialSmallRolls &&
            !mobileStickerRolls &&
            !blackLineMobile
        ) {
            setError(
                "Please select how many rolls of paper you would like to send"
            );
            return;
        }
        setLoading(true);
        const request = {
            method: "POST",
            url: `/api/enableTagging/${props.farmId}/sendPaper`,
            data: {
                mobileRolls,
                mobileLaminated,
                sixMilMobile,
                industrialRolls,
                industrialSmallRolls,
                mobileStickerRolls,
                sendForFree,
                sendFulfillmentEmail,
                blackLineMobile,
            },
        };
        JsonFetch(request, (err, response) => {
            setLoading(false);
            if (err) {
                setError(err.message);
                return;
            }

            setSuccess(true);
        });
    };

    const closeModal = () => {
        // reset things on close
        setMobileRolls("");
        setMobileLaminated("");
        setIndustrialRolls("");
        setIndustrialSmallRolls("");
        setMobilePaperType(nextMobileShipment || "mobile");
        setIndustrialPaperType(nextIndustrialShipment || "industrial");
        onClose();
    };

    return (
        <Modal header="Send More Paper" show={show} onClose={closeModal}>
            {(success && (
                <div
                    class="alert alert-success d-flex align-items-center alert-static"
                    role="alert"
                >
                    <div>
                        Paper has been sucessfully sent!{" "}
                        <a
                            onClick={() => {
                                setSuccess(false);
                            }}
                        >
                            Send More.
                        </a>
                    </div>
                </div>
            )) || (
                <>
                    {(error && <p className="text-danger">{error}</p>) || null}
                    {(showMobileDropdown && (
                        <>
                            <SelectControl
                                name="mobilePaperType"
                                label="Mobile Paper Type"
                                value={mobilePaperType}
                                onChange={(event) => {
                                    setMobilePaperType(event.target.value);
                                }}
                            >
                                <option value="">-- Select One --</option>
                                {/* <option value="mobile">
                                    Mobile Paper 125 per roll
                                </option> */}
                                {/* <option value="mobileLaminated">
                                    Laminated Mobile Paper 100 per roll
                                </option> */}
                                <option value="sixMilMobile">
                                    6mil Mobile Paper
                                </option>
                            </SelectControl>
                            {(mobilePaperType === "mobile" && (
                                <SelectControl
                                    name="mobileRolls"
                                    label={"Mobile Paper"}
                                    value={mobileRolls}
                                    onChange={(event) => {
                                        setMobileRolls(event.target.value);
                                    }}
                                >
                                    <option value="">-- Select One -- </option>
                                    <option value={12}>12</option>
                                    <option value={24}>24</option>
                                    <option value={36}>36</option>
                                    <option value={48}>48</option>
                                    <option value={60}>60</option>
                                </SelectControl>
                            )) ||
                                null}

                            {(mobilePaperType === "mobileLaminated" && (
                                <SelectControl
                                    name="mobileLaminated"
                                    label={"Laminated Mobile Paper"}
                                    value={mobileLaminated}
                                    onChange={(event) => {
                                        setMobileLaminated(event.target.value);
                                    }}
                                >
                                    <option value="">-- Select One -- </option>
                                    <option value={15}>15</option>
                                    <option value={30}>30</option>
                                    <option value={45}>45</option>
                                    <option value={60}>60</option>
                                </SelectControl>
                            )) ||
                                null}
                            {(mobilePaperType === "sixMilMobile" && (
                                <SelectControl
                                    name="sixMilMobile"
                                    label={"6mil Mobile Paper"}
                                    value={sixMilMobile}
                                    onChange={(event) => {
                                        setSixMilMobile(event.target.value);
                                    }}
                                >
                                    <option value="">-- Select One -- </option>
                                    <option value={20}>20</option>
                                    <option value={40}>40</option>
                                    <option value={60}>60</option>
                                    <option value={80}>80</option>
                                </SelectControl>
                            )) ||
                                null}
                        </>
                    )) ||
                        null}
                    {(showMobileStickerRoll && (
                        <SelectControl
                            name="mobileStickerRolls"
                            label={"Desktop Sticker Rolls"}
                            value={mobileRolls}
                            onChange={(event) => {
                                setMobileStickerRolls(event.target.value);
                            }}
                        >
                            <option value="">-- Select One -- </option>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={20}>20</option>
                            <option value={25}>25</option>
                        </SelectControl>
                    )) ||
                        null}

                    {(showBlackLineMobileDropdown && (
                        <SelectControl
                            name="blackLineMobile"
                            label={"Black Line Mobile Tags"}
                            value={blackLineMobile}
                            onChange={(event) => {
                                setBlackLineMobile(event.target.value);
                            }}
                        >
                            <option value="">-- Select One -- </option>
                            <option value={4}>4</option>
                            <option value={8}>8</option>
                            <option value={12}>12</option>
                            <option value={16}>16</option>
                            <option value={20}>20</option>
                            <option value={24}>24</option>
                        </SelectControl>
                    )) ||
                        null}

                    {(showIndustrialDropdown && (
                        <>
                            <SelectControl
                                name="industrialPaperType"
                                label="Industrial Paper Type"
                                value={industrialPaperType}
                                onChange={(event) => {
                                    setIndustrialPaperType(event.target.value);
                                }}
                            >
                                <option value="">-- Select One --</option>
                                <option value="industrial">7" Tags</option>
                                <option value="industrialSmall">
                                    Small Tags
                                </option>
                            </SelectControl>
                            {(industrialPaperType === "industrial" && (
                                <SelectControl
                                    name="industrialRolls"
                                    label={'Industrial 7" Paper'}
                                    value={industrialRolls}
                                    onChange={(event) => {
                                        setIndustrialRolls(event.target.value);
                                    }}
                                >
                                    <option value="">-- Select One -- </option>
                                    <option value={4}>4</option>
                                    <option value={8}>8</option>
                                    <option value={12}>12</option>
                                    <option value={16}>16</option>
                                    <option value={20}>20</option>
                                    <option value={24}>24</option>
                                </SelectControl>
                            )) ||
                                null}
                            {(industrialPaperType === "industrialSmall" && (
                                <SelectControl
                                    name="industrialSmallRolls"
                                    label={"Industrial Small Paper"}
                                    value={industrialSmallRolls}
                                    onChange={(event) => {
                                        setIndustrialSmallRolls(
                                            event.target.value
                                        );
                                    }}
                                >
                                    <option value="">-- Select One -- </option>
                                    <option value={4}>4</option>
                                    <option value={8}>8</option>
                                    <option value={12}>12</option>
                                    <option value={16}>16</option>
                                    <option value={20}>20</option>
                                    <option value={24}>24</option>
                                </SelectControl>
                            )) ||
                                null}
                        </>
                    )) ||
                        null}

                    <div className="form-group">
                        <label className="control-label">
                            Send Paper for Free?
                        </label>
                        <div>
                            <Switch
                                onChange={setSendForFree}
                                checked={sendForFree}
                                onColor="#5084F1"
                                uncheckedIcon={false}
                                checkedIcon={false}
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="control-label">
                            Send fullfillment email?
                        </label>
                        <div>
                            <Switch
                                onChange={setSendFulfillmentEmail}
                                checked={sendFulfillmentEmail}
                                onColor="#5084F1"
                                uncheckedIcon={false}
                                checkedIcon={false}
                            />
                        </div>
                    </div>
                    {((mobileRolls ||
                        mobileLaminated ||
                        sixMilMobile ||
                        industrialSmallRolls ||
                        industrialRolls ||
                        mobileStickerRolls ||
                        blackLineMobile) && (
                        <div className="form-group">
                            <h4>Cost:</h4>
                            <ul>
                                {(mobileRolls && (
                                    <li>{`${mobileRolls} rolls of mobile paper + shipping for ${(sendForFree
                                        ? 0
                                        : getCost(
                                              distributorSubscriptionStatus,
                                              paperCosts,
                                              "mobile",
                                              mobileRolls
                                          )
                                    ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                    })}`}</li>
                                )) ||
                                    null}
                                {(mobileLaminated && (
                                    <li>{`${mobileLaminated} rolls of laminated mobile paper + shipping for ${(sendForFree
                                        ? 0
                                        : getCost(
                                              distributorSubscriptionStatus,
                                              paperCosts,
                                              "mobileLaminated",
                                              mobileLaminated
                                          )
                                    ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                    })}`}</li>
                                )) ||
                                    null}
                                {(sixMilMobile && (
                                    <li>{`${sixMilMobile} rolls of 6mil mobile paper + shipping for ${(sendForFree
                                        ? 0
                                        : getCost(
                                              distributorSubscriptionStatus,
                                              paperCosts,
                                              "sixMilMobile",
                                              sixMilMobile
                                          )
                                    ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                    })}`}</li>
                                )) ||
                                    null}
                                {(mobileStickerRolls && (
                                    <li>{`${mobileStickerRolls} rolls of desktop stickers + shipping for ${(sendForFree
                                        ? 0
                                        : getCost(
                                              distributorSubscriptionStatus,
                                              paperCosts,
                                              "mobileStickerRoll",
                                              mobileStickerRolls
                                          )
                                    ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                    })}`}</li>
                                )) ||
                                    null}

                                {(blackLineMobile && (
                                    <li>{`${blackLineMobile} black line mobile rolls + shipping for ${(sendForFree
                                        ? 0
                                        : getCost(
                                              distributorSubscriptionStatus,
                                              paperCosts,
                                              "blackLineMobile",
                                              blackLineMobile
                                          )
                                    ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                    })}`}</li>
                                )) ||
                                    null}
                                {(industrialRolls && (
                                    <li>{`${industrialRolls} rolls of industrial paper + shipping for ${(sendForFree
                                        ? 0
                                        : getCost(
                                              distributorSubscriptionStatus,
                                              paperCosts,
                                              "industrial",
                                              industrialRolls
                                          )
                                    ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                    })}`}</li>
                                )) ||
                                    null}
                                {(industrialSmallRolls && (
                                    <li>{`${industrialSmallRolls} rolls of industrial paper + shipping for ${(sendForFree
                                        ? 0
                                        : getCost(
                                              distributorSubscriptionStatus,
                                              paperCosts,
                                              "industrialSmall",
                                              industrialSmallRolls
                                          )
                                    ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                    })}`}</li>
                                )) ||
                                    null}
                            </ul>
                        </div>
                    )) ||
                        null}
                    <Button
                        type="submit"
                        inputClasses={{ "btn-primary": true }}
                        onClick={handleSubmit}
                    >
                        Send Paper
                    </Button>
                    {"  "}
                    <Spinner show={loading} />
                </>
            )}
        </Modal>
    );
};
