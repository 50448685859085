import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import ClassNames from "classnames";
import logo from "../../media/logo.png";

const propTypes = {
  location: PropTypes.object,
};

class Navbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navBarOpen: false,
    };
  }

  componentWillReceiveProps() {
    this.setState({ navBarOpen: false });
  }

  classForPath(path) {
    return ClassNames({
      active: this.props.location.pathname === path,
    });
  }

  toggleMenu() {
    this.setState({ navBarOpen: !this.state.navBarOpen });
  }

  render() {
    const navBarCollapse = ClassNames({
      "navbar-collapse": true,
      collapse: !this.state.navBarOpen,
    });

    return (
      <div className="navbar navbar-inverse navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <Link className="navbar-brand" to="/account/settings">
              <img className="navbar-logo" src={logo} alt="BlueTrace" />
            </Link>
            <button
              className="navbar-toggle collapsed"
              onClick={this.toggleMenu.bind(this)}
            >
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
          </div>
          <div className={navBarCollapse}>
            <ul className="nav navbar-nav">
              <li className={this.classForPath("/account/settings")}>
                <Link to="/account/settings">Settings</Link>
              </li>
            </ul>
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a href="/login/logout">Sign out</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

Navbar.propTypes = propTypes;

export default Navbar;
