import Actions from "../actions";
import Button from "../../../../components/form/button.jsx";
import ControlGroup from "../../../../components/form/control-group.jsx";
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Spinner from "../../../../components/form/spinner.jsx";
import getQueryStringParams from "../../../reports/utils/getQueryStringParams";
import Store from "./store";
import TextControl from "../../../../components/form/text-control.jsx";

class ForgotPage extends React.Component {
  constructor(props) {
    super(props);

    this.input = {};
    this.state = Store.getState();
  }

  componentDidMount() {
    this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));

    if (this.input.email) {
      this.input.email.focus();
    }
  }

  componentWillUnmount() {
    this.unsubscribeStore();
  }

  onStoreChange() {
    this.setState(Store.getState());
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const params = getQueryStringParams(window.location.search);
    const app = Actions.forgot({
      email: this.input.email.value(),
      app: (params && params.app) || "",
    });
  }

  render() {
    const alerts = [];

    if (this.state.success) {
      alerts.push(
        <div key="success">
          <div className="alert alert-success">
            If an account matched that address, an email will be sent with
            instructions.
          </div>
        </div>
      );
    }

    let formElements;

    if (!this.state.success) {
      formElements = (
        <fieldset>
          <TextControl
            ref={(c) => (this.input.email = c)}
            name="email"
            label="What's your email?"
            hasError={this.state.hasError.email}
            help={this.state.help.email}
            disabled={this.state.loading}
          />
          <ControlGroup hideLabel={true} hideHelp={true}>
            <Button
              type="submit"
              inputClasses={{ "btn-primary": true }}
              disabled={this.state.loading}
            >
              Send reset
              <Spinner space="left" show={this.state.loading} />
            </Button>
          </ControlGroup>
        </fieldset>
      );
    }

    return (
      <section className="container">
        <Helmet>
          <title>Forgot password</title>
        </Helmet>
        <div className="container">
          <h1 className="page-header">Forgot your password?</h1>
          <div className="row">
            <div className="col-sm-6">
              <form onSubmit={this.handleSubmit.bind(this)}>
                {alerts}
                {formElements}
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ForgotPage;
