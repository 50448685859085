import PropTypes from "prop-types";
import React from "react";
import logo from "../../media/logo.png";

const propTypes = {
  location: PropTypes.object,
};

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="container">
        <div className="navbar-header">
          <a href="https://www.blue-trace.com" className="navbar-brand" to="">
            <img className="navbar-logo" src={logo} alt="BlueTrace" />
          </a>
        </div>
        <a className="navbar-link" href="https://www.blue-trace.com">
          Go to blue-trace.com{" "}
          <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.11 10.223V7.0472l1.308-1.3077v4.4835c0 .9323-.7628 1.6952-1.6953 1.6952H1.6952C.7628 11.9182 0 11.1553 0 10.223V4.1955C0 3.2628.7628 2.5 1.6952 2.5h4.4833L4.8707 3.8082H1.6952c-.2099 0-.3872.1771-.3872.3873v6.0275c0 .2098.1773.387.3872.387h6.0275c.21 0 .3873-.1772.3873-.387zM5.9428.4417L12.0137 0l-.442 6.0708L9.6368 4.136 6.0925 7.68 4.3333 5.9207l3.544-3.5442L5.9428.4417z"
              fill="#00c483"
            ></path>
          </svg>
        </a>
      </div>
    </div>
  );
};

Navbar.propTypes = propTypes;

export default Navbar;
