import React from "react";
import { Link } from "react-router-dom";

class NotFoundPage extends React.Component {
  render() {
    return (
      <section className="container">
        <h1 className="page-header">Not Found</h1>
        <p>That route didn’t match any handlers.</p>
        <Link to="/account">Go to home screen</Link>
      </section>
    );
  }
}

export default NotFoundPage;
