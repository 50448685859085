import Delete from "./reducers/delete";
import Details from "./reducers/details";
import Farm from "./reducers/farm";
import Groups from "./reducers/groups";
import { createStore, combineReducers } from "redux";
import Password from "./reducers/password";

export default createStore(
    combineReducers({
        delete: Delete,
        details: Details,
        password: Password,
        farm: Farm,
        groups: Groups,
    })
);
