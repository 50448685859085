export default {
    reports: [
        "Snapshot",
        "Inventory",
        "Equipment",
        "Yield",
        "Harvest",
        "Average Size",
        "Compare",
        "Exports",
    ],
    fieldTranslations: [
        { key: "oysterType", value: "Animal Type" },
        { key: "seedSource", value: "Seed Source" },
        { key: "year", value: "Year" },
        { key: "month", value: "Month" },
        { key: "sizeLabel", value: "Size" },
        { key: "meshSize", value: "Equipment" },
        { key: "nickname", value: "Fill Profile" },
        { key: "datePlanted", value: "Date Planted" },
        { key: "locationPlanted", value: "Location Planted" },
    ],
    dateRangeOptions: [
        "Last 7 Days",
        "Last 30 days",
        "Last 90 days",
        "Last 180 days",
        "Last 365 days",
        "Custom",
    ],
    reportTypes: ["Chart", "Table"],
    frequencyOptions: ["Yearly", "Monthly", "Weekly", "Daily"],
    dashboardMetrics: [
        { label: "Inventory", value: "Inventory" },
        { label: "Harvest", value: "Harvest" },
        { label: "Average Size", value: "Average Size" },
    ],
    metrics: [
        { label: "Inventory", value: "Inventory" },
        { label: "% Mortality", value: "mortality" },
        { label: "% Yield", value: "yield" },
        { label: "% Return", value: "returns" },
        { label: "Harvest", value: "Harvest" },
        { label: "Average Size", value: "Average Size" },
    ],
    spanOptions: [
        {
            label: "Monthly",
            value: "monthly",
        },
        {
            label: "Weekly",
            value: "weekly",
        },
        {
            label: "Daily",
            value: "daily",
        },
    ],
    countTypeOptions: [
        { label: "All Count Types", value: "", table: true, chart: false },
        {
            label: "Shell Stock",
            value: "shellStock",
            table: true,
            chart: true,
            harvestReport: false,
            fillColor: "#02B6FF",
        },
        {
            label: "Harvested",
            value: "harvested",
            table: true,
            chart: true,
            harvestReport: true,
            fillColor: "#32C465",
        },
        {
            label: "Lost",
            value: "lost",
            table: true,
            chart: true,
            harvestReport: false,
            fillColor: "#FF708F",
        },
        {
            label: "By Size",
            value: "bySize",
            table: false,
            harvestReport: false,
            chart: true,
        },
    ],
    fillColors: ["#02B6FF", "#FF708F", "#32C465", "#CE8FFF", "#FF8454"],
};
