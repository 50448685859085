import Actions from "../actions";
import Button from "../../../../components/form/button.jsx";
import ControlGroup from "../../../../components/form/control-group.jsx";
import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Spinner from "../../../../components/form/spinner.jsx";
import getQueryStringParams from "../../../reports/utils/getQueryStringParams";
import Store from "./store";
import TextControl from "../../../../components/form/text-control.jsx";

const propTypes = {
  match: PropTypes.object,
};

class ResetPage extends React.Component {
  constructor(props) {
    super(props);

    this.input = {};
    this.state = Store.getState();
  }

  componentDidMount() {
    this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));

    if (this.input.password) {
      this.input.password.focus();
    }
  }

  componentWillUnmount() {
    this.unsubscribeStore();
  }

  onStoreChange() {
    this.setState(Store.getState());
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    Actions.reset({
      email: this.props.match.params.email,
      key: this.props.match.params.key,
      password: this.input.password.value(),
    });
  }

  render() {
    const alerts = [];

    if (this.state.success) {
      alerts.push(
        <div key="success">
          <div className="alert alert-success">
            Your password has been reset.
          </div>
        </div>
      );
    }

    if (this.state.error && this.state.error !== "Success.") {
      alerts.push(
        <div key="danger" className="alert alert-danger">
          {this.state.error}
        </div>
      );
    }

    let formElements;

    if (!this.state.success) {
      formElements = (
        <fieldset>
          <TextControl
            ref={(c) => (this.input.password = c)}
            name="password"
            label="New password"
            type="password"
            hasError={this.state.hasError.password}
            help={this.state.help.password}
            disabled={this.state.loading}
          />
          <TextControl
            name="_email"
            label="Email"
            hasError={this.state.hasError.email}
            value={this.props.match.params.email}
            help={this.state.help.email}
            disabled={true}
          />
          <ControlGroup hideLabel={true} hideHelp={true}>
            <Button
              type="submit"
              inputClasses={{ "btn-primary": true }}
              disabled={this.state.loading}
            >
              Set password
              <Spinner space="left" show={this.state.loading} />
            </Button>
          </ControlGroup>
        </fieldset>
      );
    } else {
      const params = getQueryStringParams(window.location.search);
      const backLinks = {
        distributor: "oystertracker-distributor://",
        farmer: "oystertracker-farmer://",
      };

      if (params && params.app && backLinks[params.app]) {
        formElements = (
          <a href={backLinks[params.app]} className="btn btn-primary">
            Back to app
          </a>
        );
      }
    }

    return (
      <section className="container">
        <Helmet>
          <title>Reset password</title>
        </Helmet>
        <div className="container">
          <h1 className="page-header">Reset your password</h1>
          <div className="row">
            <div className="col-sm-6">
              <form onSubmit={this.handleSubmit.bind(this)}>
                {alerts}
                {formElements}
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ResetPage.propTypes = propTypes;

export default ResetPage;
