import Constants from "../constants";
import ObjectAssign from "object-assign";
import ParseValidation from "../../../../helpers/parse-validation";

const initialState = {
    id: "",
    amount: "",
    loading: false,
    hydrated: false,
    saveSuccess: false,
};
const reducer = function (state = initialState, action) {
    if (action.type === Constants.GET_INVOICE) {
        return ObjectAssign({}, state, {
            loading: true,
            hydrated: false,
        });
    }

    if (action.type === Constants.GET_INVOICE_RESPONSE) {
        const validation = ParseValidation(action.response);

        return ObjectAssign({}, state, {
            loading: false,
            hydrated: true,
            error: validation.error,
            hasError: validation.hasError,
            help: validation.help,
            id: action.response.id,
            amount: action.response.amount_due,
        });
    }

    if (action.type === Constants.PAY_INVOICE) {
        return ObjectAssign({}, state, {
            loading: true,
            hydrated: false,
        });
    }

    if (action.type === Constants.PAY_INVOICE_RESPONSE) {
        const validation = ParseValidation(action.response);

        if (validation.error) {
            return ObjectAssign({}, state, {
                loading: false,
                hydrated: true,
                error: validation.error,
                hasError: validation.hasError,
            });
        }
        return ObjectAssign({}, state, {
            loading: false,
            hydrated: true,
            error: null,
            hasError: false,
            saveSuccess: true,
        });
    }

    return state;
};

export default reducer;
