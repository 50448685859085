import React, { useState, useEffect } from "react";

import request from "superagent";
import Report from "./Report";
import Dashboard from "./Dashboard";
import Equipment from "./Equipment";
import Export from "./Export";
import Dropdown from "./components/Dropdown";
import Toggle from "./components/Toggle";

import CONSTANTS from "./utils/constants";

import "react-dates/lib/css/_datepicker.css";
import "./index.scss";

const {
    reportTypes,
    reports,
    dateRangeOptions,
    frequencyOptions,
    fieldTranslations,
} = CONSTANTS;

export default (props) => {
    const [leases, setLeases] = useState([]);
    const [email, setEmail] = useState("");
    const [activeLease, setActiveLease] = useState("");
    const [activeLeaseId, setActiveLeaseId] = useState(null);
    const [viewByOptions, setViewByOptions] = useState(
        props.viewByOptions || {}
    );
    const [farmId, setFarmId] = useState(props.farmId || null);
    const [metric, setMetric] = useState(reports[0]);
    const [reportType, setReportType] = useState(reportTypes[0]);
    const [editMode, toggleEditDashboard] = useState(false);
    const [dateRange, setDateRange] = useState(dateRangeOptions[2]);
    const [frequency, setFrequency] = useState(frequencyOptions[1]);
    const [activeViewBy, setActiveViewBy] = useState({});
    const [dateRange2, setDateRange2] = useState(dateRangeOptions[2]);
    const [frequency2, setFrequency2] = useState(frequencyOptions[1]);
    const [activeViewBy2, setActiveViewBy2] = useState({});
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startDate2, setStartDate2] = useState(null);
    const [endDate2, setEndDate2] = useState(null);
    const [graphType, setGraphType] = useState("share");
    const segments = Object.keys(viewByOptions)
        .filter((key) => !!key)
        .map((key) => {
            return fieldTranslations.find((field) => field.key === key)
                ? fieldTranslations.find((field) => field.key === key).value
                : key;
        });
    const [segment, setSegment] = useState(segments[0]);

    useEffect(() => {
        if (props.leases) {
            const newLeases = JSON.parse(JSON.stringify(props.leases));
            newLeases.unshift({ name: "All" });
            setLeases(newLeases);
            setActiveLease("All");
        }

        const setValues = () => {
            if (window.leases) {
                const leases = window.leases;

                leases.unshift({ name: "All" });
                setLeases(window.leases);

                setActiveLease(window.activeLease.name);

                setViewByOptions(window.filters);

                setFarmId(window.farmId);

                setEmail(window.email);
            } else {
                setTimeout(setValues, 100);
            }
        };

        setValues();
    }, [props.leases]);

    useEffect(() => {
        if (activeLease && activeLease !== "All") {
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(
                    JSON.stringify({ lease: activeLease })
                );
            }

            const leaseId = leases.find((l) => l.name === activeLease).leaseId;

            setActiveLeaseId(leaseId);
        } else {
            setActiveLeaseId(null);
        }
    }, [activeLease, leases]);

    useEffect(() => {
        toggleEditDashboard(false);

        setGraphType("share");
    }, [metric]);

    const handleExport = async (exportReport = null) => {
        //get email and pass it back to native app for save keeping
        const exportEmail = window.prompt(
            "Enter your email and we'll email you a csv of the data",
            email
        );

        if (exportEmail) {
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                        email: exportEmail,
                    })
                );
                window.email = exportEmail;
            }

            setEmail(exportEmail);

            try {
                const response = await request
                    .put(`/api/v2/reportExport/${farmId}`)
                    .send({
                        activeLease: activeLeaseId,
                        dateRange,
                        frequency,
                        activeViewBy,
                        metric: exportReport || metric,
                        email: exportEmail,
                        startDate,
                        endDate,
                        graphType,
                        segment: fieldTranslations.find(
                            (field) => field.value === segment
                        )
                            ? fieldTranslations.find(
                                  (field) => field.value === segment
                              ).key
                            : segment,
                    });

                if (response.body.error) {
                    if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(
                            JSON.stringify({ error: response.body.error })
                        );
                    } else {
                        alert(response.body.error);
                    }
                } else {
                    if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(
                            JSON.stringify({
                                message: "Your export was successfully sent.",
                                report: exportReport || metric,
                            })
                        );
                    } else {
                        alert("Your export was successfully sent.");
                    }
                }
            } catch (err) {
                console.log("get export error", err);
            }
        }
    };

    const leaseNames = leases.map((l) => l.name);

    return (
        <div className="reportWrap">
            <div className="reportNav">
                {reports.map((report) => {
                    const isActive = report === metric;

                    const text = isActive ? `• ${report}` : report;
                    return (
                        <button
                            className={`link ${isActive ? "link-active" : ""}`}
                            onClick={() => {
                                setMetric(report);
                            }}
                        >
                            {text}
                        </button>
                    );
                })}
            </div>

            <div className="reportContainer">
                <div className="reportHeader">
                    <Dropdown
                        theme="dark"
                        options={leaseNames}
                        value={activeLease}
                        setValue={setActiveLease}
                        label={"Growing Areas"}
                    />

                    <div className="right">
                        {metric !== "Exports" &&
                        metric !== "Compare" &&
                        metric !== "Snapshot" &&
                        metric !== "Size Snapshot" ? (
                            <button
                                onClick={() => {
                                    handleExport();
                                }}
                                className="button__dark"
                            >
                                Export
                            </button>
                        ) : null}
                        {metric !== "Snapshot" &&
                        metric !== "Equipment" &&
                        metric !== "Exports" &&
                        metric !== "Size Snapshot" ? (
                            <Toggle
                                values={reportTypes}
                                activeValue={reportType}
                                setToggleValue={setReportType}
                            />
                        ) : null}
                        {metric === "Snapshot" && (
                            <button
                                onClick={() => {
                                    toggleEditDashboard(true);
                                }}
                                className="button__dark"
                            >
                                Edit
                            </button>
                        )}
                    </div>
                </div>
                {metric === "Exports" ? (
                    <Export
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        handleExport={handleExport}
                    />
                ) : metric === "Equipment" ? (
                    <Equipment farmId={farmId} activeLease={activeLeaseId} />
                ) : metric === "Snapshot" ? (
                    <Dashboard
                        edit={editMode}
                        farmId={farmId}
                        activeLease={activeLease}
                        activeLeaseId={activeLeaseId}
                        viewByOptions={viewByOptions}
                        toggleEditDashboard={toggleEditDashboard}
                    />
                ) : (
                    <div className="chartWrap">
                        <Report
                            leases={leaseNames}
                            activeLease={activeLease}
                            activeLeaseId={activeLeaseId}
                            setActiveLease={setActiveLease}
                            viewByOptions={viewByOptions}
                            farmId={farmId}
                            metric={metric}
                            reportType={reportType}
                            dateRange={dateRange}
                            setDateRange={setDateRange}
                            frequency={frequency}
                            setFrequency={setFrequency}
                            activeViewBy={activeViewBy}
                            setActiveViewBy={setActiveViewBy}
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            graphType={graphType}
                            setGraphType={setGraphType}
                            segment={segment}
                            segments={segments}
                            setSegment={setSegment}
                        />
                        {(metric === "Compare" && (
                            <Report
                                leases={leaseNames}
                                activeLease={activeLease}
                                activeLeaseId={activeLeaseId}
                                setActiveLease={setActiveLease}
                                viewByOptions={viewByOptions}
                                farmId={farmId}
                                metric={metric}
                                reportType={reportType}
                                dateRange={dateRange2}
                                setDateRange={setDateRange2}
                                frequency={frequency2}
                                setFrequency={setFrequency2}
                                activeViewBy={activeViewBy2}
                                setActiveViewBy={setActiveViewBy2}
                                startDate={startDate2}
                                endDate={endDate2}
                                setStartDate={setStartDate2}
                                setEndDate={setEndDate2}
                            />
                        )) ||
                            null}
                    </div>
                )}
            </div>
        </div>
    );
};
