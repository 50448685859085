import FluxConstant from "flux-constant";

export default FluxConstant.set([
    "GET_DETAILS",
    "GET_DETAILS_RESPONSE",
    "GET_USER",
    "GET_USER_RESPONSE",
    "GET_INVOICE",
    "GET_INVOICE_RESPONSE",
    "PAY_INVOICE",
    "PAY_INVOICE_RESPONSE",
]);
