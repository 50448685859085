import FluxConstant from "flux-constant";

export default FluxConstant.set([
    "FORGOT",
    "FORGOT_RESPONSE",
    "GET_USER_CREDS",
    "GET_USER_CREDS_RESPONSE",
    "LOGIN",
    "LOGIN_RESPONSE",
    "LOGOUT",
    "LOGOUT_RESPONSE",
    "RESET",
    "RESET_RESPONSE",
]);
