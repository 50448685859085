import Details from "./reducers/details";
import User from "./reducers/user";
import Invoice from "./reducers/invoice";
import { createStore, combineReducers } from "redux";

export default createStore(
    combineReducers({
        details: Details,
        user: User,
        invoice: Invoice,
    })
);
