import Details from "./reducers/details";
import Password from "./reducers/password";
import { combineReducers, createStore } from "redux";
import User from "./reducers/user";

export default createStore(
    combineReducers({
        details: Details,
        password: Password,
        user: User,
    })
);
