import Constants from "../constants";
import ObjectAssign from "object-assign";
import ParseValidation from "../../../../../helpers/parse-validation";

const initialState = {
    loading: false,
    showSaveSuccess: false,
    error: undefined,
    hasError: {},
    help: {},
    lineId: "",
    trays: [],
    name: "",
    location: "",
};
const reducer = function (state = initialState, action) {
    if (action.type === Constants.GET_DETAILS_RESPONSE) {
        const stateUpdates = ObjectAssign({}, initialState);

        if (action.response.hasOwnProperty("lines")) {
            stateUpdates.leases = action.response.lines.reverse();
        }

        return ObjectAssign({}, stateUpdates);
    }

    if (action.type === Constants.NEW_LINE) {
        return ObjectAssign({}, state, {
            loading: true,
            newLine: action.request.data.newLine,
        });
    }

    if (action.type === Constants.NEW_LINE_RESPONSE) {
        const validation = ParseValidation(action.response);
        const stateUpdates = {
            loading: false,
            showSaveSuccess: !action.err,
            error: validation.error,
            hasError: validation.hasError,
            help: validation.help,
        };

        if (action.response.hasOwnProperty("lines")) {
            stateUpdates.newLine = "";
            stateUpdates.lines = action.response.lines.reverse();
        }

        return ObjectAssign({}, state, stateUpdates);
    }

    if (action.type === Constants.HIDE_LINE_SAVE_SUCCESS) {
        return ObjectAssign({}, state, {
            showSaveSuccess: false,
        });
    }

    // if (action.type === Constants.DELETE_LEASE) {
    //     return ObjectAssign({}, state, {
    //         loading: true
    //     });
    // }
    //
    // if (action.type === Constants.DELETE_LEASE_RESPONSE) {
    //
    //     const validation = ParseValidation(action.response);
    //
    //     return ObjectAssign({}, state, {
    //         loading: false,
    //         error: validation.error,
    //         leases: action.response.leases
    //     });
    // }

    return state;
};

export default reducer;
