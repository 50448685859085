import React from "react";
import {
    LineChart,
    Line,
    BarChart,
    Bar,
    Cell,
    Legend,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
} from "recharts";
import { formatDate, quantFormatter } from "../utils/formatters";
import isPercentMetric from "../utils/isPercentMetric";

const colors = ["#02B6FF", "#FF708F", "#32C465"];
const barColors = ["#E1BAFF", "#BF6EFF", "#5A00B5"];
const segmentColors = [
    "#FF6E8D",
    "#DC1D6C",
    "#EAA765",
    "#EA7807",
    "#EBD765",
    "#C2A500",
    "#34E073",
    "#028532",
    "#54CDFF",
    "#027EE3",
    "#BF6EFF",
    "#5A00B5",
];
export default ({ data, frequency, metric, report, type = "share" }) => {
    if (!data.length) {
        return null;
    }

    const formatTooltipValue = (value, metric) => {
        if (isPercentMetric(metric, type)) {
            return `${value.toFixed(1)}%`;
        }
        if (metric === "Average Size") {
            return `${value.toFixed(1)}mm`;
        }

        return value.toLocaleString();
    };

    const height = report === "Compare" ? "60%" : "100%";

    let domain = [0, "dataMax"];

    if (isPercentMetric(metric, type)) {
        const maxValue = data.reduce((max, d) => {
            Object.keys(d)
                .filter((key) => key === "name")
                .forEach((key) => {
                    if (d[key] && d[key] > max) {
                        max = d[key];
                    }
                });

            return max;
        }, 100);
        domain = [0, maxValue];
    }

    if (type === "count") {
        const legendIconSize = metric === "Inventory" ? 0 : 8;
        return (
            <ResponsiveContainer width="100%" height={height}>
                <BarChart data={data} key={`${report}-${metric}`}>
                    <Legend
                        wrapperStyle={styles.legendStyle}
                        verticalAlign="top"
                        iconType="circle"
                        iconSize={legendIconSize}
                        align="right"
                        layout="horizontal"
                        formatter={(value) =>
                            metric === "Inventory"
                                ? ""
                                : value.charAt(0).toUpperCase() + value.slice(1)
                        }
                    />

                    <CartesianGrid vertical={true} horizontal={false} />

                    <XAxis
                        axisLine={false}
                        tickLine={false}
                        dataKey="name"
                        tick={styles.axisStyleX}
                        tickFormatter={(tick) =>
                            metric === "Inventory"
                                ? tick
                                : formatDate(tick, {
                                      span: frequency,
                                  }).toUpperCase()
                        }
                    />
                    <YAxis
                        axisLine={false}
                        tickLine={false}
                        tick={styles.axisStyleY}
                        orientation="left"
                        tickFormatter={(tick) =>
                            quantFormatter(tick, null, metric, type)
                        }
                        domain={domain}
                    />
                    <Tooltip
                        labelFormatter={(date) =>
                            metric === "Inventory"
                                ? date
                                : formatDate(date, {
                                      span: frequency,
                                  }).toUpperCase()
                        }
                        formatter={(value) => formatTooltipValue(value, metric)}
                        labelStyle={styles.axisStyleX}
                        contentStyle={styles.tooltipData}
                    />
                    {metric === "Inventory" ? (
                        <Bar dataKey="count">
                            {data.map((entry, i) => {
                                const colorIndex =
                                    i < segmentColors.length
                                        ? i
                                        : i % segmentColors.length;

                                return (
                                    <Cell
                                        key={`cell-${i}`}
                                        fill={segmentColors[colorIndex]}
                                    />
                                );
                            })}
                        </Bar>
                    ) : (
                        Object.keys(data[0])
                            .filter((key) => key !== "name")
                            .map((key, i) => {
                                return (
                                    <Bar
                                        dataKey={key}
                                        stackId="1"
                                        fill={barColors[i]}
                                    />
                                );
                            })
                    )}
                </BarChart>
            </ResponsiveContainer>
        );
    }

    return (
        <ResponsiveContainer width="100%" height={height}>
            <LineChart data={data} key={`${report}-${metric}`}>
                <Legend
                    wrapperStyle={styles.legendStyle}
                    verticalAlign="top"
                    iconType="circle"
                    iconSize={8}
                    align="right"
                    layout="horizontal"
                    formatter={(value) =>
                        metric === "Harvest"
                            ? "Harvest Yield"
                            : value.charAt(0).toUpperCase() + value.slice(1)
                    }
                />
                <CartesianGrid vertical={true} horizontal={false} />

                <XAxis
                    axisLine={false}
                    tickLine={false}
                    dataKey="name"
                    tick={styles.axisStyleX}
                    tickFormatter={(tick) =>
                        formatDate(tick, { span: frequency }).toUpperCase()
                    }
                />
                <YAxis
                    axisLine={false}
                    tickLine={false}
                    tick={styles.axisStyleY}
                    orientation="left"
                    tickFormatter={(tick) => quantFormatter(tick, null, metric)}
                    domain={domain}
                />
                <Tooltip
                    labelFormatter={(date) =>
                        formatDate(date, { span: frequency }).toUpperCase()
                    }
                    formatter={(value) => formatTooltipValue(value, metric)}
                    labelStyle={styles.axisStyleX}
                    contentStyle={styles.tooltipData}
                />
                {Object.keys(data[0])
                    .filter((key) => key !== "name")
                    .map((key, i) => (
                        <Line
                            connectNulls
                            type="linear"
                            dataKey={key}
                            stackId="1"
                            stroke={colors[i]}
                            strokeWidth={2}
                            dot={
                                frequency === "Daily"
                                    ? false
                                    : {
                                          stroke: colors[i],
                                          strokeWidth: 4,
                                      }
                            }
                        />
                    ))}
            </LineChart>
        </ResponsiveContainer>
    );
};

const styles = {
    axisStyleX: {
        fontFamily: "Avenir Next",
        fontSize: 11,
        fontWeight: 700,
        fill: "#000000",
    },
    axisStyleY: {
        fontFamily: "Avenir Next",
        fontSize: 11,
        fontWeight: 700,
        fill: "#000000",
    },
    tooltipData: {
        fontFamily: "Avenir Next",
        fontSize: 11,
        fontWeight: 600,
    },
    legendStyle: {
        paddingLeft: "60px",
        paddingTop: "5px",
        paddingBottom: "10px",
        fontFamily: "Avenir Next",
        fontSize: 14,
        fontWeight: 500,
        textAlign: "center",
    },
};
