import FluxConstant from "flux-constant";

export default FluxConstant.set([
    "ADD_FARM_DETAILS",
    "ADD_FARM_DETAILS_RESPONSE",
    "CANCEL_FARM_MANAGER",
    "CANCEL_FARM_MANAGER_RESPONSE",
    "CLEAN",
    "CLEAN_RESPONSE",
    "DELETE",
    "DELETE_LEASE",
    "DELETE_LEASE_RESPONSE",
    "DELETE_RESPONSE",
    "ENABLE_TAGGING",
    "ENABLE_TAGGING_RESPONSE",
    "GET_DETAILS",
    "GET_DETAILS_RESPONSE",
    "GET_FILTERS",
    "GET_FILTERS_RESPONSE",
    "GET_PRODUCTS",
    "GET_PRODUCTS_RESPONSE",
    "HIDE_DETAILS_SAVE_SUCCESS",
    "HIDE_LEASE_SAVE_SUCCESS",
    "NEW_BAG",
    "NEW_BAG_RESPONSE",
    "NEW_LEASE",
    "NEW_LEASE_RESPONSE",
    "NEW_LINE",
    "NEW_LINE_RESPONSE",
    "NEW_TRAY",
    "NEW_TRAY_RESPONSE",
    "RESEND_EMAIL",
    "RESEND_EMAIL_ALERT_HIDE",
    "RESEND_EMAIL_RESPONSE",
    "SAVE_DETAILS",
    "SAVE_DETAILS_RESPONSE",
    "SAVE_SUBSCRIPTION",
    "SAVE_SUBSCRIPTION_RESPONSE",
    "TOGGLE_TAB",
    "TOGGLE_EDIT_MODE",
    "LOAD_PAYMENT_PLANS",
    "LOAD_PAYMENT_PLANS_RESPONSE",
    "LOAD_ACTIVITIES_RESPONSE",
    "UPDATE_PAYMENTPLAN",
    "UPDATE_PAYMENTPLAN_RESPONSE",
    "SAVE_FARM_DETAILS",
    "SAVE_FARM_DETAILS_RESPONSE",
    "TOGGLE_SAVE_SUCCESS",
    "UPDATE_FEATURE_FLAGS",
    "UPDATE_FEATURE_FLAGS_RESPONSE",
    "HIDE_ALERT",
]);
