import React from "react";

class Footer extends React.Component {
  render() {
    const year = new Date().getFullYear();

    return (
      <div className="footer">
        <div className="container">
          <span className="copyright pull-right">&#169; {year} Acme, Inc.</span>
          <ul className="links">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/login/logout">Sign out</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Footer;
