import Actions from "./actions";
import DeleteForm from "../../../admin/components/delete-form.jsx";
import DetailsForm from "./details-form.jsx";
import PermissionsForm from "./permissions-form.jsx";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import Store from "./store";

const propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

class DetailsPage extends React.Component {
  constructor(props) {
    super(props);

    Actions.getDetails(this.props.match.params.id);

    this.state = Store.getState();
  }

  componentDidMount() {
    this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));
  }

  componentWillUnmount() {
    this.unsubscribeStore();
  }

  onStoreChange() {
    this.setState(Store.getState());
  }

  render() {
    if (!this.state.details.hydrated) {
      return (
        <section className="container">
          <h1 className="page-header">
            <Link to="/admin/admin-groups">Admin Groups</Link> / loading...
          </h1>
        </section>
      );
    }

    if (this.state.details.showFetchFailure) {
      return (
        <section className="container">
          <h1 className="page-header">
            <Link to="/admin/admin-groups">Admin Groups</Link> / Error
          </h1>
          <div className="alert alert-danger">{this.state.details.error}</div>
        </section>
      );
    }

    const id = this.state.details._id;
    const name = this.state.details.name;

    return (
      <section className="container">
        <h1 className="page-header">
          <Link to="/admin/admin-groups">Admin Groups</Link> / {name}
        </h1>
        <div className="row">
          <div className="col-sm-8">
            <DetailsForm {...this.state.details} />
            <PermissionsForm {...this.state.permissions} />
            <DeleteForm
              {...this.state.delete}
              action={Actions.delete.bind(Actions, id, this.props.history)}
            />
          </div>
        </div>
      </section>
    );
  }
}

DetailsPage.propTypes = propTypes;

export default DetailsPage;
