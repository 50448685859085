import Actions from "./actions";
import Button from "../../../components/form/button.jsx";
import Alert from "../../../components/alert.jsx";
import FarmSummary from "../../farms/farms/wizard/farm-summary";
import Reports from "../../farms/farms/details/Reports";
import FarmDetails from "./FarmDetails";
import Manager from "./manager";
import Tagging from "./tagging";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import Store from "./store";
import actions from "./actions";
import StripeForm from "./stripe/StripeForm";
import { Tabs, Tab } from "react-bootstrap";

const propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

class DetailsPage extends React.Component {
  constructor(props) {
    super(props);

    Actions.getDetails(this.props.match.params.id);
    Actions.getUser();
    Actions.loadPaymentPlans();
    Actions.getFilters(this.props.match.params.id);

    this.state = Store.getState();
  }

  componentDidMount() {
    this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));

    console.log(
      "window.location.search",
      window.location.search,
      window.location.search.includes("invoicePaid=true")
    );
    if (window.location.search.includes("invoicePaid=true")) {
      actions.showSaveSuccess();
    }
  }

  componentWillUnmount() {
    this.unsubscribeStore();
  }

  onStoreChange() {
    this.setState(Store.getState());
  }

  render() {
    if (!this.state.details.hydrated) {
      return (
        <section className="container">
          <h1 className="page-header">
            <Link to="/manage">Farms</Link> / loading...
          </h1>
        </section>
      );
    }

    if (this.state.details.showFetchFailure) {
      return (
        <section className="container">
          <h1 className="page-header">
            <Link to="/manage">Farms</Link> / Error
          </h1>
          <div className="alert alert-danger">{this.state.details.error}</div>
        </section>
      );
    }

    const name = this.state.details.name;
    let pageContent;

    let alerts = [];

    if (
      window.location.search.includes("invoicePaid=true") &&
      this.state.details.showSaveSuccess
    ) {
      alerts.push(
        <Alert
          key="success"
          type="success"
          message={"Invoice successfully paid!"}
          onClose={actions.hideSaveSuccess}
        />
      );
    }

    if (this.state.details.error) {
      alerts.push(
        <Alert key="danger" type="danger" message={this.state.details.error} />
      );
    }
    const { subscriptionId, customerId, cardInfo, plan } = this.state.details;

    const managerEasySubscribe = !!(
      !subscriptionId &&
      plan &&
      customerId &&
      cardInfo
    );

    const infoSubscribeLink = managerEasySubscribe && (
      <Button
        type="submit"
        inputClasses={{
          "btn-link": true,
        }}
        onClick={() => {
          actions.updatePaymentDetails({
            id: this.state.details._id,
            setupFee: this.state.details.setupFee,
            plan: this.state.details.plan,
            token: null,
            customerId: this.state.details.customerId,
            subscriptionId: this.state.details.subscriptionId,
          });
        }}
      >
        Use credit card on file to subscribe
      </Button>
    );

    pageContent = (
      <div>
        <FarmSummary
          {...this.state.details}
          subscribeButton={infoSubscribeLink}
        />
        <StripeForm farm={this.state.details} user={this.state.user} />
      </div>
    );

    return (
      <section className="container">
        {alerts}
        <h1 className="page-header">
          <Link to="/manage">Farms</Link> / {name}
        </h1>
        <div className="row">
          <div className="col-sm-12">
            <Tabs id="details-tabs">
              <Tab title="Farm Info & Payment Details" eventKey={0} key={0}>
                {pageContent}
              </Tab>
              <Tab title="About My Farm" eventKey={1} key={1}>
                <FarmDetails {...this.state.details} />
              </Tab>
              {(this.state.details.leases &&
                this.state.details.leases.length && (
                  <Tab title="Reports" eventKey={4} key={4}>
                    <Reports
                      farmId={this.props.match.params.id}
                      farm={this.state.details}
                    />
                  </Tab>
                )) ||
                null}
              {(this.state.details.plan && (
                <Tab title="Farm Manager Subscription" eventKey={2} key={2}>
                  <Manager {...this.state.details} />
                  {managerEasySubscribe && (
                    <Button
                      inputClasses={{
                        "btn-primary": true,
                      }}
                      type="submit"
                      onClick={() => {
                        actions.updatePaymentDetails({
                          id: this.state.details._id,
                          setupFee: this.state.details.setupFee,
                          plan: this.state.details.plan,
                          token: null,
                          customerId: this.state.details.customerId,
                          subscriptionId: this.state.details.subscriptionId,
                        });
                      }}
                    >
                      Use credit card on file to subscribe
                    </Button>
                  )}
                </Tab>
              )) ||
                null}
              {(this.state.details.taggingActive && (
                <Tab title="Tagging Subscription" eventKey={3} key={3}>
                  <Tagging {...this.state.details} />
                </Tab>
              )) ||
                null}
            </Tabs>
          </div>
        </div>
      </section>
    );
  }
}

DetailsPage.propTypes = propTypes;

export default DetailsPage;
