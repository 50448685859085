import FluxConstant from "flux-constant";

export default FluxConstant.set([
    "GET_DETAILS",
    "GET_DETAILS_RESPONSE",
    "GET_USER",
    "GET_USER_RESPONSE",
    "HIDE_DETAILS_SAVE_SUCCESS",
    "HIDE_PASSWORD_SAVE_SUCCESS",
    "HIDE_USER_SAVE_SUCCESS",
    "SAVE_DETAILS",
    "SAVE_DETAILS_RESPONSE",
    "SAVE_PASSWORD",
    "SAVE_PASSWORD_RESPONSE",
    "SAVE_USER",
    "SAVE_USER_RESPONSE",
]);
