/* global window */
import ApiActions from "../../../../actions/api";
import Constants from "./constants";
import Store from "./store";
import Qs from "qs";

class Actions {
    static getResults(data) {
        ApiActions.get(
            "/api/admin-groups",
            data,
            Store,
            Constants.GET_RESULTS,
            Constants.GET_RESULTS_RESPONSE
        );
    }

    static changeSearchQuery(data, history) {
        history.push({
            pathname: "/admin/admin-groups",
            search: `?${Qs.stringify(data)}`,
        });

        window.scrollTo(0, 0);
    }

    static showCreateNew(data) {
        Store.dispatch({
            type: Constants.SHOW_CREATE_NEW,
        });
    }

    static hideCreateNew(data) {
        Store.dispatch({
            type: Constants.HIDE_CREATE_NEW,
        });
    }

    static createNew(data, history) {
        ApiActions.post(
            "/api/admin-groups",
            data,
            Store,
            Constants.CREATE_NEW,
            Constants.CREATE_NEW_RESPONSE,
            (err, response) => {
                if (!err) {
                    this.hideCreateNew();

                    history.replace(window.location);

                    window.scrollTo(0, 0);
                }
            }
        );
    }
}

export default Actions;
