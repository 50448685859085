import Constants from "../constants";
import ObjectAssign from "object-assign";
import ParseValidation from "../../../../../helpers/parse-validation";

const initialState = {
    show: false,
    loading: false,
    error: undefined,
    hasError: {},
    help: {},
    location: "",
    name: "",
};
const reducer = function (state = initialState, action) {
    if (action.type === Constants.CREATE_NEW) {
        return ObjectAssign({}, state, {
            loading: true,
            location: action.request.data.location,
            name: action.request.data.name,
        });
    }

    if (action.type === Constants.CREATE_NEW_RESPONSE) {
        const validation = ParseValidation(action.response);
        const stateUpdates = {
            loading: false,
            error: validation.error,
            hasError: validation.hasError,
            help: validation.help,
        };

        if (!validation.error) {
            stateUpdates.location = "";
            stateUpdates.name = "";
        }

        return ObjectAssign({}, state, stateUpdates);
    }

    if (action.type === Constants.SHOW_CREATE_NEW) {
        return ObjectAssign({}, state, {
            show: true,
        });
    }

    if (action.type === Constants.HIDE_CREATE_NEW) {
        return ObjectAssign({}, state, {
            show: false,
        });
    }

    return state;
};

export default reducer;
