import React from "react";
import { Elements } from "react-stripe-elements";

import InjectedCheckoutForm from "./CardForm";

class StripeForm extends React.Component {
  render() {
    return (
      <Elements>
        <InjectedCheckoutForm user={this.props.user} farm={this.props.farm} />
      </Elements>
    );
  }
}

export default StripeForm;
