import React from "react";
import ReportContainer from "../../../reports/ReportContainer";

class foo extends React.Component {
  render() {
    return (
      <ReportContainer
        farmId={this.props.farmId}
        leases={this.props.farm.leases}
        viewByOptions={this.props.farm.viewByOptions}
      />
    );
  }
}

export default foo;
