import React, { useState, useEffect } from "react";
import Dropdown from "./components/Dropdown";
import MultiPageDropdown from "./components/MultiPageDropdown";
import request from "superagent";
import Table from "./components/Table";
import AreaChart from "./components/AreaChart";
import CONSTANTS from "./utils/constants";
import moment from "moment";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import isAfterDay from "react-dates/lib/utils/isAfterDay";

const {
    dateRangeOptions,
    frequencyOptions,
    metrics,
    fieldTranslations,
} = CONSTANTS;

export default ({
    leases,
    activeLease,
    activeLeaseId,
    metric,
    setActiveLease,
    viewByOptions,
    farmId,
    reportType,
    dateRange,
    setDateRange,
    frequency,
    setFrequency,
    activeViewBy,
    setActiveViewBy,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    graphType,
    setGraphType,
    segment,
    segments,
    setSegment,
}) => {
    const [internalMetric, setInternalMetric] = useState(metrics[0].label);
    const [data, setData] = useState([]);
    const [focusedInput, setFocusedInput] = useState("startDate");

    useEffect(() => {
        const getChartData = async () => {
            try {
                const reportMetric =
                    metric === "Compare"
                        ? metrics.find((m) => m.label === internalMetric).value
                        : metric;

                const response = await request
                    .put(`/api/v2/getFarmInventory/${farmId}`)
                    .send({
                        activeLease: activeLeaseId,
                        dateRange,
                        frequency,
                        activeViewBy,
                        metric: reportMetric,
                        startDate,
                        endDate,
                        graphType,
                        segment: fieldTranslations.find(
                            (field) => field.value === segment
                        )
                            ? fieldTranslations.find(
                                  (field) => field.value === segment
                              ).key
                            : segment,
                    });

                setData(response.body.data);
            } catch (err) {
                alert(err);
            }
        };
        if (farmId) {
            if (
                dateRange !== "Custom" ||
                (dateRange === "Custom" && startDate && endDate)
            )
                getChartData();
        }
    }, [
        activeLeaseId,
        dateRange,
        frequency,
        activeViewBy,
        farmId,
        metric,
        internalMetric,
        startDate,
        endDate,
        graphType,
        segment,
    ]);

    const customDates = (
        <div className="customDates">
            <DateRangePicker
                startDate={startDate} // momentPropTypes.momentObj or null,
                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                endDate={endDate} // momentPropTypes.momentObj or null,
                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                }} // PropTypes.func.isRequired,
                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={(focusedInput) => {
                    if (!focusedInput) return; // doesn't update the focusedInput if it is trying to close the DRP
                    setFocusedInput(focusedInput);
                    document.activeElement.blur();
                }} // PropTypes.func.isRequired,
                // withFullScreenPortal={true}
                noBorder={true}
                isOutsideRange={(day) => isAfterDay(day, moment())}
            />
        </div>
    );

    let chartData =
        metric !== "Yield" ? data : data[graphType] ? data[graphType] : data;

    return (
        <div className="report">
            <div className="dropdownRow">
                {metric === "Inventory" && graphType === "count" ? (
                    <div className="dropdownContainer segmentDropdown">
                        <Dropdown
                            type="radio"
                            placeholder="View By"
                            label="View By"
                            options={segments}
                            setValue={setSegment}
                            value={segment}
                            defaultValue={segments[0]}
                        />
                    </div>
                ) : (
                    <div className="dropdownContainer">
                        <MultiPageDropdown
                            options={viewByOptions}
                            values={activeViewBy}
                            setValues={setActiveViewBy}
                            label="View by"
                            placeholder="All Animals"
                        />
                        {metric === "Compare" && (
                            <Dropdown
                                type="radio"
                                placeholder="Metric"
                                label="Metric"
                                options={metrics.map((m) => m.label)}
                                setValue={setInternalMetric}
                                value={internalMetric}
                                defaultValue={metrics[0].label}
                            />
                        )}
                    </div>
                )}
                {!(metric === "Inventory" && graphType === "count") && (
                    <div className="dropdownContainer">
                        <Dropdown
                            type="radio"
                            placeholder="Date range"
                            label="Date range"
                            options={dateRangeOptions}
                            setValue={setDateRange}
                            value={dateRange}
                            defaultValue={dateRangeOptions[2]}
                            customSelector={customDates}
                            customValue={"Custom"}
                            customSelectedValue={
                                startDate || endDate
                                    ? `${
                                          (startDate &&
                                              startDate.format("M/D/YY")) ||
                                          "m/d/y"
                                      } - ${
                                          (endDate &&
                                              endDate.format("M/D/YY")) ||
                                          "m/d/y"
                                      }`
                                    : "Custom"
                            }
                        />

                        <Dropdown
                            type="radio"
                            placeholder="Frequency"
                            label="Frequency"
                            options={frequencyOptions}
                            setValue={setFrequency}
                            value={frequency}
                            defaultValue={frequencyOptions[1]}
                        />
                    </div>
                )}
            </div>
            {metric === "Yield" && (
                <div className="yieldReportType">
                    <button
                        className={graphType === "share" ? "active" : ""}
                        onClick={() => {
                            setGraphType("share");
                        }}
                    >
                        Share
                    </button>
                    <button
                        className={graphType === "count" ? "active" : ""}
                        onClick={() => {
                            setGraphType("count");
                        }}
                    >
                        Count
                    </button>
                </div>
            )}
            {metric === "Inventory" && (
                <div className="yieldReportType">
                    <button
                        className={graphType === "share" ? "active" : ""}
                        onClick={() => {
                            setGraphType("share");
                        }}
                    >
                        Over Time
                    </button>
                    <button
                        className={graphType === "count" ? "active" : ""}
                        onClick={() => {
                            setGraphType("count");
                        }}
                    >
                        Snapshot
                    </button>
                </div>
            )}
            {(reportType === "Table" && (
                <Table
                    data={chartData}
                    frequency={frequency}
                    metric={metric === "Compare" ? internalMetric : metric}
                    type={graphType}
                />
            )) || (
                <AreaChart
                    data={chartData}
                    metric={metric === "Compare" ? internalMetric : metric}
                    report={metric}
                    frequency={frequency}
                    type={graphType}
                />
            )}
        </div>
    );
};
