import Actions from "./actions";
import Alert from "../../../../components/alert.jsx";
import Button from "../../../../components/form/button.jsx";
import ControlGroup from "../../../../components/form/control-group.jsx";
import LinkState from "../../../../helpers/link-state";
import PropTypes from "prop-types";
import React from "react";
import Spinner from "../../../../components/form/spinner.jsx";
import TextControl from "../../../../components/form/text-control.jsx";

const propTypes = {
  _id: PropTypes.string,
  error: PropTypes.string,
  hasError: PropTypes.object,
  help: PropTypes.object,
  loading: PropTypes.bool,
  name: PropTypes.string,
  showSaveSuccess: PropTypes.bool,
};

class DetailsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.name,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      name: nextProps.name,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const id = this.props._id;
    const data = {
      name: this.state.name,
    };

    Actions.saveDetails(id, data);
  }

  render() {
    const alerts = [];

    if (this.props.showSaveSuccess) {
      alerts.push(
        <Alert
          key="success"
          type="success"
          onClose={Actions.hideDetailsSaveSuccess}
          message="Success. Changes have been saved."
        />
      );
    }

    if (this.props.error) {
      alerts.push(
        <Alert key="danger" type="danger" message={this.props.error} />
      );
    }

    const formElements = (
      <fieldset>
        <legend>Details</legend>
        {alerts}
        <TextControl
          name="name"
          label="Name"
          value={this.state.name}
          onChange={LinkState.bind(this)}
          hasError={this.props.hasError.name}
          help={this.props.help.name}
          disabled={this.props.loading}
        />
        <ControlGroup hideLabel={true} hideHelp={true}>
          <Button
            type="submit"
            inputClasses={{ "btn-primary": true }}
            disabled={this.props.loading}
          >
            Save changes
            <Spinner space="left" show={this.props.loading} />
          </Button>
        </ControlGroup>
      </fieldset>
    );

    return <form onSubmit={this.handleSubmit.bind(this)}>{formElements}</form>;
  }
}

DetailsForm.propTypes = propTypes;

export default DetailsForm;
