import PropTypes from 'prop-types';
import React from 'react';
import LinkState from '../../../../helpers/link-state';
import Button from '../../../../components/form/button.jsx';
import TextControl from '../../../../components/form/text-control.jsx';
import SelectControl from '../../../../components/form/select-control.jsx';
import Actions from '../details/actions';
import StateOptions from '../details/StateOptions';
import ActivityCheckboxes from '../details/ActivityCheckboxes';

const propTypes = {
	_id: PropTypes.string,
	address1: PropTypes.string,
	address2: PropTypes.string,
	city: PropTypes.string,
	country: PropTypes.string,
	activities: PropTypes.array,
	email: PropTypes.string,
	billingEmail: PropTypes.string,
	current: PropTypes.object,
	error: PropTypes.string,
	hasError: PropTypes.object,
	help: PropTypes.object,
	loading: PropTypes.bool,
	location: PropTypes.string,
	log: PropTypes.array,
	name: PropTypes.string,
	companyName: PropTypes.string,
	newFarm: PropTypes.string,
	options: PropTypes.array,
	owner: PropTypes.string,
	saveAction: PropTypes.func,
	showSaveSuccess: PropTypes.bool,
	state: PropTypes.string,
	successCloseAction: PropTypes.func,
	zip: PropTypes.string,
	type: PropTypes.string,
};

class FarmForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			_id: undefined,
			address1: '',
			address2: '',
			city: '',
			state: '',
			country: '',
			activities: [],
			email: '',
			billingEmail: '',
			zip: '',
			name: '',
			companyName: '',
			type: 'farm',
			owner: '',
			loading: false,
			showSaveSuccess: false,
		};
	}

	componentWillMount() {
		this.setState({
			address1: this.props.address1,
			address2: this.props.address2,
			city: this.props.city,
			state: this.props.state,
			country: this.props.country,
			activities: this.props.activities,
			email: this.props.email,
			billingEmail: this.props.billingEmail,
			zip: this.props.zip,
			location: this.props.location,
			name: this.props.name,
			companyName: this.props.companyName,
			owner: this.props.owner,
			loading: this.props.loading,
			showSaveSuccess: this.props.showSaveSuccess,
			type: this.props.type,
			_id: this.props._id,
		});
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			address1: nextProps.address1,
			address2: nextProps.address2,
			city: nextProps.city,
			state: nextProps.state,
			country: nextProps.country,
			activities: nextProps.activities,
			email: nextProps.email,
			billingEmail: nextProps.billingEmail,
			zip: nextProps.zip,
			location: nextProps.location,
			name: nextProps.name,
			companyName: nextProps.companyName,
			owner: nextProps.owner,
			loading: nextProps.loading,
			showSaveSuccess: nextProps.showSaveSuccess,
			type: nextProps.type,
			_id: nextProps._id,
		});
	}

	setInitialActivities = (activities) => {
		this.setState({
			activities,
		});
	};

	setSelectedActivities = (actId) => {
		console.log('farm type', this.state.type);
		const index = this.state.activities.indexOf(actId);
		let activities = this.state.activities;

		if (this.state.type === 'farm') {
			activities = [actId];
		} else {
			if (index !== -1) {
				activities.splice(index, 1);
			} else {
				activities.push(actId);
			}
		}
		this.setState({
			activities,
		});
	};

	handleSubmit() {
		const address1 = this.state.address1;
		const address2 = this.state.address2;
		const city = this.state.city;
		const name = this.state.name;
		const companyName = this.state.companyName;
		const owner = this.state.owner;
		const state = this.state.state;
		const country = this.state.country;
		const activities = this.state.activities;
		const email = this.state.email;
		const billingEmail = this.state.billingEmail;
		const zip = this.state.zip;
		const farmId = this.state._id;
		const type = this.state.type;

		if (!country) {
			alert('Country is required');
			return;
		}
		if (!state) {
			alert('State is required');
			return;
		}

		if (!billingEmail) {
			alert('A Billing Email is required');
			return;
		}

		if (farmId) {
			Actions.updateFarmInfo(
				farmId,
				address1,
				address2,
				city,
				name,
				companyName,
				owner,
				state,
				country,
				activities,
				email,
				billingEmail,
				zip,
				type
			);
		} else {
			Actions.saveFarmInfo(
				address1,
				address2,
				city,
				name,
				companyName,
				owner,
				state,
				country,
				activities,
				email,
				billingEmail,
				zip,
				type
			);
		}
		if (this.props.toggleEditMode) {
			this.props.toggleEditMode(false);
		}
	}

	render() {
		return (
			<div>
				<TextControl
					name="name"
					label="Farm Name"
					value={this.state.name}
					onChange={LinkState.bind(this)}
					hasError={this.props.hasError.name}
					help={this.props.help.name}
					disabled={this.state.loading}
					maxLength={40}
				/>
				<TextControl
					name="companyName"
					label="Company Name"
					value={this.state.companyName}
					onChange={LinkState.bind(this)}
					hasError={this.props.hasError.companyName}
					help={this.props.help.name}
					disabled={this.state.loading}
				/>
				<SelectControl
					name="type"
					label="Type"
					value={this.state.type}
					onChange={LinkState.bind(this)}
					disabled={this.state.loading}
				>
					<option value="">- Select One - </option>
					<option value="farm">Farm</option>
					<option value="distributor">Distributor</option>
				</SelectControl>
				{(this.props.allActivities.length && this.props.plans.length && (
					<ActivityCheckboxes
						allActivities={this.props.allActivities}
						activities={this.state.activities}
						updateActivities={this.setSelectedActivities}
						setInitialActivities={this.setInitialActivities}
						farmType={this.state.type}
						isNewFarm={!this.state._id}
					/>
				)) ||
					null}
				<TextControl
					name="owner"
					label="Owner"
					value={this.state.owner}
					onChange={LinkState.bind(this)}
					hasError={this.props.hasError.name}
					help={this.props.help.name}
					disabled={this.state.loading}
				/>
				<TextControl
					name="email"
					label="Email"
					value={this.state.email}
					onChange={LinkState.bind(this)}
					disabled={this.state.loading}
				/>
				<TextControl
					name="billingEmail"
					label="Billing Email*"
					value={this.state.billingEmail}
					onChange={LinkState.bind(this)}
					disabled={this.state.loading}
				/>
				<TextControl
					name="address1"
					label="Address 1"
					value={this.state.address1}
					onChange={LinkState.bind(this)}
					disabled={this.state.loading}
				/>
				<TextControl
					name="address2"
					label="Address 2"
					value={this.state.address2}
					onChange={LinkState.bind(this)}
					disabled={this.state.loading}
				/>
				<TextControl
					name="city"
					label="City"
					value={this.state.city}
					onChange={LinkState.bind(this)}
					disabled={this.state.loading}
				/>

				<SelectControl
					name="state"
					label="State"
					value={this.state.state}
					onChange={LinkState.bind(this)}
					disabled={this.state.loading}
				>
					<StateOptions country={this.state.country} />
				</SelectControl>

				<TextControl
					name="zip"
					label="Zip Code"
					value={this.state.zip}
					onChange={LinkState.bind(this)}
					disabled={this.state.loading}
				/>

				<SelectControl
					name="country"
					label="Country"
					value={this.state.country}
					onChange={LinkState.bind(this)}
					disabled={this.state.loading}
				>
					<option value="">- Select One - </option>
					<option value="Canada">Canada</option>
					<option value="Mexico">Mexico</option>
					<option value="USA">USA</option>
					<option value="Australia">Australia</option>
				</SelectControl>

				<Button
					type="submit"
					inputClasses={{ 'btn-primary': true }}
					onClick={this.handleSubmit.bind(this)}
					disabled={this.state.loading}
				>
					{this.state.loading ? 'Save changes...' : 'Save changes'}
				</Button>
			</div>
		);
	}
}

FarmForm.propTypes = propTypes;

export default FarmForm;
