import DetailsForm from './details-form.jsx';
import Actions from './actions';
import PasswordForm from './password-form.jsx';
import React from 'react';
import Store from './store';
import UserForm from './user-form.jsx';

class SettingsPage extends React.Component {
	constructor(props) {
		super(props);

		Actions.getUser();

		this.state = Store.getState();
	}

	componentDidMount() {
		this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));
	}

	componentWillUnmount() {
		this.unsubscribeStore();
	}

	onStoreChange() {
		this.setState(Store.getState());
	}

	render() {
		return (
			<section className="container">
				<h1 className="page-header">Account settings</h1>
				<div className="row">
					<div className="col-sm-6">
						<UserForm {...this.state.user} />
						<PasswordForm {...this.state.password} />
					</div>
				</div>
			</section>
		);
	}
}

export default SettingsPage;
