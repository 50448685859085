import Actions from './actions';
import Button from '../../../../components/form/button.jsx';
import Spinner from '../../../../components/form/spinner.jsx';
import DeleteForm from '../../../admin/components/delete-form.jsx';
import FarmSummary from '../wizard/farm-summary';
import Alert from '../../../../components/alert.jsx';
import StripeForm from './adminStripeForm/StripeForm.jsx';
import FarmInfo from '../wizard/farm-info';
import FarmDetails from './FarmDetails';
import Tagging from './tagging';
import Reports from './Reports';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Store from './store';

import Manager from './manager';
import { Tabs, Tab } from 'react-bootstrap';
import FeatureFlags from './FeatureFlags';

const propTypes = {
	history: PropTypes.object,
	match: PropTypes.object,
};

class DetailsPage extends React.Component {
	constructor(props) {
		super(props);

		Actions.getDetails(this.props.match.params.id);
		Actions.loadPaymentPlans();
		Actions.loadActivities();
		Actions.getFilters(this.props.match.params.id);

		this.state = Store.getState();

		this.handleCleanSubmit = this.handleCleanSubmit.bind(this);
		this.resendTaggingSetupEmail = this.resendTaggingSetupEmail.bind(this);
	}

	componentDidMount() {
		this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));
	}

	componentWillUnmount() {
		this.unsubscribeStore();
	}

	onStoreChange() {
		this.setState(Store.getState());
	}

	resendTaggingSetupEmail() {
		console.log('resending for account', this.state.details._id);
		Actions.resendTaggingEmail(this.state.details._id);
	}

	toggleEditMode(toggle = true) {
		Actions.toggleEditMode(toggle);
	}

	handleCleanSubmit(event) {
		event.preventDefault();
		event.stopPropagation();

		if (!window.confirm('Are you sure?')) {
			return;
		}

		const action = Actions.clean.bind(
			Actions,
			this.state.details._id,
			this.props.history
		);

		action();
	}

	handleSelect(activeTab) {
		Actions.toggleTab(activeTab);
	}

	render() {
		if (!this.state.details.hydrated) {
			return (
				<section className="container">
					<h1 className="page-header">
						<Link to="/farms">Farms</Link> / loading...
					</h1>
				</section>
			);
		}

		const { subscriptionId, customerId, cardInfo, plan } = this.state.details;

		const managerEasySubscribe = !!(
			!subscriptionId &&
			plan &&
			customerId &&
			cardInfo
		);

		const infoSubscribeLink = managerEasySubscribe && (
			<Button
				type="submit"
				inputClasses={{
					'btn-link': true,
				}}
				onClick={() => {
					Actions.updatePaymentDetails({
						id: this.state.details._id,
						token: null,
						email: null,
					});
				}}
			>
				Use credit card on file to subscribe
			</Button>
		);

		if (this.state.details.showFetchFailure) {
			return (
				<section className="container">
					<h1 className="page-header">
						<Link to="/farms">Farms</Link> / Error
					</h1>
					<div className="alert alert-danger">{this.state.details.error}</div>
					<p>
						<a href={window.location.pathname}>Go Back</a>
					</p>
				</section>
			);
		}

		const id = this.state.details._id;
		const type = this.state.details.type;
		const name = this.state.details.name;
		const editMode = this.state.details.editMode;
		let farmInfo;

		const cleanForm = (
			<div className="panel panel-warning">
				<div className="panel-heading">
					<h3 className="panel-title">Danger zone</h3>
				</div>
				<div className="panel-body">
					<form onSubmit={this.handleCleanSubmit}>
						{alert}
						<Button
							type="submit"
							inputClasses={{
								'btn-warning': true,
								'pull-right': true,
							}}
							disabled={this.state.loading}
						>
							Clean
							<Spinner space="left" show={this.props.loading} />
						</Button>
						<p>
							This action will remove all groups, activities, leases and fill
							profiles from this farm.
						</p>
					</form>
				</div>
			</div>
		);

		if (!editMode) {
			farmInfo = (
				<div>
					<FarmSummary
						{...this.state.details}
						subscribeButton={infoSubscribeLink}
						role="admin"
						resendTaggingSetupEmail={this.resendTaggingSetupEmail}
					/>
					{(this.state.details.billingEmail && (
						<StripeForm farm={this.state.details} user={this.state.user} />
					)) ||
						null}
					<div className="text-center">
						<p>
							<Button
								type="submit"
								inputClasses={{ 'btn-primary btn-lg': true }}
								onClick={this.toggleEditMode.bind(this)}
								disabled={this.state.loading}
							>
								Edit
							</Button>
						</p>
					</div>
					{cleanForm}
					<DeleteForm
						{...this.state.delete}
						action={Actions.delete.bind(Actions, id, this.props.history)}
					/>
				</div>
			);
		} else {
			farmInfo = (
				<FarmInfo
					toggleEditMode={this.toggleEditMode.bind(this)}
					{...this.state.details}
				/>
			);
		}

		let alerts = [];

		if (this.state.details.alert) {
			alerts.push(
				<Alert
					key="success"
					type="success"
					message={this.state.details.alert}
					onClose={Actions.hideAlert}
				/>
			);
		}

		if (this.state.details.emailSent) {
			alerts.push(
				<Alert
					key="success"
					type="success"
					message={'Reminder Email Sent'}
					onClose={Actions.hideEmailReminder}
				/>
			);
		}

		if (this.state.details.error) {
			alerts.push(
				<Alert key="danger" type="danger" message={this.state.details.error} />
			);
		}

		return (
			<section className="container">
				{alerts}
				<h1 className="page-header">
					<Link to="/farms">Farms</Link> / {name}
				</h1>
				<div className="row">
					<div className="col-sm-12">
						{this.state.details.type === 'distributor' &&
							this.state.details.taggingActive === true &&
							!this.state.details.distributorSubscriptionId && (
								<div className="alert alert-danger alert-static" role="alert">
									Please add a payment tier to this distributors subscription.
								</div>
							)}
						<Tabs
							activeKey={this.state.details.activeTab}
							onSelect={this.handleSelect.bind(this)}
							id="details-tabs"
						>
							<Tab title="Farm Info" eventKey={0} key={0}>
								{(!this.state.details.billingEmail && (
									<div
										className="alert alert-warning alert-static"
										role="alert"
									>
										A billing email must be added to the farm before onboarding
										and adding credit card information.
									</div>
								)) ||
									null}
								{farmInfo}
							</Tab>
							{(this.state.details.leases &&
								this.state.details.leases.length &&
								Object.keys(this.state.details.viewByOptions).length && (
									<Tab title="Reports" eventKey={4} key={4}>
										<Reports
											farmId={this.props.match.params.id}
											farm={this.state.details}
										/>
									</Tab>
								)) ||
								null}

							{id && (
								<Tab title="Farm Manager Subscription" eventKey={1} key={1}>
									{(!this.state.details.billingEmail && (
										<div
											className="alert alert-warning alert-static"
											role="alert"
										>
											A billing email must be added to the farm before
											onboarding and adding credit card information.
										</div>
									)) || <Manager {...this.state.details} />}

									{managerEasySubscribe && (
										<div>
											<br />
											<Button
												inputClasses={{
													'btn-primary': true,
												}}
												type="submit"
												onClick={() => {
													Actions.updatePaymentDetails({
														id: this.state.details._id,
														token: null,
														email: null,
													});
												}}
											>
												Use credit card on file to subscribe
											</Button>
										</div>
									)}
								</Tab>
							)}
							{id && (
								<Tab
									title={
										this.state.details.type &&
										this.state.details.type === 'distributor'
											? 'Distributor Subscription'
											: 'Tagging Subscription'
									}
									eventKey={2}
									key={2}
								>
									{(!this.state.details.billingEmail && (
										<div
											className="alert alert-warning alert-static"
											role="alert"
										>
											A billing email must be added to the farm before
											onboarding and adding credit card information.
										</div>
									)) || <Tagging {...this.state.details} />}
								</Tab>
							)}

							<Tab title="Features" eventKey={6} key={6}>
								<FeatureFlags {...this.state.details} />
							</Tab>

							<Tab title="About My Farm" eventKey={5} key={5}>
								<FarmDetails {...this.state.details} />
							</Tab>
						</Tabs>
					</div>
				</div>
			</section>
		);
	}
}

DetailsPage.propTypes = propTypes;

export default DetailsPage;
