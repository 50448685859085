import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppUniversal from "./app-universal.jsx";

import "../../core/bootstrap.scss";
import "./index.scss";

export default () => {
  return (
    <BrowserRouter>
      <AppUniversal />
    </BrowserRouter>
  );
};
