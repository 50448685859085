/* global window */
import ApiActions from "../../../actions/api";
import Constants from "./constants";
import ForgotStore from "./forgot/store";
import LoginStore from "./home/store";
import Qs from "qs";
import ResetStore from "./reset/store";

class Actions {
    static forgot(data) {
        ApiActions.post(
            "/api/login/forgot",
            data,
            ForgotStore,
            Constants.FORGOT,
            Constants.FORGOT_RESPONSE
        );
    }

    static getUserCreds() {
        if (!global.window) {
            return;
        }

        ApiActions.get(
            "/api/users/my",
            undefined,
            LoginStore,
            Constants.GET_USER_CREDS,
            Constants.GET_USER_CREDS_RESPONSE,
            (err, response) => {
                if (!err) {
                    const query = Qs.parse(window.location.search.substring(1));

                    if (query.returnUrl) {
                        window.location.href = query.returnUrl;
                    } else if (response && response.groups) {
                        if (
                            response.groups &&
                            (response.groups.admin || response.groups.root)
                        ) {
                            window.location.href =
                                query.returnUrl &&
                                query.returnUrl.startsWith("/farms")
                                    ? query.returnUrl
                                    : "/farms";
                        } else if (response.groups.farmmanager) {
                            window.location.href =
                                query.returnUrl &&
                                query.returnUrl.startsWith("/manage")
                                    ? query.returnUrl
                                    : "/manage";
                        } else {
                            window.location.href = "/account/settings";
                        }
                    }
                }
            }
        );
    }

    static login(data) {
        ApiActions.post(
            "/api/login",
            data,
            LoginStore,
            Constants.LOGIN,
            Constants.LOGIN_RESPONSE,
            (err, response) => {
                if (!err) {
                    sessionStorage.setItem("jwtToken", response.token);

                    const query = Qs.parse(window.location.search.substring(1));
                    console.log("hey", response.user.groups);

                    if (query.returnUrl && query.returnUrl !== "/") {
                        window.location.href = query.returnUrl;
                    } else if (response && response.user) {
                        if (
                            response.user.groups &&
                            (response.user.groups.admin ||
                                response.user.groups.root)
                        ) {
                            window.location.href =
                                query.returnUrl &&
                                query.returnUrl.startsWith("/farms")
                                    ? query.returnUrl
                                    : "/farms";
                        } else if (response.user.groups.farmmanager) {
                            window.location.href =
                                query.returnUrl &&
                                query.returnUrl.startsWith("/manage")
                                    ? query.returnUrl
                                    : "/manage";
                        } else {
                            window.location.href = "/account/settings";
                        }
                    }
                }
            }
        );
    }

    static logout() {
        sessionStorage.removeItem("jwtToken");
        window.location.href = "/";
    }

    static reset(data) {
        ApiActions.post(
            "/api/login/reset",
            data,
            ResetStore,
            Constants.RESET,
            Constants.RESET_RESPONSE
        );
    }
}

export default Actions;
