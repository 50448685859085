import Footer from "./footer.jsx";
import Home from "./home/index.jsx";
import Navbar from "./navbar.jsx";
import NotFound from "./not-found.jsx";
import React from "react";
import { Route, Switch } from "react-router-dom";
import Settings from "./settings/index.jsx";

export default () => (
  <div>
    <Route component={Navbar} />
    <Switch>
      <Route path="/account" exact component={Home} />
      <Route path="/account/settings" exact component={Settings} />

      <Route component={NotFound} />
    </Switch>
    <Footer />
  </div>
);
