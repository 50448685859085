import React from "react";
import SelectControl from "../../../../components/form/select-control";
import LinkState from "../../../../helpers/link-state";

export default function SelectNewPaper({
    state,
    parentThis,
    country,
    printerOptions,
}) {
    if (!state.newPrinter) {
        return null;
    }

    const labelPrinter = "TSC DA220";

    const newMobilePrinter =
        printerOptions.find((p) => p.name === state.newPrinter).type ===
            "mobile" && state.newPrinter !== labelPrinter;

    const newIndustrialPrinter =
        printerOptions.find((p) => p.name === state.newPrinter).type ===
        "industrial";

    const newLabelPrinter =
        state.newPrinter === labelPrinter && country !== "Australia";

    const newAusPrinter =
        state.newPrinter === labelPrinter && country === "Australia";

    return (
        <>
            {(newMobilePrinter && (
                <>
                    <SelectControl
                        name="newPaperType"
                        label="New Paper Type"
                        value={state.newPaperType}
                        onChange={LinkState.bind(parentThis)}
                    >
                        <option value="">-- Select One --</option>
                        {/* <option value="mobile">
                            Mobile Paper 125 per roll
                        </option> */}
                        {/* <option value="mobileLaminated">
                            Laminated Mobile Paper 100 per roll
                        </option> */}
                        <option value="sixMilMobile">
                            6mil Mobile Paper 75 per roll
                        </option>
                    </SelectControl>
                    <SelectControl
                        name="newPaper"
                        label={"& Tag Rolls"}
                        value={state.newPaper}
                        onChange={LinkState.bind(parentThis)}
                    >
                        <option value="">-- Select One -- </option>
                        {state.newPaperType === "mobile" ? (
                            <>
                                <option value={12}>12</option>
                                <option value={24}>24</option>
                                <option value={36}>36</option>
                                <option value={48}>48</option>
                                <option value={60}>60</option>
                            </>
                        ) : state.newPaperType === "sixMilMobile" ? (
                            <>
                                <option value={20}>20</option>
                                <option value={40}>40</option>
                                <option value={60}>60</option>
                                <option value={80}>80</option>
                            </>
                        ) : (
                            <>
                                <option value={15}>15</option>
                                <option value={30}>30</option>
                                <option value={45}>45</option>
                                <option value={60}>60</option>
                            </>
                        )}
                    </SelectControl>
                </>
            )) ||
                null}

            {(newIndustrialPrinter && (
                <>
                    <SelectControl
                        name="newPaperType"
                        label="New Paper Type"
                        onChange={LinkState.bind(parentThis)}
                    >
                        <option value="">-- Select One --</option>
                        <option value="industrial">7" Tags</option>
                        <option value="industrialSmall">Small Tags</option>
                    </SelectControl>
                    <SelectControl
                        name="newPaper"
                        label={"& Tag Rolls"}
                        value={state.newPaper}
                        onChange={LinkState.bind(parentThis)}
                    >
                        <option value="">-- Select One -- </option>
                        <option value={4}>4</option>
                        <option value={8}>8</option>
                        <option value={12}>12</option>
                        <option value={16}>16</option>
                        <option value={20}>20</option>
                    </SelectControl>
                </>
            )) ||
                null}

            {(newLabelPrinter && (
                <SelectControl
                    name="newStickerRolls"
                    label={"& Sticker Rolls"}
                    value={state.newStickerRolls}
                    onChange={LinkState.bind(parentThis)}
                >
                    <option value="">-- Select One -- </option>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                    <option value={25}>25</option>
                </SelectControl>
            )) ||
                null}

            {(newAusPrinter && (
                <SelectControl
                    name="newBlackLineMobile"
                    label={"& Black Line Mobie Rolls"}
                    value={state.newBlackLineMobile}
                    onChange={LinkState.bind(parentThis)}
                    disabled={
                        !(
                            state.newPrinter === "TSC DA220" &&
                            country === "Australia"
                        )
                    }
                >
                    <option value="">-- Select One -- </option>
                    <option value={4}>4</option>
                    <option value={8}>8</option>
                    <option value={12}>12</option>
                    <option value={16}>16</option>
                    <option value={20}>20</option>
                    <option value={24}>24</option>
                </SelectControl>
            )) ||
                null}
        </>
    );
}
