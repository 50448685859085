import React from 'react';
import TextControl from '../../../components/form/text-control.jsx';
import LinkState from '../../../helpers/link-state';
import SelectControl from '../../../components/form/select-control.jsx';
import Button from '../../../components/form/button.jsx';
import Spinner from '../../../components/form/spinner.jsx';
import Alert from '../../../components/alert.jsx';
import StateOptions from '../../farms/farms/details/StateOptions';
import { Link, Redirect } from 'react-router-dom';

import Actions from './actions';
import StripeForm from './stripe-form.jsx';
import { injectStripe } from 'react-stripe-elements';

class InvoiceForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			displayErrors: false,
			name: '',
			business: '',
			address1: '',
			address2: '',
			city: '',
			country: '',
			state: '',
			zip: '',
			loading: false,
			updateCard: true,
		};

		this.submit = this.submit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleUpdateCard = this.handleUpdateCard.bind(this);
	}

	componentWillMount() {
		const { shippingAddress } = this.props.details;
		if (shippingAddress && shippingAddress.name) {
			console.log('mount', shippingAddress);
			this.setState(shippingAddress);
		}
	}

	componentWillReceiveProps(nextProps) {
		const { details } = nextProps;

		if (
			!this.props.details.customerId &&
			details.customerId &&
			details.cardInfo
		) {
			this.setState({
				updateCard: false,
			});
		}

		const { shippingAddress } = nextProps.details;
		if (shippingAddress && shippingAddress.name) {
			console.log('receive', shippingAddress);
			this.setState(shippingAddress);
		}
	}

	handleUpdateCard() {
		console.log('update card');
		this.setState({
			updateCard: true,
		});
	}

	submit() {
		//are all the address fields filled out
		const {
			updateCard,
			name,
			business,
			address1,
			address2,
			city,
			state,
			zip,
			country,
		} = this.state;
		if (!name || !address1 || !city || !state || !zip || !country) {
			this.setState({
				displayErrors: true,
			});
			return;
		}
		if (updateCard) {
			this.props.stripe.createToken({ name }).then(({ token, error }) => {
				if (error) {
					return;
				}
				Actions.payInvoice(
					this.props.invoice.id,
					this.props.details._id,
					this.props.user.email,
					{ name, business, address1, address2, city, state, zip, country },
					token
				);
			});
		} else {
			Actions.payInvoice(
				this.props.invoice.id,
				this.props.details._id,
				this.props.user.email,
				{ name, business, address1, address2, city, state, zip, country }
			);
		}
	}

	handleChange(val, name) {
		console.log(val, name);
	}

	render() {
		console.log('props', this.props);
		const { invoice } = this.props;
		const { displayErrors } = this.state;

		let invoiceDollars = invoice.amount / 100;
		invoiceDollars = invoiceDollars.toLocaleString('en-US', {
			style: 'currency',
			currency: 'USD',
		});

		let alerts = [];

		if (invoice.error) {
			alerts.push(<Alert key="danger" type="danger" message={invoice.error} />);
		}

		if (invoice.saveSuccess && this.props.details._id) {
			return (
				<Redirect
					to={{
						pathname: `/manage/${this.props.details._id}?invoicePaid=true`,
					}}
				/>
			);
		}

		return (
			<section className="container">
				{alerts}
				<h1 className="page-header">
					Farms /{' '}
					<Link to={`/manage/${this.props.details._id}`}>
						{this.props.details.name}
					</Link>{' '}
					/ Tag Printing Setup
				</h1>
				<div className="row">
					<div className="col-sm-12">
						<h2>
							Setup your tag printing subscription: {invoiceDollars} (includes
							shipping)
						</h2>
						<div className="panel panel-default">
							<div className="panel-heading">
								<h4>Card Info</h4>
							</div>
							<div className="panel-body">
								<StripeForm
									updateCard={this.state.updateCard}
									handleUpdateCard={this.handleUpdateCard}
									farm={this.props.details}
								/>
							</div>
						</div>

						<div className="panel panel-default">
							<div className="panel-heading">
								<h4>Shipping address</h4>
							</div>
							<div className="panel-body">
								<TextControl
									name="name"
									label="Name*"
									value={this.state.name}
									onChange={LinkState.bind(this)}
									disabled={invoice.loading}
								/>
								{displayErrors && !this.state.name && (
									<p className="text-danger">Name is required.</p>
								)}
								<TextControl
									name="business"
									label="Buisness"
									value={this.state.business}
									onChange={LinkState.bind(this)}
									disabled={invoice.loading}
								/>
								<TextControl
									name="address1"
									label="Address 1*"
									value={this.state.address1}
									onChange={LinkState.bind(this)}
									disabled={invoice.loading}
								/>
								{displayErrors && !this.state.address1 && (
									<p className="text-danger">Address 1 is required.</p>
								)}
								<TextControl
									name="address2"
									label="Address 2"
									value={this.state.address2}
									onChange={LinkState.bind(this)}
									disabled={invoice.loading}
								/>

								<TextControl
									name="city"
									label="City*"
									value={this.state.city}
									onChange={LinkState.bind(this)}
									disabled={invoice.loading}
								/>
								{displayErrors && !this.state.city && (
									<p className="text-danger">City is required.</p>
								)}
								<SelectControl
									name="state"
									label="State*"
									value={this.state.state}
									onChange={LinkState.bind(this)}
									disabled={invoice.loading}
								>
									<StateOptions country={this.state.country} />
								</SelectControl>
								{displayErrors && !this.state.state && (
									<p className="text-danger">State is required.</p>
								)}
								<TextControl
									name="zip"
									label="Zip Code *"
									value={this.state.zip}
									onChange={LinkState.bind(this)}
									disabled={invoice.loading}
								/>
								{displayErrors && !this.state.zip && (
									<p className="text-danger">Zip Code is required.</p>
								)}

								<SelectControl
									name="country"
									label="Country"
									value={this.state.country}
									onChange={LinkState.bind(this)}
									disabled={this.state.loading}
								>
									<option value="">- Select One - </option>
									<option value="Canada">Canada</option>
									<option value="Mexico">Mexico</option>
									<option value="USA">USA</option>
									<option value="Australia">Australia</option>
								</SelectControl>

								{displayErrors && !this.state.country && (
									<p className="text-danger">Country is required.</p>
								)}
							</div>
						</div>

						<div className="panel-body">
							<Button
								onClick={this.submit}
								inputClasses={{
									'btn-primary': true,
									'btn-lg': true,
								}}
								disabled={invoice.loading}
							>
								Submit
								<Spinner space="left" show={invoice.loading} />
							</Button>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default injectStripe(InvoiceForm);
