import React, { useState } from "react";
import ClassNames from "classnames";
import CONSTANTS from "../utils/constants";
import nextChevron from "../../../media/nextChevron.svg";
import dropdownArrowWhite from "../../../media/dropdownArrowWhite.svg";
import dropdownArrow from "../../../media/dropdownArrow.svg";
import backArrow from "../../../media/backArrow.svg";
import check from "../../../media/check.svg";
const { fieldTranslations } = CONSTANTS;

export default ({
    placeholder,
    options,
    label,
    theme,
    values,
    setValues,
    disabled,
}) => {
    const dropdownTheme = theme || "light";
    const [opened, setOpened] = useState(false);
    const [activeField, setActiveField] = useState(null);
    const [position, setPosition] = useState(false);

    const handleSelectOption = (option) => {
        const newOptions = JSON.parse(JSON.stringify(values));

        if (!newOptions[activeField]) {
            newOptions[activeField] = [option];
        } else {
            const index = newOptions[activeField].findIndex(
                (o) => o === option
            );
            if (index !== -1) {
                newOptions[activeField].splice(index, 1);
            } else {
                newOptions[activeField].push(option);
            }
        }
        setValues(newOptions);
    };

    const isSelected = (option) => {
        return values[activeField] && values[activeField].includes(option);
    };

    const handleClear = () => {
        setValues({});
        toggleOpen();
    };

    const toggleOpen = () => {
        if (!disabled) {
            setOpened(!opened);
        }
    };

    const categoryOptions = Object.keys(options).map((key, index) => {
        return (
            <button
                key={`${key}-${index}`}
                onClick={() => {
                    setActiveField(key);
                }}
                className="popoverRow"
            >
                <div className="popoverRow__inner">
                    <span>
                        {fieldTranslations.find((field) => field.key === key)
                            ? fieldTranslations.find(
                                  (field) => field.key === key
                              ).value
                            : key}
                    </span>

                    <span>
                        {values[key] && values[key].length ? (
                            values[key].length === 1 ? (
                                <span className="selectedOption">
                                    {values[key]}
                                </span>
                            ) : (
                                <span className="selectedOption">
                                    {`${values[key].length} Selected`}
                                </span>
                            )
                        ) : null}

                        <img
                            className="backArrow"
                            src={nextChevron}
                            alt="Next"
                        />
                    </span>
                </div>
            </button>
        );
    });

    const fieldOptions =
        activeField &&
        options[activeField].map((option, index) => (
            <button
                key={`${option}-${index}`}
                onClick={() => {
                    handleSelectOption(option);
                }}
                className="popoverRow"
            >
                <div className="popoverRow__inner">
                    <span>{option}</span>
                    {(isSelected(option) && (
                        <img className="backArrow" src={check} alt="checked" />
                    )) ||
                        null}
                </div>
            </button>
        ));

    const selected = Object.keys(values).length
        ? Object.keys(values).reduce((all, key) => {
              if (values[key]) {
                  return all.concat(values[key]);
              }

              return all;
          }, [])
        : [];

    const activeValue = selected.length
        ? selected.length === 1
            ? selected[0]
            : `${selected.length} Selected`
        : placeholder;

    const dropdownClasses = ClassNames({
        reportDropdownActive__Dark: dropdownTheme === "dark",
        reportDropdownActive: dropdownTheme !== "dark",
        disabled: disabled,
    });

    const bodyRect = document.body.getBoundingClientRect();

    let popoverContainerClasses = "null";
    if (position) {
        const classes = {
            "drop-up": !!(position.top > bodyRect.height / 2),
        };
        classes[`dropdownPopover__${dropdownTheme}`] = true;

        popoverContainerClasses = ClassNames(classes);
    }

    return (
        <div
            className={`dropdownContainer__${dropdownTheme}`}
            ref={(el) => {
                if (!el) return;

                if (!position) {
                    setPosition(el.getBoundingClientRect());
                }
            }}
        >
            <div className={`${opened ? "opened" : ""}  reportDropdown`}>
                <label className="label">{label}</label>
                <button onClick={toggleOpen} className={dropdownClasses}>
                    <span className="activeValue">{activeValue}</span>
                    {dropdownTheme === "dark" ? (
                        <img
                            className="dropdownArrow"
                            src={dropdownArrowWhite}
                            alt="dropdown arrow"
                        />
                    ) : (
                        <img src={dropdownArrow} alt="dropdown arrow" />
                    )}
                </button>
                {opened && (
                    <div className={popoverContainerClasses}>
                        <div className="triangle" />
                        <div className="popoverContainer">
                            <div className="popoverHeader">
                                <div className="headerItem">
                                    {activeField && (
                                        <button
                                            className="popoverHeaderButton"
                                            onClick={() => {
                                                setActiveField(null);
                                            }}
                                        >
                                            <img
                                                className="backArrow"
                                                src={backArrow}
                                                alt="back"
                                            />
                                            Back
                                        </button>
                                    )}
                                </div>
                                <div className="headerItem">
                                    <h4 className="popoverHeaderlabel">
                                        View By
                                    </h4>
                                </div>
                                <div className="headerItem">
                                    {(selected.length && (
                                        <button
                                            onClick={handleClear}
                                            className="popoverHeaderButton"
                                        >
                                            Clear
                                        </button>
                                    )) ||
                                        null}
                                </div>
                            </div>

                            <div className="popoverRows">
                                {(activeField && fieldOptions) ||
                                    categoryOptions}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {opened && <div className="overlay" onClick={toggleOpen} />}
        </div>
    );
};
