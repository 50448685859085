import React, { useState } from "react";
import moment from "moment";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import isAfterDay from "react-dates/lib/utils/isAfterDay";
import Dropdown from "./components/Dropdown";
import CONSTANTS from "./utils/constants";

const { dateRangeOptions } = CONSTANTS;

export default ({
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    dateRange,
    setDateRange,
    handleExport,
}) => {
    const [focusedInput, setFocusedInput] = useState("startDate");

    const customDates = (
        <div className="customDates">
            <DateRangePicker
                startDate={startDate} // momentPropTypes.momentObj or null,
                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                endDate={endDate} // momentPropTypes.momentObj or null,
                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                }} // PropTypes.func.isRequired,
                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={(focusedInput) => {
                    if (!focusedInput) return; // doesn't update the focusedInput if it is trying to close the DRP
                    setFocusedInput(focusedInput);
                    document.activeElement.blur();
                }} // PropTypes.func.isRequired,
                // withFullScreenPortal={true}
                noBorder={true}
                isOutsideRange={(day) => isAfterDay(day, moment())}
            />
        </div>
    );

    return (
        <div class="exportContainer">
            <div class="exportBox">
                <h3>Snapshot</h3>
                <p>Everything that is on your farm right now.</p>
                <button
                    onClick={() => {
                        handleExport("Snapshot");
                    }}
                    className="exportBtn"
                >
                    Export
                </button>
            </div>
            <div class="exportBox">
                <h3>Harvest Log</h3>
                <p>Everything you harvested with all of its meta data.</p>
                <div className="exportDateContainer">
                    <Dropdown
                        type="radio"
                        placeholder="Date range"
                        label="Date range"
                        options={dateRangeOptions}
                        setValue={setDateRange}
                        value={dateRange}
                        defaultValue={dateRangeOptions[2]}
                        customSelector={customDates}
                        customValue={"Custom"}
                        customSelectedValue={
                            startDate || endDate
                                ? `${
                                      (startDate &&
                                          startDate.format("M/D/YY")) ||
                                      "m/d/y"
                                  } - ${
                                      (endDate && endDate.format("M/D/YY")) ||
                                      "m/d/y"
                                  }`
                                : "Custom"
                        }
                    />
                </div>
                <button
                    onClick={() => {
                        handleExport("HarvestLog");
                    }}
                    className="exportBtn"
                >
                    Export
                </button>
            </div>
        </div>
    );
};
