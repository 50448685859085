import Button from "../../../../components/form/button.jsx";
import Alert from "../../../../components/alert.jsx";
import axios from "axios";
import PropTypes from "prop-types";
import React from "react";

const propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

class UploadPage extends React.Component {
  constructor(props) {
    super(props);

    this.submitUpload = this.submitUpload.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.clearErrorMessage = this.clearErrorMessage.bind(this);
    this.state = {
      file: null,
      messageType: "",
      errorMessage: "",
    };
  }

  submitUpload(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.state.file !== null) {
      console.log("have a file", this.state.file);
      const path = window.location.pathname.split("/");
      const farmId = path[path.length - 1];
      const data = new FormData();
      data.append("file", this.state.file);
      data.append("filename", "file.xlsx");

      const instance = axios.create({
        timeout: 1000,
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
      });

      instance
        .post(`/api/farms/upload/${farmId}`, data)
        .then((res) => {
          const { message } = res.data;

          this.setState({
            errorMessage: message,
            messageType: "success",
          });
        })
        .catch((err) => {
          console.log(err.response);

          const errorMessage = err.response.data.message;
          const messageType = "danger";
          this.setState({
            errorMessage,
            messageType,
          });
        });
    } else {
      this.setState({
        errorMessage: "Please upload a file first",
        messageType: "danger",
      });
    }
  }

  handleChange(e) {
    const file = e.target.files[0];

    this.setState({
      file,
    });
  }

  clearErrorMessage() {
    this.setState({
      errorMessage: "",
      messageType: "",
    });
  }

  render() {
    const { errorMessage, messageType } = this.state;
    return (
      <div>
        <section className="container">
          <h1 className="page-header">Upload Groups</h1>
        </section>
        <section className="container">
          <form onSubmit={this.submitUpload}>
            <input type="file" name="foo" onChange={this.handleChange} />
            <Button type="submit" inputClasses={{ "btn-primary": true }}>
              Upload
            </Button>
          </form>

          {errorMessage && (
            <Alert
              onClose={this.clearErrorMessage}
              key={messageType}
              type={messageType}
              message={errorMessage}
            />
          )}
        </section>
      </div>
    );
  }
}

UploadPage.propTypes = propTypes;

export default UploadPage;
