import React, { useState, useEffect } from "react";
import JsonFetch from "../../../../helpers/json-fetch";
import "./currentInvoice.scss";

export default (props) => {
    const [invoiceItems, setInvoiceItems] = useState([]);

    useEffect(() => {
        const getNewPrice = async () => {
            const request = {
                method: "POST",
                url: `/api/enableTagging/${props.farmId}/invoiceAmount`,
                data: {
                    promotionalFee: props.promotionalFee,
                    printers: props.printers,
                    taggingActive: props.taggingActive,
                },
            };

            JsonFetch(request, (err, response) => {
                if (err) {
                    console.log("get invoice amount err", err);

                    return;
                }

                setInvoiceItems(response);
            });
        };

        if (props.promotionalFee || props.printers.length) {
            getNewPrice();
        }
        {
            setInvoiceItems([]);
        }
    }, [props.promotionalFee, props.printers]);

    const subscription =
        (props.newSubscription &&
            props.newSubscription !== props.currentSubscription &&
            props.plans.find((plan) => plan.id === props.newSubscription)) ||
        null;

    return (
        <>
            {((invoiceItems.length || subscription) && (
                <div className="currentInvoiceContainer">
                    {subscription && (
                        <>
                            <h3>Subscription:</h3>
                            <ul>
                                <li>{subscription.nickname}</li>
                            </ul>
                        </>
                    )}
                    {(invoiceItems.length && (
                        <>
                            <h3>Printer & Paper Invoice:</h3>
                            <ul>
                                {invoiceItems.map((item) => (
                                    <li>
                                        <span className="invoiceItem__name">
                                            {item.description}
                                        </span>
                                        <span clasName="invoiceItem__container">
                                            {(item.cost / 100).toLocaleString(
                                                "en-US",
                                                {
                                                    style: "currency",
                                                    currency: "USD",
                                                }
                                            )}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                            <div className="invoiceTotal">
                                <span className="invoiceItem__name">TOTAL</span>
                                <span clasName="invoiceItem__container">
                                    {(
                                        invoiceItems.reduce(
                                            (total, { cost }) => total + cost,
                                            0
                                        ) / 100
                                    ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                    })}
                                </span>
                            </div>
                        </>
                    )) ||
                        null}
                </div>
            )) ||
                null}
        </>
    );
};
