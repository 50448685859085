import Actions from "./actions";
import Alert from "../../../../components/alert.jsx";
import Button from "../../../../components/form/button.jsx";
import ControlGroup from "../../../../components/form/control-group.jsx";
import LinkState from "../../../../helpers/link-state.js";
import Modal from "../../../../components/modal.jsx";
import PropTypes from "prop-types";
import React from "react";
import Spinner from "../../../../components/form/spinner.jsx";
import TextControl from "../../../../components/form/text-control.jsx";

const propTypes = {
  error: PropTypes.string,
  hasError: PropTypes.object,
  help: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.bool,
  name: PropTypes.string,
  show: PropTypes.bool,
};

class CreateNewForm extends React.Component {
  constructor(props) {
    super(props);

    this.els = {};
    this.state = {
      showing: false,
      name: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      name: nextProps.name,
    });
  }

  componentDidUpdate() {
    if (this.props.show && this.state.name.length === 0) {
      this.els.name.focus();
    }
  }

  onSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    Actions.createNew(
      {
        name: this.state.name,
      },
      this.props.history
    );
  }

  render() {
    let alert;

    if (this.props.error) {
      alert = <Alert type="danger" message={this.props.error} />;
    }

    const formElements = (
      <fieldset>
        {alert}
        <TextControl
          name="name"
          label="Name"
          ref={(c) => (this.els.name = c)}
          value={this.state.name}
          onChange={LinkState.bind(this)}
          hasError={this.props.hasError.name}
          help={this.props.help.name}
          disabled={this.props.loading}
        />
        <ControlGroup hideLabel={true} hideHelp={true}>
          <Button
            type="submit"
            inputClasses={{ "btn-primary": true }}
            disabled={this.props.loading}
          >
            Create new
            <Spinner space="left" show={this.props.loading} />
          </Button>
        </ControlGroup>
      </fieldset>
    );

    return (
      <Modal
        header="Create new"
        show={this.props.show}
        onClose={Actions.hideCreateNew}
      >
        <form onSubmit={this.onSubmit.bind(this)}>{formElements}</form>
      </Modal>
    );
  }
}

CreateNewForm.propTypes = propTypes;

export default CreateNewForm;
