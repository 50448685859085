import Constants from "../constants";
import ObjectAssign from "object-assign";

const initialState = {
    loading: false,
    showSaveSuccess: false,
    error: undefined,
    hasError: {},
    help: {},
    trayId: "",
    bags: [],
    name: "",
    location: "",
};
const reducer = function (state = initialState, action) {
    if (action.type === Constants.GET_DETAILS_RESPONSE) {
        const stateUpdates = ObjectAssign({}, initialState);

        if (action.response.hasOwnProperty("lines")) {
            stateUpdates.leases = action.response.lines.reverse();
        }

        return ObjectAssign({}, stateUpdates);
    }

    // if (action.type === Constants.NEW_TRAY) {
    //     console.log('new tray state', state);
    //     // return ObjectAssign({}, state, {
    //     //     loading: true,
    //     //     newLine: action.request.data.newLine
    //     // });
    // }
    // //
    //  if (action.type === Constants.NEW_TRAY_RESPONSE) {
    //
    //      console.log('tray respone action', action.response);
    //      console.log('tray response state', state);
    // //     const validation = ParseValidation(action.response);
    // //     const stateUpdates = {
    // //         loading: false,
    // //         showSaveSuccess: !action.err,
    // //         error: validation.error,
    // //         hasError: validation.hasError,
    // //         help: validation.help
    // //     };
    // //
    // //     if (action.response.hasOwnProperty('lines')) {
    // //         stateUpdates.newLine = '';
    // //         stateUpdates.lines = action.response.lines.reverse();
    // //     }
    // //
    // //     return ObjectAssign({}, state, stateUpdates);
    // }
    //
    // if (action.type === Constants.HIDE_LINE_SAVE_SUCCESS) {
    //     return ObjectAssign({}, state, {
    //         showSaveSuccess: false
    //     });
    // }

    // if (action.type === Constants.DELETE_LEASE) {
    //     return ObjectAssign({}, state, {
    //         loading: true
    //     });
    // }
    //
    // if (action.type === Constants.DELETE_LEASE_RESPONSE) {
    //
    //     const validation = ParseValidation(action.response);
    //
    //     return ObjectAssign({}, state, {
    //         loading: false,
    //         error: validation.error,
    //         leases: action.response.leases
    //     });
    // }

    return state;
};

export default reducer;
