import request from "superagent";
import getQueryStringParams from "./getQueryStringParams";

export default (url, farmId, initialParams = {}) => {
    const queryParams = Object.assign(
        {},
        getQueryStringParams(window.location.search),
        initialParams
    );

    const search = Object.keys(queryParams).reduce((all, key) => {
        if (key !== "farmId") {
            return `${all}${all ? "&" : "?"}${key}=${queryParams[key]}`;
        }

        return all;
    }, "");

    return request.get(`${url}/${farmId}${search}`).then(r => r.body);
};
