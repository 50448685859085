import React, { useState, useEffect } from "react";
import request from "superagent";
import DashboardItem from "./components/DashboardItem";
import DashboardEdit from "./components/DashboardEdit";

export default ({
    farmId,
    activeLease,
    activeLeaseId,
    edit,
    viewByOptions,
    toggleEditDashboard,
}) => {
    const [dashboardMetrics, setDashboardMetrics] = useState([]);

    useEffect(() => {
        const getDashboardData = async () => {
            const response = await request
                .put(`/api/v2/getDashboard/${farmId}`)
                .send({
                    activeLease: activeLeaseId,
                });

            setDashboardMetrics(response.body);
        };

        if (farmId && edit === false) {
            getDashboardData();
        }
    }, [farmId, activeLeaseId, edit]);

    if (edit && dashboardMetrics.length) {
        return (
            <DashboardEdit
                toggleEditDashboard={toggleEditDashboard}
                setDashboardMetrics={setDashboardMetrics}
                viewByOptions={viewByOptions}
                dashboardSquares={dashboardMetrics}
                farmId={farmId}
            />
        );
    }

    let dashboardTopRow = [];
    let dashboardBottomRow = [];

    if (dashboardMetrics.length) {
        const topRowCount = Math.ceil(dashboardMetrics.length / 2);
        dashboardTopRow = dashboardMetrics.slice(0, topRowCount);
        dashboardBottomRow = dashboardMetrics.slice(
            topRowCount,
            dashboardMetrics.length
        );
    }

    return (
        <div className="dashboard-container">
            <div className="dashboard-row">
                {dashboardTopRow.map((item) => (
                    <DashboardItem
                        farmId={farmId}
                        activeLeaseId={activeLeaseId}
                        {...item}
                    />
                ))}
            </div>
            <div className="dashboard-row">
                {dashboardBottomRow.map((item) => (
                    <DashboardItem
                        {...item}
                        farmId={farmId}
                        activeLeaseId={activeLeaseId}
                    />
                ))}
            </div>
        </div>
    );
};
