import Delete from "./reducers/delete";
import Details from "./reducers/details";
import Permissions from "./reducers/permissions";
import { createStore, combineReducers } from "redux";

export default createStore(
    combineReducers({
        delete: Delete,
        details: Details,
        permissions: Permissions,
    })
);
