import React from "react";
import LoginApp from "./pages/main/app.jsx";
import Manage from "./pages/manage/app.jsx";
import Farms from "./pages/farms/app.jsx";
import Account from "./pages/account/app.jsx";
import Admin from "./pages/admin/app.jsx";
import Reports from "./pages/reports/app.jsx";
import Tag from "./pages/tag/app.jsx";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./core/bootstrap.scss";

function App() {
    return (
        <Router>
            <Switch>
                <Route exact={false} path="/manage">
                    <Manage />
                </Route>
                <Route exact={false} path="/farms">
                    <Farms />
                </Route>
                <Route exact={false} path="/account">
                    <Account />
                </Route>
                <Route exact={false} path="/admin">
                    <Admin />
                </Route>
                <Route exact={false} path="/reports">
                    <Reports />
                </Route>
                <Route exact={false} path="/tag">
                    <Tag />
                </Route>
                <Route>
                    <LoginApp />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
