import React, { Component } from "react";
import AWS from "aws-sdk";
import TextControl from "../../../../components/form/text-control.jsx";
import TextAreaControl from "../../../../components/form/textarea-control.jsx";
import Spinner from "../../../../components/form/spinner.jsx";
import Button from "../../../../components/form/button.jsx";
import LinkState from "../../../../helpers/link-state";
import Actions from "./actions";
import Alert from "../../../../components/alert.jsx";
import shortUuid from "short-uuid";
import "../../../manage/details/farmDetails.scss";
AWS.config.update({
    accessKeyId: "AKIAJ5EMQZQYKPZHRJZQ",
    secretAccessKey: "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",
    region: "us-east-1",
});

export default class FarmDetails extends Component {
    state = {
        growerNames: "",
        photo: null,
        distributorBrands: [],
        saving: false,
    };

    componentWillMount() {
        const { distributorBrands, photo, growerNames } = this.props;

        this.setState({
            distributorBrands,
            growerNames,
            photo,
        });
    }

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.photo !== this.props.photo ||
            nextProps.growerNames !== this.props.growerNames ||
            nextProps.distributorBrands !== this.props.distributorBrands
        ) {
            this.setState({
                photo: nextProps.photo,
                growerNames: nextProps.growerNames,
                distributorBrands: nextProps.distributorBrands,
            });
        }
    }

    uploadImage = (file) => {
        return new Promise((resolve, reject) => {
            const s3 = new AWS.S3({ apiVersion: "2006-03-01" });
            const fileName = shortUuid.generate();
            var uploadParams = {
                Bucket: "oystertracker",
                Key: "",
                Body: "",
                ACL: "public-read",
            };

            uploadParams.Body = file;

            if (file.size > 1000000) {
                return reject(
                    new Error("Please upload a jpg, or png smaller than 1MB")
                );
            }

            uploadParams.Key = `${fileName}-${file.name}`;
            s3.upload(uploadParams, (err, data) => {
                if (err) {
                    console.log("UPLOAD ERROR", err);
                    return reject(err);
                }
                return resolve(data.Location);
            });
        });
    };

    submit = async () => {
        this.setState({ saving: true });

        let photo = this.state.photo;
        if (
            document.getElementById("input-photo") &&
            document.getElementById("input-photo").files &&
            document.getElementById("input-photo").files[0]
        ) {
            try {
                photo = await this.uploadImage(
                    document.getElementById("input-photo").files[0]
                );
            } catch (err) {
                alert(err.message);
                this.setState({ saving: false });
                return;
            }
        }

        const data = JSON.parse(JSON.stringify(this.state));
        delete data.saving;

        this.setState({ saving: false });

        Actions.updateFarmDetails(this.props._id, {
            ...data,
            photo,
        });
    };

    removePhoto = () => {
        this.setState({
            photo: null,
        });
    };

    addBrand = () => {
        const brandUpdate = JSON.parse(
            JSON.stringify(this.state.distributorBrands)
        );

        brandUpdate.push({
            brand: "",
            growingProcess: "",
            tastingNotes: "",
        });

        this.setState({ distributorBrands: brandUpdate });
    };

    render() {
        const alerts = [];

        if (this.props.showDetailsSaveSuccess) {
            alerts.push(
                <Alert
                    key="success"
                    type="success"
                    onClose={Actions.hideDetailsSaveSuccess}
                    message="Your changes have been saved."
                />
            );
        }

        return (
            <div className="farmDetails--form">
                {alerts}

                <br />
                <div className="panel panel-default">
                    <div className="panel-heading">Farm Info</div>
                    <div className="panel-body">
                        <TextControl
                            name="growerNames"
                            label="Grower Names*"
                            value={this.state.growerNames}
                            onChange={LinkState.bind(this)}
                        />
                        {this.state.photo ? (
                            <div className="farm-photo-input">
                                <label className="control-label">
                                    Farm Photo
                                </label>

                                <img src={this.state.photo} />
                                <button
                                    onClick={this.removePhoto}
                                    className="btn"
                                >
                                    Remove
                                </button>
                                <TextControl name="photo" type="hidden" />
                            </div>
                        ) : (
                            <TextControl
                                name="photo"
                                type="image"
                                label="Farm Photo"
                            />
                        )}
                    </div>
                </div>

                <div className="panel panel-default">
                    <div className="panel-heading">Brands</div>
                    <div className="panel-body">
                        {this.state.distributorBrands &&
                            this.state.distributorBrands.map(
                                (
                                    { brand, growingProcess, tastingNotes },
                                    i
                                ) => {
                                    const updateBrand = (key, value) => {
                                        const brandUpdate = JSON.parse(
                                            JSON.stringify(
                                                this.state.distributorBrands
                                            )
                                        );

                                        brandUpdate[i][key] = value;

                                        this.setState({
                                            distributorBrands: brandUpdate,
                                        });
                                    };

                                    const removeBrand = () => {
                                        if (
                                            window.confirm(
                                                "Deleting this brand will remove it from your tagging app. Are you sure?"
                                            )
                                        ) {
                                            const brandUpdate = JSON.parse(
                                                JSON.stringify(
                                                    this.state.distributorBrands
                                                )
                                            );

                                            brandUpdate.splice(i, 1);
                                            this.setState({
                                                distributorBrands: brandUpdate,
                                            });
                                        }
                                    };

                                    return (
                                        <>
                                            <button
                                                onClick={removeBrand}
                                                className="removeBtn"
                                            >
                                                X
                                            </button>
                                            <TextControl
                                                value={brand}
                                                name="brand"
                                                label="Brand Name"
                                                onChange={(e) => {
                                                    updateBrand(
                                                        "brand",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <TextAreaControl
                                                value={growingProcess}
                                                name="growingProcess"
                                                label="Growing Process"
                                                onChange={(e) => {
                                                    updateBrand(
                                                        "growingProcess",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <TextAreaControl
                                                value={tastingNotes}
                                                name="tastingNotes"
                                                label="Tasting Notes"
                                                onChange={(e) => {
                                                    updateBrand(
                                                        "tastingNotes",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <hr />
                                        </>
                                    );
                                }
                            )}
                        <Button onClick={this.addBrand}>Add Brand</Button>
                    </div>
                </div>

                <Button
                    type="submit"
                    onClick={this.submit}
                    inputClasses={{ "btn-primary": true }}
                    disabled={this.state.saving}
                >
                    Submit <Spinner show={this.state.saving} space="left" />
                </Button>
            </div>
        );
    }
}
