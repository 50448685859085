import Constants from "../constants";
import ObjectAssign from "object-assign";
import ParseValidation from "../../../../helpers/parse-validation";

const initialState = {
    hydrated: false,
    loading: false,
    showFetchFailure: false,
    showSaveSuccess: false,
    showNewLine: false,
    error: undefined,
    plans: [],
    plan: null,
    setupFee: 0,
    cardInfo: {},
    customerId: null,
    subscriptionId: null,
    taggingActive: false,
    hasError: {},
    help: {},
    _id: undefined,
    name: undefined,
    owner: undefined,
    leases: [],
    address1: "",
    address2: "",
    city: "",
    country: "",
    email: "",
    billingEmail: "",
    state: "",
    zip: "",
    activeTab: 0,
    tabs: [],
    editMode: false,
};
const reducer = function (state = initialState, action) {
    if (action.type === Constants.GET_DETAILS) {
        return ObjectAssign({}, state, {
            hydrated: false,
            loading: true,
        });
    }

    if (action.type === Constants.GET_DETAILS_RESPONSE) {
        const validation = ParseValidation(action.response);

        const leases =
            (action.response.leases && action.response.leases.reverse()) || [];
        const stateUpdates = {
            _id: action.response._id,
            hydrated: true,
            loading: false,
            showFetchFailure: !!action.err,
            error: validation.error,
            address1: action.response.address1,
            address2: action.response.address2,
            city: action.response.city,
            country: action.response.country,
            email: action.response.email,
            billingEmail: action.response.billingEmail,
            name: action.response.name,
            owner: action.response.owner,
            leases,
            plan: action.response.plan,
            setupFee: action.response.setupFee,
            state: action.response.state,
            zip: action.response.zip,
            subscriptionId: action.response.subscriptionId,
            customerId: action.response.customerId,
            taggingActive: action.response.taggingActive,
            cardInfo: action.response.cardInfo,
            shippingAddress: action.response.shippingAddress,
        };

        return ObjectAssign({}, state, stateUpdates);
    }

    return state;
};

export default reducer;
