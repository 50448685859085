import FilterFormHoc from '../../../../pages/admin/components/filter-form-hoc.jsx';
import PropTypes from 'prop-types';
import React from 'react';
import SelectControl from '../../../../components/form/select-control.jsx';
import TextControl from '../../../../components/form/text-control.jsx';

const propTypes = {
	linkInputState: PropTypes.func,
	linkSelectState: PropTypes.func,
	loading: PropTypes.bool,
	state: PropTypes.object,
};
const defaultValues = {
	name: '',
	sort: '_id',
	limit: '20',
	page: '1',
};

class FilterForm extends React.Component {
	render() {
		return (
			<div className="row">
				<div className="col-sm-3">
					<TextControl
						name="name"
						label="Name search"
						value={this.props.state.name}
						onChange={this.props.linkInputState}
						disabled={this.props.loading}
					/>
					<button
						className="btn btn-primary visible-xs-block"
						onClick={this.props.linkInputState}
					>
						Search
					</button>
				</div>
				<div className="col-sm-3">
					<SelectControl
						name="sort"
						label="Sort by"
						value={this.props.state.sort}
						onChange={this.props.linkSelectState}
						disabled={this.props.loading}
					>
						<option value="_id">id &#9650;</option>
						<option value="-_id">id &#9660;</option>
						<option value="name">name &#9650;</option>
						<option value="-name">name &#9660;</option>
					</SelectControl>
				</div>
				<div className="col-sm-3">
					<SelectControl
						name="limit"
						label="Limit"
						value={this.props.state.limit}
						onChange={this.props.linkSelectState}
						disabled={this.props.loading}
					>
						<option value="5">5 items</option>
						<option value="10">10 items</option>
						<option value="20">20 items</option>
						<option value="50">50 items</option>
						<option value="100">100 items</option>
					</SelectControl>
				</div>
			</div>
		);
	}
}

FilterForm.propTypes = propTypes;

export default FilterFormHoc(FilterForm, defaultValues);
