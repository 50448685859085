import React from "react";

function dateToYMD(date) {
    return (
        (date.getMonth() + 1).toString().padStart(2, 0) +
        "/" +
        date.getDate() +
        "/" +
        date.getYear()
    );
}

export default function CustomPaperHistory({
    customPaperRolls,
    paperCosts,
    type,
}) {
    console.log("type", type);
    return (
        <>
            <table className="billingTable">
                <tr>
                    <th>Shipment Date</th>
                    <th>Roll Count</th>
                    <th>Tag Count</th>
                </tr>
                {(customPaperRolls &&
                    customPaperRolls.map((rolls) => (
                        <tr>
                            <td>{dateToYMD(new Date(rolls.date))}</td>
                            <td>{rolls.count}</td>
                            <td>
                                {rolls.count * paperCosts[type].tagCountPerRoll}
                            </td>
                        </tr>
                    ))) ||
                    null}
            </table>
        </>
    );
}
