import React from "react";
import JsonFetch from "../helpers/json-fetch";

class CheckPermissions extends React.Component {
    componentDidMount() {
        JsonFetch(
            { method: "GET", url: "/api/users/my", query: null },
            (err, response) => {
                if (err) {
                    console.log("check permissions err", err);
                }

                const path = window.location.pathname;

                if (
                    (path.startsWith("/farm") || path.startsWith("/admin")) &&
                    !response.groups.admin &&
                    !response.groups.root
                ) {
                    if (response.groups.farmmanager) {
                        return (window.location.href = "/manage");
                    } else {
                        return (window.location.href = "/account/settings");
                    }
                }

                if (
                    path.startsWith("/manage") &&
                    !response.groups.farmmanager
                ) {
                    if (response.groups.root || response.groups.admin) {
                        return (window.location.href = "/farms");
                    } else {
                        return (window.location.href = "/account/settings");
                    }
                }
            }
        );
    }

    render() {
        return null;
    }
}

export default CheckPermissions;
