import React, { useState } from "react";
import request from "superagent";
import MultiPageDropdown from "./MultiPageDropdown";
import Dropdown from "./Dropdown";
import CONSTANTS from "../utils/constants";

const metrics = CONSTANTS.dashboardMetrics;
const dateRangeOptions = CONSTANTS.dateRangeOptions.filter(
    (range) => range !== "Custom"
);

export default ({
    dashboardSquares,
    viewByOptions,
    setDashboardMetrics,
    toggleEditDashboard,
    farmId,
}) => {
    const [reports, setReports] = useState(dashboardSquares);

    const addReport = () => {
        const newReports = JSON.parse(JSON.stringify(reports));
        newReports.push({
            activeViewBy: {},
            metric: dashboardMetrics[0],
            dateRange: dateRangeOptions[2],
        });
        setReports(newReports);
    };
    const removeReport = (index) => {
        const newReports = JSON.parse(JSON.stringify(reports));
        newReports.splice(index, 1);
        setReports(newReports);
    };
    const setReportValue = (key, value, index) => {
        const newReports = JSON.parse(JSON.stringify(reports));

        if (key === "metric") {
            const newMetric = metrics.find((m) => m.label === value);
            newReports[index][key] = (newMetric && newMetric.value) || value;
        } else {
            newReports[index][key] = value;
        }
        if (
            key === "metric" &&
            key !== "Inventory" &&
            key !== "AverageSize" &&
            newReports[index].dateRange === "Snapshot"
        ) {
            newReports[index].dateRange = dateRangeOptions[2];
        }
        setReports(newReports);
    };

    const handleSave = async () => {
        await request.put(`/api/v2/updateDashboard/${farmId}`).send({
            dashboardReports: reports,
        });

        setDashboardMetrics(reports);
        toggleEditDashboard(false);
    };

    const dashboardMetrics = metrics.map((m) => m.label);
    dashboardMetrics.push("Equipment");

    return (
        <div className="reportContainer dashboardEdit__container">
            <div className="reportHeader">
                <div className="right">
                    <button onClick={handleSave} className="button__dark">
                        Save
                    </button>
                </div>
            </div>
            <div className="chartWrap">
                {reports.map((report, i) => {
                    const itemDateRanges =
                        report.metric === "Inventory" ||
                        report.metric === "Average Size"
                            ? ["Snapshot"].concat(dateRangeOptions)
                            : dateRangeOptions;

                    const disableDropdowns = report.metric === "Equipment";

                    const metric = metrics.find(
                        (m) => m.value === report.metric
                    );

                    const reportMetric =
                        (metric && metric.label) || report.metric;

                    let dashboardTopRow = [];
                    let dashboardBottomRow = [];

                    let topRowCount = 0;
                    if (reports.length) {
                        topRowCount = Math.ceil(reports.length / 2);
                        dashboardTopRow = reports.slice(0, topRowCount);
                        dashboardBottomRow = reports.slice(
                            topRowCount,
                            reports.length
                        );
                    }

                    return (
                        <div className="reportEdit__item">
                            <button
                                className="removeBtn"
                                onClick={() => {
                                    removeReport(i);
                                }}
                            >
                                x
                            </button>
                            <div className="reportEdit__display">
                                <div className="reportEdit__display-row">
                                    {dashboardTopRow.map((preview, index) => (
                                        <div
                                            className={
                                                index === i ? "current" : ""
                                            }
                                        />
                                    ))}
                                </div>
                                <div className="reportEdit__display-row">
                                    {dashboardBottomRow.map(
                                        (preview, index) => {
                                            const reportIndex = reports.findIndex(
                                                (r) => r === preview
                                            );
                                            return (
                                                <div
                                                    className={
                                                        reportIndex === i
                                                            ? "current"
                                                            : ""
                                                    }
                                                />
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                            <MultiPageDropdown
                                options={viewByOptions}
                                values={report.activeViewBy}
                                setValues={(values) => {
                                    setReportValue("activeViewBy", values, i);
                                }}
                                label="View by"
                                placeholder="All Animals"
                                disabled={disableDropdowns}
                            />

                            <div className="dropdownContainer">
                                <Dropdown
                                    type="radio"
                                    placeholder="Metric"
                                    label="Metric"
                                    options={dashboardMetrics}
                                    setValue={(value) => {
                                        setReportValue("metric", value, i);
                                    }}
                                    value={reportMetric}
                                    defaultValue={dashboardMetrics[0]}
                                />
                                <Dropdown
                                    type="radio"
                                    placeholder="Date range"
                                    label="Date range"
                                    options={itemDateRanges}
                                    setValue={(value) => {
                                        setReportValue("dateRange", value, i);
                                    }}
                                    value={report.dateRange}
                                    defaultValue={dateRangeOptions[2]}
                                    disabled={disableDropdowns}
                                />
                            </div>
                        </div>
                    );
                })}
                {reports.length < 6 && (
                    <div className="reportEdit__item">
                        <button className="addBtn" onClick={addReport}>
                            Add report +
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};
