import Actions from "./actions";
import CreateNewForm from "./create-new-form.jsx";
import FilterForm from "./filter-form.jsx";
import Paging from "../../../../components/paging.jsx";
import PropTypes from "prop-types";
import React from "react";
import Results from "./results.jsx";
import Store from "./store";
import Qs from "qs";

const propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

class SearchPage extends React.Component {
  constructor(props) {
    super(props);

    const query = Qs.parse(this.props.location.search.substring(1));

    Actions.getResults(query);

    this.els = {};
    this.state = Store.getState();
  }

  componentWillReceiveProps(nextProps) {
    const query = Qs.parse(nextProps.location.search.substring(1));

    Actions.getResults(query);
  }

  componentDidMount() {
    this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));
  }

  componentWillUnmount() {
    this.unsubscribeStore();
  }

  onStoreChange() {
    this.setState(Store.getState());
  }

  onFiltersChange(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    Actions.changeSearchQuery(this.els.filters.state, this.props.history);
  }

  onPageChange(page) {
    this.els.filters.changePage(page);
  }

  onNewClick() {
    Actions.showCreateNew();
  }

  render() {
    return (
      <section className="container">
        <div className="page-header">
          <button
            ref={(c) => (this.els.createNew = c)}
            className="btn btn-default pull-right"
            onClick={this.onNewClick.bind(this)}
          >
            Create new
          </button>
          <h1>Users</h1>
        </div>
        <FilterForm
          ref={(c) => (this.els.filters = c)}
          loading={this.state.results.loading}
          query={Qs.parse(this.props.location.search.substring(1))}
          onChange={this.onFiltersChange.bind(this)}
        />
        <Results data={this.state.results.data} />
        <Paging
          ref={(c) => (this.els.paging = c)}
          pages={this.state.results.pages}
          items={this.state.results.items}
          loading={this.state.results.loading}
          onChange={this.onPageChange.bind(this)}
        />
        <CreateNewForm
          history={this.props.history}
          location={this.props.location}
          {...this.state.createNew}
        />
      </section>
    );
  }
}

SearchPage.propTypes = propTypes;

export default SearchPage;
