import Actions from "./actions";
import Alert from "../../../../components/alert.jsx";
import Button from "../../../../components/form/button.jsx";
import ControlGroup from "../../../../components/form/control-group.jsx";
import LinkState from "../../../../helpers/link-state";
import PropTypes from "prop-types";
import React from "react";
import Spinner from "../../../../components/form/spinner.jsx";
import TextControl from "../../../../components/form/text-control.jsx";

const propTypes = {
  error: PropTypes.string,
  hasError: PropTypes.object,
  help: PropTypes.object,
  loading: PropTypes.bool,
  password: PropTypes.string,
  passwordConfirm: PropTypes.string,
  showSaveSuccess: PropTypes.bool,
  userId: PropTypes.string,
};

class PasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: props.password,
      passwordConfirm: props.passwordConfirm,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      password: nextProps.password,
      passwordConfirm: nextProps.passwordConfirm,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const id = this.props.userId;
    const data = {
      password: this.state.password,
      passwordConfirm: this.state.passwordConfirm,
    };

    Actions.savePassword(id, data);
  }

  render() {
    const alerts = [];

    if (this.props.showSaveSuccess) {
      alerts.push(
        <Alert
          key="success"
          type="success"
          onClose={Actions.hidePasswordSaveSuccess}
          message="Success. Changes have been saved."
        />
      );
    }

    if (this.props.error) {
      alerts.push(
        <Alert key="danger" type="danger" message={this.props.error} />
      );
    }

    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <fieldset>
          <legend>Password</legend>
          {alerts}
          <TextControl
            name="password"
            type="password"
            label="New password"
            value={this.state.password}
            onChange={LinkState.bind(this)}
            hasError={this.props.hasError.password}
            help={this.props.help.password}
            disabled={this.props.loading}
          />
          <TextControl
            name="passwordConfirm"
            type="password"
            label="Confirm new password"
            value={this.state.passwordConfirm}
            onChange={LinkState.bind(this)}
            hasError={this.props.hasError.passwordConfirm}
            help={this.props.help.passwordConfirm}
            disabled={this.props.loading}
          />
          <ControlGroup hideLabel={true} hideHelp={true}>
            <Button
              type="submit"
              inputClasses={{ "btn-primary": true }}
              disabled={this.props.loading}
            >
              Set password
              <Spinner space="left" show={this.props.loading} />
            </Button>
          </ControlGroup>
        </fieldset>
      </form>
    );
  }
}

PasswordForm.propTypes = propTypes;

export default PasswordForm;
