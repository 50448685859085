import React, { useEffect } from "react";
import useIsMounted from "../../../../hooks/useIsMounted";

export const Checkbox = ({ label, value, onChange, disabled, farmType }) => {
    return (
        <label>
            <input
                type={"checkbox"}
                checked={value}
                onChange={onChange}
                disabled={disabled}
                style={{ marginRight: "5px" }}
            />
            {label}
        </label>
    );
};

export default function ActivityCheckboxes({
    allActivities,
    activities,
    updateActivities,
    setInitialActivities,
    farmType,
    isNewFarm,
}) {
    const isMount = useIsMounted();
    useEffect(() => {
        if (allActivities.length && !isMount) {
            if (farmType === "farm" && allActivities.length) {
                setInitialActivities([
                    allActivities.find((a) => a.name === "Harvest")._id,
                ]);
            } else {
                setInitialActivities(
                    allActivities
                        .filter(
                            (a) => a.name === "Receive" || a.name === "Order"
                        )
                        .map((a) => a._id)
                );
            }
        }
    }, [farmType, allActivities]);

    const distributorActivities = ["Receive", "Order"];

    const isCheckboxDisabled = (farmType, activityName) => {
        if (farmType === "farm") {
            return distributorActivities.includes(activityName);
        }
        return !distributorActivities.includes(activityName);
    };
    return (
        <div className="form-group">
            <label className="control-label">Tagging Activities</label> <br />
            {allActivities.map((act) => (
                <>
                    <Checkbox
                        label={act.name}
                        value={activities.includes(act._id)}
                        onChange={() => {
                            updateActivities(act._id);
                        }}
                        disabled={isCheckboxDisabled(farmType, act.name)}
                    />
                    <br />
                </>
            ))}
        </div>
    );
}
