import Footer from "./footer.jsx";
import Navbar from "./navbar.jsx";
import NotFound from "./not-found.jsx";
import React from "react";
import { Route, Switch } from "react-router-dom";
import FarmDetails from "./details/index.jsx";
import FarmSearch from "../farms/farms/search/index.jsx";
import TaggingInvoice from "./taggingInvoice/index.jsx";
import StripeScriptLoader from "react-stripe-script-loader";
import { StripeProvider } from "react-stripe-elements";
import getApiKey from "./getApiKey";

const App = () => {
  const apiKey = getApiKey();
  return (
    <StripeScriptLoader
      uniqueId="myUniqueId"
      script="https://js.stripe.com/v3/"
      loader="Loading..."
    >
      <StripeProvider apiKey={apiKey}>
        <div>
          {/* <CheckPermission /> */}
          <Route component={Navbar} />
          <Switch>
            <Route path="/manage" exact component={FarmSearch} />
            <Route path="/manage/:id/:invoiceId" component={TaggingInvoice} />
            <Route path="/manage/:id" component={FarmDetails} />

            <Route component={NotFound} />
          </Switch>
          <Footer />
        </div>
      </StripeProvider>
    </StripeScriptLoader>
  );
};

export default App;
