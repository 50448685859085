export default function getApiKey() {
    if (
        window.location.host === "oystertracker-prod.herokuapp.com" ||
        window.location.host === "admin.blue-trace.com" ||
        window.location.host === "admin.oystertracker.com"
    ) {
        return "pk_live_JfhlkaTbwhMdwjz7KMJIrV9R";
    }

    return "pk_test_3AVvJkuuHWIJC75qWXejKfeK";
}
