import FluxConstant from "flux-constant";

export default FluxConstant.set([
    "CREATE_NEW",
    "CREATE_NEW_RESPONSE",
    "GET_RESULTS",
    "GET_RESULTS_RESPONSE",
    "HIDE_CREATE_NEW",
    "SHOW_CREATE_NEW",
]);
