import FluxConstant from "flux-constant";

export default FluxConstant.set([
    "DELETE",
    "DELETE_RESPONSE",
    "GET_DETAILS",
    "GET_DETAILS_RESPONSE",
    "HIDE_DETAILS_SAVE_SUCCESS",
    "HIDE_PERMISSIONS_SAVE_SUCCESS",
    "SAVE_DETAILS",
    "SAVE_DETAILS_RESPONSE",
    "SAVE_PERMISSIONS",
    "SAVE_PERMISSIONS_RESPONSE",
]);
