import React, { useState } from "react";
import { formatDate } from "../utils/formatters";
import isPercentMetric from "../utils/isPercentMetric";

export default ({ data, frequency, metric, type = "share" }) => {
    const [fixedShadow, setShadow] = useState({});

    const addScrollShadow = (e) => {
        if (e.target.scrollLeft > 0) {
            if (!fixedShadow.boxShadow) {
                setShadow({
                    boxShadow: "6px 0 5px -2px rgba(0, 0, 0, .1)",
                });
            }
        } else {
            setShadow({});
        }
    };
    if (!data.length) {
        return null;
    }

    const formatNumber = (value) => {
        if (metric === "Average Size") {
            return `${value.toFixed(1)}mm`;
        }
        if (isPercentMetric(metric, type)) {
            return `${value.toFixed(1)}%`;
        }

        return value.toLocaleString();
    };

    const rowCount = Object.keys(data[0]).filter((key) => key !== "name")
        .length;

    const tableContainer =
        metric === "Yield" || metric === "Inventory"
            ? Object.assign({}, styles.tableContainer, { top: 30 })
            : styles.tableContainer;

    return (
        <div style={tableContainer}>
            <div style={styles.tableBox} onScroll={addScrollShadow}>
                <table style={styles.tableStyle}>
                    <thead>
                        <tr style={styles.tableHead}>
                            <th
                                style={Object.assign(
                                    {},
                                    styles.mainColHead,
                                    fixedShadow
                                )}
                            ></th>
                            {data.map((col) => (
                                <th
                                    key={`tableHead${col.name}`}
                                    style={styles.middleColHead}
                                >
                                    {metric === "Inventory" && type === "count"
                                        ? col.name
                                        : formatDate(col.name, {
                                              span: frequency,
                                          })}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(data[0])
                            .filter((key) => key !== "name")
                            .map((key, i) => {
                                const lastStyle =
                                    rowCount - 1 === i
                                        ? styles.mainColLast
                                        : {};
                                return (
                                    <tr style={styles.tableRow}>
                                        <td
                                            style={Object.assign(
                                                {},
                                                styles.mainCol,
                                                lastStyle,
                                                fixedShadow
                                            )}
                                        >
                                            {key}
                                        </td>
                                        {data.map((col, i) => (
                                            <td
                                                key={`tableData${i}`}
                                                style={styles.middleCol}
                                            >
                                                {col[key] || col[key] === 0
                                                    ? formatNumber(col[key])
                                                    : ""}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const styles = {
    axisStyle: {
        fontFamily: "Avenir Next",
        fontSize: 14,
        fontWeight: "400",
        fill: "#646464",
    },
    tableContainer: {
        position: "relative",
    },
    tableBox: {
        marginTop: 10,
        marginLeft: 90,
        borderColor: "#E8E8E8",
        borderStyle: "solid",
        borderWidth: "1px 1px 1px 0",
        overflowX: "scroll",
        overflowY: "visible",
        padding: 0,
    },
    tableStyle: {
        borderSpacing: 0,
    },
    tableHead: {
        height: "50px",
        background: "#fafafa",
    },
    tableRow: {
        height: "50px",
        whiteSpace: "nowrap",
    },
    mainColHead: {
        borderTop: "1px solid #E8E8E8",
        left: 0,
        top: "auto",
        position: "absolute",
        textAlign: "left",
        width: 90,
        height: "52px",
        background: "#fafafa",
        fontFamily: "Avenir Next",
        fontSize: 13,
        fontWeight: "500",
        padding: "14px 5px 10px 20px",
        whiteSpace: "nowrap",
        transform: "translateY(-1px)",
        transition: "all .5s ease-in-out",
    },
    middleColHead: {
        fontFamily: "Avenir Next",
        fontSize: 13,
        fontWeight: "500",
        padding: "10px 0 10px 20px",
        textAlign: "right",
        whiteSpace: "nowrap",
    },
    mainCol: {
        transition: "all .5s ease-in-out",
        fontFamily: "Avenir Next",
        fontSize: 15,
        fontWeight: "400",
        textAlign: "left",
        paddingLeft: "30px",
        borderTop: "1px solid #E8E8E8",
        position: "absolute",
        left: 0,
        width: 90,
        height: "52px",
        top: "auto",
        padding: "14px 5px 10px 20px",
        background: "#ffffff",
    },
    mainColLast: {
        height: "51px",
        borderBottom: "1px solid #E8E8E8",
    },
    middleCol: {
        whiteSpace: "nowrap",
        fontFamily: "Avenir Next",
        fontSize: 15,
        fontWeight: "400",
        textAlign: "right",
        padding: "10px 0 10px 20px",
        borderTop: "1px solid #E8E8E8",
    },
};
