import PropTypes from 'prop-types';
import React from 'react';
import Moment from 'moment';
import { Link } from 'react-router-dom';
import Button from '../../../../components/form/button.jsx';
import UserResults from '../../../admin/users/search/results';

const propTypes = {
	address1: PropTypes.string,
	address2: PropTypes.string,
	city: PropTypes.string,
	leases: PropTypes.array,
	owner: PropTypes.string,
	plan: PropTypes.string,
	setupFee: PropTypes.string,
	name: PropTypes.string,
	state: PropTypes.string,
	country: PropTypes.string,
	email: PropTypes.string,
	zip: PropTypes.string,
};

class FarmSummary extends React.Component {
	render() {
		const TagSetupBlock = (
			<span>
				{this.props.taggingActive === true ? (
					<i className="fa fa-check-circle text-success"></i>
				) : this.props.taggingActive === 'pending' ? (
					<i className="fa fa-minus-circle text-warning"></i>
				) : (
					<i className="fa fa-times-circle text-danger"></i>
				)}
				{this.props.type && this.props.type === 'distributor'
					? 'Distributor'
					: 'Tag Printing'}
			</span>
		);

		const users =
			(this.props.users && this.props.users.length && (
				<div className="panel panel-default">
					<div className="panel-heading">Attached Users</div>
					<UserResults data={this.props.users} />
				</div>
			)) ||
			null;
		return (
			<div>
				<br />
				<div className="panel panel-default">
					<div className="panel-heading">Farm Information</div>
					<ul className="list-group list-group-farm-summary">
						<li className="list-group-item row">
							<dt className="col-sm-4">Name</dt>
							<dd className="col-sm-8">{this.props.name}</dd>
						</li>
						<li className="list-group-item row">
							<dt className="col-sm-4">Owner</dt>
							<dd className="col-sm-8">{this.props.owner}</dd>
						</li>
						<li className="list-group-item row">
							<dt className="col-sm-4">Billing Email</dt>
							<dd className="col-sm-8">{this.props.billingEmail}</dd>
						</li>
						<li className="list-group-item row">
							<dt className="col-sm-4">Public Email</dt>
							<dd className="col-sm-8">{this.props.email}</dd>
						</li>
						<li className="list-group-item row">
							<dt className="col-sm-4">Address</dt>
							<dd className="col-sm-8">
								{this.props.address1}
								<br />
								{this.props.address2 ? `${this.props.address2}<br/>` : ''}
								{this.props.city}, {this.props.state} {this.props.zip}
								{this.props.country}
							</dd>
						</li>
						<li className="list-group-item row">
							<dt className="col-sm-4">Type</dt>
							<dd className="col-sm-8">
								{(this.props.type && this.props.type.toUpperCase()) || null}
							</dd>
						</li>
					</ul>
				</div>
				<div className="panel panel-default">
					<div className="panel-heading">Leases</div>
					<table className="table">
						<thead>
							<tr>
								<th>Name</th>
								<th>Date of Issue</th>
								<th>Date of Expiration</th>
								<th>Size</th>
								<th>Lines</th>
								<th>Location</th>
							</tr>
						</thead>
						<tbody>
							{this.props.leases.map((lease, i) => {
								return (
									<tr key={i}>
										<td>{lease.name}</td>
										<td>{Moment(lease.dateofIssue).format('ll')}</td>
										<td>{Moment(lease.dateOfExpiration).format('ll')}</td>
										<td>{lease.size}</td>
										<td>{lease.lines.length}</td>
										<td>{lease.location}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				<div className="panel panel-default">
					<div className="panel-heading">Your BlueTrace Services</div>
					<ul className="list-group">
						<li className="list-group-item">
							{this.props.subscriptionId ? (
								<i className="fa fa-check-circle text-success"></i>
							) : (
								<i className="fa fa-times-circle text-danger"></i>
							)}
							Farm Manager {this.props.subscribeButton}
						</li>
						<li className="list-group-item">
							{this.props.taggingActive === 'pending' &&
							this.props.taggingSetupInvoice ? (
								this.props.role === 'admin' ? (
									<span>{TagSetupBlock}</span>
								) : (
									<span>
										{TagSetupBlock}{' '}
										<Link
											to={`/manage/${this.props._id}/${this.props.taggingSetupInvoice}`}
										>
											Enable Subscription
										</Link>
									</span>
								)
							) : (
								<div>{TagSetupBlock}</div>
							)}
						</li>
					</ul>
				</div>
				{users}
			</div>
		);
	}
}

FarmSummary.propTypes = propTypes;

export default FarmSummary;
