import PropTypes from 'prop-types';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

const propTypes = {
	data: PropTypes.array,
};

function Results(props) {
	const history = useHistory();

	const rows = props.data.map((record) => {
		const handleRowClick = () => {
			history.push(`/admin/users/${record._id}`);
		};
		return (
			<tr onClick={handleRowClick} key={record._id}>
				<td>
					<Link
						className="btn btn-default btn-sm"
						to={`/admin/users/${record._id}`}
					>
						Edit
					</Link>
				</td>
				<td>{record.username}</td>
				<td>{record.email}</td>
				<td>{record.isActive}</td>
				<td className="nowrap">{record._id}</td>
			</tr>
		);
	});

	return (
		<div className="table-responsive">
			<table className="table table-striped table-results">
				<thead>
					<tr>
						<th></th>
						<th>username</th>
						<th className="stretch">email</th>
						<th>active</th>
						<th>id</th>
					</tr>
				</thead>
				<tbody>{rows}</tbody>
			</table>
		</div>
	);
}

Results.propTypes = propTypes;

export default Results;
