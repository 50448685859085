import ApiActions from "../../../actions/api";
import Constants from "./constants";
import Store from "./store";

class Actions {
    static getDetails(id) {
        ApiActions.get(
            `/api/farms/${id}`,
            undefined,
            Store,
            Constants.GET_DETAILS,
            Constants.GET_DETAILS_RESPONSE
        );
    }

    static getFilters(id) {
        ApiActions.get(
            `/api/farms/${id}/filters`,
            undefined,
            Store,
            Constants.GET_FILTERS,
            Constants.GET_FILTERS_RESPONSE
        );
    }

    static getUser() {
        ApiActions.get(
            "/api/users/my",
            undefined,
            Store,
            Constants.GET_USER,
            Constants.GET_USER_RESPONSE
        );
    }

    static loadPaymentPlans() {
        ApiActions.get(
            "/api/farms/paymentplans",
            null,
            Store,
            Constants.LOAD_PAYMENT_PLANS,
            Constants.LOAD_PAYMENT_PLANS_RESPONSE
        );
    }

    static updatePaymentDetails(data) {
        const { token, id, customerId, subscriptionId } = data;

        const postData = {
            token,
            id,
        };

        const putData = {
            token,
            customerId,
            subscriptionId,
        };

        if (subscriptionId && customerId) {
            ApiActions.put(
                `/api/farms/${id}/subscriptions`,
                putData,
                Store,
                Constants.SAVE_SUBSCRIPTION,
                Constants.SAVE_SUBSCRIPTION_RESPONSE
            );
        } else {
            ApiActions.post(
                `/api/farms/${id}/subscriptions`,
                postData,
                Store,
                Constants.SAVE_SUBSCRIPTION,
                Constants.SAVE_SUBSCRIPTION_RESPONSE
            );
        }
    }

    static updateFarmDetails(id, data) {
        ApiActions.post(
            `/api/farms/${id}/farmDetails`,
            data,
            Store,
            Constants.SAVE_FARM_DETAILS,
            Constants.SAVE_FARM_DETAILS_RESPONSE
        );
    }

    static hideDetailsSaveSuccess() {
        Store.dispatch({
            type: Constants.HIDE_DETAILS_SAVE_SUCCESS,
        });
    }

    static saveShippingAddress(id, data) {
        ApiActions.put(
            `/api/farms/${id}/shippingAddress`,
            data,
            Store,
            Constants.SAVE_ADDRESS,
            Constants.SAVE_ADDRESS_RESPONSE
        );
    }

    static showSaveSuccess() {
        return Store.dispatch({
            type: Constants.TOGGLE_SAVE_SUCCESS,
            showSaveSuccess: true,
        });
    }

    static hideSaveSuccess() {
        return Store.dispatch({
            type: Constants.TOGGLE_SAVE_SUCCESS,
            showSaveSuccess: false,
        });
    }
}

export default Actions;
