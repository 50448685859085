import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import ClassNames from 'classnames';
import logo from '../../media/logo.png';

const propTypes = {
	location: PropTypes.object,
};

class Navbar extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			navBarOpen: false,
		};
	}

	componentWillReceiveProps() {
		this.setState({ navBarOpen: false });
	}

	classForPath(pathPattern) {
		return ClassNames({
			active: this.props.location.pathname.match(pathPattern),
		});
	}

	toggleMenu() {
		this.setState({ navBarOpen: !this.state.navBarOpen });
	}

	render() {
		const navBarCollapse = ClassNames({
			'navbar-collapse': true,
			collapse: !this.state.navBarOpen,
		});

		return (
			<div className="navbar navbar-fixed-top">
				<div className="container">
					<div className="navbar-header">
						<Link className="navbar-brand" to="/admin">
							<img className="navbar-logo" src={logo} alt="BlueTrace" />
						</Link>
						<button
							className="navbar-toggle collapsed"
							onClick={this.toggleMenu.bind(this)}
						>
							<span className="icon-bar" />
							<span className="icon-bar" />
							<span className="icon-bar" />
						</button>
					</div>
					<div className={navBarCollapse}>
						<ul className="nav navbar-nav">
							<li className={this.classForPath(/^\/farms/)}>
								<a href="/farms">Farms</a>
							</li>
							<li className={this.classForPath(/^\/admin\/admin-groups/)}>
								<a href="/admin/admin-groups">Admin Groups</a>
							</li>
							<li className={this.classForPath(/^\/admin\/users/)}>
								<a href="/admin/users">Users</a>
							</li>
						</ul>
						<ul className="nav navbar-nav navbar-right">
							<li>
								<a href="/login/logout">Sign out</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

Navbar.propTypes = propTypes;

export default Navbar;
