import React from "react";
import { Link } from "react-router-dom";

class Footer extends React.Component {
  render() {
    const year = new Date().getFullYear();

    return (
      <div className="footer">
        <div className="container">
          <span className="copyright pull-right">&#169; {year} BlueTrace.</span>
          <ul className="links">
            <li>
              <Link to="/">Home</Link>
            </li>
          </ul>
          <div className="clearfix"></div>
        </div>
      </div>
    );
  }
}

export default Footer;
