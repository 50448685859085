import ApiActions from "../../../actions/api";
import Constants from "./constants";
import Store from "./store";

class Actions {
    static getDetails(id) {
        ApiActions.get(
            `/api/farms/${id}`,
            undefined,
            Store,
            Constants.GET_DETAILS,
            Constants.GET_DETAILS_RESPONSE
        );
    }

    static getUser() {
        ApiActions.get(
            "/api/users/my",
            undefined,
            Store,
            Constants.GET_USER,
            Constants.GET_USER_RESPONSE
        );
    }

    static getInvoice(id) {
        ApiActions.get(
            `/api/invoice/${id}`,
            undefined,
            Store,
            Constants.GET_INVOICE,
            Constants.GET_INVOICE_RESPONSE
        );
    }

    static payInvoice(
        invoiceId,
        farmId,
        emailAddress,
        shippingAddress,
        token = null
    ) {
        ApiActions.post(
            `/api/invoice/${invoiceId}/pay`,
            {
                farmId,
                emailAddress,
                shippingAddress,
                token,
            },
            Store,
            Constants.PAY_INVOICE,
            Constants.PAY_INVOICE_RESPONSE
        );
    }
}

export default Actions;
