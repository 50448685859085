import PropTypes from 'prop-types';
import React from 'react';
import FarmInfo from './farm-info';
import Store from './store';
import Actions from './actions';

const propTypes = {
	_id: PropTypes.string,
	containerOptions: PropTypes.object,
	error: PropTypes.string,
	hasError: PropTypes.object,
	help: PropTypes.object,
	loading: PropTypes.bool,
	name: PropTypes.string,
	location: PropTypes.string,
	owner: PropTypes.string,
	plan: PropTypes.string,
	setupFee: PropTypes.string,
	showSaveSuccess: PropTypes.bool,
};

class SetupWizard extends React.Component {
	constructor(props) {
		super(props);

		this.state = Store.getState();
	}

	componentDidMount() {
		if (this.props._id) {
			Actions.loadFarmData(this.props);
		}

		Actions.loadPaymentPlans();
		Actions.loadActivities();

		this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));
	}

	componentWillUnmount() {
		this.unsubscribeStore();
	}

	onStoreChange() {
		this.setState(Store.getState());
	}

	handleSelect(activeTab) {
		Actions.toggleTab(activeTab);
	}

	render() {
		return <FarmInfo {...this.state.farm} />;
	}
}

SetupWizard.propTypes = propTypes;

export default SetupWizard;
