/* global window */
import ApiActions from "../../../../actions/api";
import Constants from "./constants";
import Store from "./store";

class Actions {
    static saveFarmInfo(
        address1,
        address2,
        city,
        name,
        companyName,
        owner,
        state,
        country,
        activities,
        email,
        billingEmail,
        zip,
        type
    ) {
        const data = {
            address1,
            address2,
            city,
            name,
            companyName,
            owner,
            state,
            country,
            activities,
            email,
            billingEmail,
            zip,
            type,
        };

        console.log("creating farm", data.billingEmail);

        ApiActions.post(
            "/api/farms",
            data,
            Store,
            Constants.ADD_FARM_DETAILS,
            Constants.ADD_FARM_DETAILS_RESPONSE
        );
    }

    static updateFarmInfo(
        farmId,
        address1,
        address2,
        city,
        name,
        companyName,
        owner,
        state,
        country,
        activities,
        email,
        billingEmail,
        zip,
        type
    ) {
        const data = {
            address1,
            address2,
            city,
            name,
            owner,
            state,
            country,
            activities,
            email,
            billingEmail,
            zip,
            type,
        };

        ApiActions.put(
            `/api/farms/${farmId}`,
            data,
            Store,
            Constants.ADD_FARM_DETAILS,
            Constants.ADD_FARM_DETAILS_RESPONSE
        );
    }

    static updateFeatureFlags(farmId, features) {
        ApiActions.put(
            `/api/farms/${farmId}/features`,
            features,
            Store,
            Constants.UPDATE_FEATURE_FLAGS,
            Constants.UPDATE_FEATURE_FLAGS_RESPONSE
        );
    }

    static getDetails(id) {
        ApiActions.get(
            `/api/farms/${id}`,
            undefined,
            Store,
            Constants.GET_DETAILS,
            Constants.GET_DETAILS_RESPONSE
        );
    }

    static getFilters(id) {
        ApiActions.get(
            `/api/farms/${id}/filters`,
            undefined,
            Store,
            Constants.GET_FILTERS,
            Constants.GET_FILTERS_RESPONSE
        );
    }

    static getDistributorProducts() {
        ApiActions.get(
            "/api/farms/products",
            undefined,
            Store,
            Constants.GET_PRODUCTS,
            Constants.GET_PRODUCTS_RESPONSE
        );
    }

    static loadPaymentPlans() {
        ApiActions.get(
            "/api/farms/paymentplans",
            null,
            Store,
            Constants.LOAD_PAYMENT_PLANS,
            Constants.LOAD_PAYMENT_PLANS_RESPONSE
        );
    }

    static loadActivities() {
        ApiActions.get(
            "/api/farms/activities",
            null,
            Store,
            Constants.LOAD_PAYMENT_PLANS,
            Constants.LOAD_ACTIVITIES_RESPONSE
        );
    }

    static toggleEditMode(toggle) {
        return Store.dispatch({
            type: Constants.TOGGLE_EDIT_MODE,
            toggle,
        });
    }

    static saveDetails(id, data) {
        ApiActions.put(
            `/api/farms/${id}`,
            data,
            Store,
            Constants.SAVE_DETAILS,
            Constants.SAVE_DETAILS_RESPONSE
        );
    }

    static hideDetailsSaveSuccess() {
        Store.dispatch({
            type: Constants.HIDE_DETAILS_SAVE_SUCCESS,
        });
    }

    static delete(id, history) {
        ApiActions.delete(
            `/api/farms/${id}`,
            undefined,
            Store,
            Constants.DELETE,
            Constants.DELETE_RESPONSE,
            (err, response) => {
                if (!err) {
                    history.push("/farms");

                    window.scrollTo(0, 0);
                }
            }
        );
    }

    static clean(id, history) {
        ApiActions.delete(
            `/api/farms/${id}/clean`,
            undefined,
            Store,
            Constants.CLEAN,
            Constants.CLEAN_RESPONSE,
            (err, response) => {
                if (!err) {
                    window.location.reload();
                }
            }
        );
    }

    static enableTagging(farmId, data) {
        ApiActions.post(
            `/api/enableTagging/${farmId}`,
            data,
            Store,
            Constants.ENABLE_TAGGING,
            Constants.ENABLE_TAGGING_RESPONSE
        );
    }

    static updateFarmSetupFee(farmId, plan, setupFee, trialPeriod) {
        ApiActions.put(
            `/api/farms/${farmId}/paymentPlan`,
            { plan, setupFee, trialPeriod },
            Store,
            Constants.UPDATE_PAYMENTPLAN,
            Constants.UPDATE_PAYMENTPLAN_RESPONSE
        );
    }

    static toggleTab(activeTab) {
        return Store.dispatch({
            type: Constants.TOGGLE_TAB,
            activeTab,
        });
    }

    static updatePaymentDetails(data) {
        const { token, id, email } = data;

        const postData = {
            token,
            id,
            email,
        };

        ApiActions.post(
            `/api/farms/${id}/subscriptions`,
            postData,
            Store,
            Constants.SAVE_SUBSCRIPTION,
            Constants.SAVE_SUBSCRIPTION_RESPONSE
        );
    }

    static resendTaggingEmail(farmId) {
        ApiActions.get(
            `/api/enableTagging/${farmId}/resendTaggingSetupEmail`,
            null,
            Store,
            Constants.RESEND_EMAIL,
            Constants.RESEND_EMAIL_RESPONSE
        );
    }

    static hideAlert() {
        Store.dispatch({
            type: Constants.HIDE_ALERT,
        });
    }

    static hideEmailReminder() {
        Store.dispatch({
            type: Constants.RESEND_EMAIL_ALERT_HIDE,
        });
    }

    static cancelFarmManager(farmId, subscriptionId) {
        ApiActions.put(
            `/api/farms/${farmId}/subscriptions/cancel`,
            { subscriptionId },
            Store,
            Constants.CANCEL_FARM_MANAGER,
            Constants.CANCEL_FARM_MANAGER_RESPONSE
        );
    }

    static cancelTagging(farmId, subscriptionId = null) {
        ApiActions.put(
            `/api/farms/${farmId}/subscriptions/cancel/tagging`,
            { subscriptionId },
            Store,
            Constants.CANCEL_FARM_MANAGER,
            Constants.CANCEL_FARM_MANAGER_RESPONSE
        );
    }

    static updateFarmDetails(id, data) {
        ApiActions.post(
            `/api/farms/${id}/farmDetails`,
            data,
            Store,
            Constants.SAVE_FARM_DETAILS,
            Constants.SAVE_FARM_DETAILS_RESPONSE
        );
    }
}

export default Actions;
