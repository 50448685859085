import React from "react";
import ManagerSummary from "../../farms/farms/details/managerInfo";

class Manager extends React.Component {
    render() {
        return <ManagerSummary {...this.props} />;
    }
}

export default Manager;
