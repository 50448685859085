import React from "react";

export default ({ values, activeValue, setToggleValue }) => {
    return (
        <div className="reportToggle">
            {values.map((value, index) => (
                <button
                    key={`${value}_${index}_toggle`}
                    className={
                        activeValue === value
                            ? "toggleActive"
                            : "toggleInActive"
                    }
                    onClick={() => {
                        setToggleValue(value);
                    }}
                >
                    {value}
                </button>
            ))}
        </div>
    );
};
