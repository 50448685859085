import React, { useState } from "react";
import ClassNames from "classnames";
import dropdownArrowWhite from "../../../media/dropdownArrowWhite.svg";
import dropdownArrow from "../../../media/dropdownArrow.svg";
import backArrow from "../../../media/backArrow.svg";
import check from "../../../media/check.svg";
import nextChevron from "../../../media/nextChevron.svg";

export default ({
    type,
    defaultValue,
    setValue,
    value,
    placeholder,
    options,
    label,
    theme,
    disabled,
    customValue,
    customSelector,
    customSelectedValue,
}) => {
    const dropdownTheme = theme || "light";

    const [opened, setOpened] = useState(false);
    const [position, setPosition] = useState(false);
    const [showCustom, setShowCustom] = useState(false);

    const handleSelectOption = (option) => {
        if (type === "checkbox") {
            const newOptions = JSON.parse(JSON.stringify(value));

            const index = newOptions.findIndex((o) => o === option);
            if (index !== -1) {
                newOptions.splice(index, 1);
            } else {
                newOptions.push(option);
            }
            setValue(newOptions);
        } else {
            setValue(option);

            if (option !== customValue) {
                toggleOpen();
            } else {
                setShowCustom(true);
            }
        }
    };

    const isSelected = (option) => {
        if (type === "checkbox") {
            return value.includes(option);
        } else {
            return option === value;
        }
    };

    const toggleOpen = () => {
        if (!disabled) {
            setOpened(!opened);
        }
    };

    const activeValue =
        type === "checkbox"
            ? value.length
                ? value.length === 1
                    ? value[0]
                    : `${value.length} Selected`
                : placeholder
            : value === customValue
            ? customSelectedValue
            : value || placeholder;

    const dropdownClasses = ClassNames({
        reportDropdownActive__Dark: dropdownTheme === "dark",
        reportDropdownActive: dropdownTheme !== "dark",
        disabled: disabled,
    });

    const bodyRect = document.body.getBoundingClientRect();

    let popoverContainerClasses = "null";
    if (position) {
        const classes = {
            "drop-up": !!(position.top > bodyRect.height / 2),
        };
        classes[`dropdownPopover__${dropdownTheme}`] = true;

        popoverContainerClasses = ClassNames(classes);
    }

    return (
        <div
            className={`dropdownContainer__${dropdownTheme}`}
            ref={(el) => {
                if (!el) return;

                if (!position) {
                    setPosition(el.getBoundingClientRect());
                }
            }}
        >
            <div className={`${opened ? "opened" : ""}  reportDropdown`}>
                {dropdownTheme === "light" && label && (
                    <label className="label">{label}</label>
                )}
                <button onClick={toggleOpen} className={dropdownClasses}>
                    <span className="activeValue">{activeValue}</span>
                    {dropdownTheme === "dark" ? (
                        <img
                            className="dropdownArrow"
                            src={dropdownArrowWhite}
                            alt="dropdown arrow"
                        />
                    ) : (
                        <img src={dropdownArrow} alt="dropdown arrow" />
                    )}
                </button>
                {opened && (
                    <div className={popoverContainerClasses}>
                        <div className="triangle" />
                        <div className="popoverContainer">
                            <div className="popoverHeader">
                                <div className="headerItem">
                                    {showCustom ? (
                                        <button
                                            className="popoverHeaderButton"
                                            onClick={() => {
                                                setShowCustom(false);
                                            }}
                                        >
                                            <img
                                                className="backArrow"
                                                src={backArrow}
                                                alt="back arrow"
                                            />
                                            Back
                                        </button>
                                    ) : null}
                                </div>
                                <div className="headerItem">
                                    <h4 className="popoverHeaderlabel">
                                        {label}
                                    </h4>
                                </div>
                                <div className="headerItem"></div>
                            </div>
                            {showCustom ? (
                                customSelector
                            ) : (
                                <div className="popoverRows">
                                    {options.map((option, index) => (
                                        <button
                                            key={`${option}-${index}`}
                                            onClick={() => {
                                                handleSelectOption(option);
                                            }}
                                            className="popoverRow"
                                        >
                                            <div className="popoverRow__inner">
                                                <span>{option}</span>
                                                {isSelected(option) ? (
                                                    <img
                                                        className="backArrow"
                                                        src={check}
                                                        alt="check"
                                                    />
                                                ) : option === customValue ? (
                                                    <img
                                                        className="backArrow"
                                                        src={nextChevron}
                                                        alt="next"
                                                    />
                                                ) : null}
                                            </div>
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            {opened && <div className="overlay" onClick={toggleOpen} />}
        </div>
    );
};
