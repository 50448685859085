import React from "react";
import TextControl from "../../../components/form/text-control.jsx";
import SelectControl from "../../../components/form/select-control.jsx";
import Button from "../../../components/form/button.jsx";
import LinkState from "../../../helpers/link-state";
import Actions from "./actions";
import StateOptions from "../../farms/farms/details/StateOptions.js";

class TaggingAddressEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            displayErrors: false,
            name: "",
            business: "",
            address1: "",
            address2: "",
            city: "",
            country: "",
            state: "",
            zip: "",
            loading: false,
        };

        this.handleSaveAddress = this.handleSaveAddress.bind(this);
    }

    componentWillMount() {
        const { shippingAddress } = this.props;
        if (shippingAddress && shippingAddress.name) {
            console.log("mount", shippingAddress);
            this.setState(shippingAddress);
        }
    }

    componentWillReceiveProps(nextProps) {
        const { shippingAddress } = nextProps;
        if (shippingAddress && shippingAddress.name) {
            console.log("receive", shippingAddress);
            this.setState(shippingAddress);
        }
    }

    handleSaveAddress() {
        const {
            name,
            business,
            address1,
            address2,
            city,
            state,
            zip,
            country,
        } = this.state;
        if (!name || !address1 || !city || !state || !zip || !country) {
            this.setState({
                displayErrors: true,
            });
            return;
        }
        Actions.saveShippingAddress(this.props._id, {
            name,
            business,
            address1,
            address2,
            city,
            state,
            zip,
        });
        this.props.toggleEdit();
    }
    render() {
        const { displayErrors } = this.state;
        return (
            <div>
                <br />
                <div className="panel panel-default">
                    <div className="panel-heading">Refill Shipping Info</div>
                    <div className="panel-body">
                        <TextControl
                            name="name"
                            label="Name*"
                            value={this.state.name}
                            onChange={LinkState.bind(this)}
                            disabled={this.state.loading}
                        />
                        {displayErrors && !this.state.name && (
                            <p className="text-danger">Name is required.</p>
                        )}
                        <TextControl
                            name="business"
                            label="Buisness"
                            value={this.state.business}
                            onChange={LinkState.bind(this)}
                            disabled={this.state.loading}
                        />
                        <TextControl
                            name="address1"
                            label="Address 1*"
                            value={this.state.address1}
                            onChange={LinkState.bind(this)}
                            disabled={this.state.loading}
                        />
                        {displayErrors && !this.state.address1 && (
                            <p className="text-danger">
                                Address 1 is required.
                            </p>
                        )}
                        <TextControl
                            name="address2"
                            label="Address 2"
                            value={this.state.address2}
                            onChange={LinkState.bind(this)}
                            disabled={this.state.loading}
                        />

                        <TextControl
                            name="city"
                            label="City*"
                            value={this.state.city}
                            onChange={LinkState.bind(this)}
                            disabled={this.state.loading}
                        />
                        {displayErrors && !this.state.city && (
                            <p className="text-danger">City is required.</p>
                        )}
                        <SelectControl
                            name="state"
                            label="State*"
                            value={this.state.state}
                            onChange={LinkState.bind(this)}
                            disabled={this.state.loading}
                        >
                            <StateOptions country={this.state.country} />
                        </SelectControl>
                        {displayErrors && !this.state.state && (
                            <p className="text-danger">State is required.</p>
                        )}
                        <TextControl
                            name="zip"
                            label="Zip Code *"
                            value={this.state.zip}
                            onChange={LinkState.bind(this)}
                            disabled={this.state.loading}
                        />
                        {displayErrors && !this.state.zip && (
                            <p className="text-danger">Zip Code is required.</p>
                        )}

                        <SelectControl
                            name="country"
                            label="Country"
                            value={this.state.country}
                            onChange={LinkState.bind(this)}
                            disabled={this.state.loading}
                        >
                            <option value="">- Select One - </option>
                            <option value="Canada">Canada</option>
                            <option value="Mexico">Mexico</option>
                            <option value="USA">USA</option>
                            <option value="Australia">Australia</option>
                        </SelectControl>
                        {displayErrors && !this.state.country && (
                            <p className="text-danger">Country is required.</p>
                        )}

                        <Button
                            type="submit"
                            inputClasses={{ "btn-primary": true }}
                            onClick={this.handleSaveAddress}
                            disabled={this.state.loading}
                        >
                            Update Shipping Address
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default TaggingAddressEdit;
