import Actions from './actions';
import DeleteForm from '../../../admin/components/delete-form.jsx';
import DetailsForm from './details-form.jsx';
import PasswordForm from './password-form.jsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import FarmForm from '../../components/farm-form';
import Store from './store';
import GroupsForm from './groups-form';

const propTypes = {
	history: PropTypes.object,
	match: PropTypes.object,
};

class DetailsPage extends React.Component {
	constructor(props) {
		super(props);

		Actions.getDetails(this.props.match.params.id);
		Actions.getFarmOptions();
		Actions.getGroupOptions();
		Actions.getPermissionsUrl();
		this.state = Store.getState();
	}

	componentDidMount() {
		this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));
	}

	componentWillUnmount() {
		this.unsubscribeStore();
	}

	onStoreChange() {
		this.setState(Store.getState());
	}

	render() {
		if (!this.state.details.hydrated) {
			return (
				<section className="container">
					<h1 className="page-header">
						<Link to="/admin/users">Users</Link> / loading...
					</h1>
				</section>
			);
		}

		if (this.state.details.showFetchFailure) {
			return (
				<section className="container">
					<h1 className="page-header">
						<Link to="/admin/users">Users</Link> / Error
					</h1>
					<div className="alert alert-danger">{this.state.details.error}</div>
				</section>
			);
		}

		const id = this.state.details._id;
		const name = this.state.details.name;
		const fullName = Object.values(name).filter(Boolean).join(' ');
		const username = this.state.details.username;

		return (
			<section className="container">
				<h1 className="page-header">
					<Link to="/admin/users">Users</Link> / {username}
				</h1>
				<div className="row">
					<div className="col-sm-8">
						<DetailsForm {...this.state.details} />
						<PasswordForm {...this.state.password} />
						<GroupsForm {...this.state.groups} />
						<DeleteForm
							{...this.state.delete}
							action={Actions.delete.bind(Actions, id, this.props.history)}
						/>
					</div>
					<div className="col-sm-4">
						<FarmForm
							{...this.state.farm}
							saveAction={Actions.newFarm.bind(Actions, id)}
							successCloseAction={Actions.hideFarmSaveSuccess}
							removeAction={Actions.removeFarm.bind(Actions, id)}
							accountId={id}
							accountName={fullName}
						/>
					</div>
				</div>
			</section>
		);
	}
}

DetailsPage.propTypes = propTypes;

export default DetailsPage;
